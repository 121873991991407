.LoadingButtonCon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    span {
        margin-left: 5px;
        font-size: 16px;
    }
}