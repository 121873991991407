.coach-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-container {
    position: relative;
    width: 800px;
}

.thumbnail-strip {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;
}

.thumbnail img {
    width: 100px;
    height: 56px;
    object-fit: cover;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: border-color 0.2s;
}

.thumbnail img:hover {
    border-color: #d4f8dd;
}

.thumbnail .label {
    margin-top: 5px;
    font-size: 12px;
    color: #333;
}

.comments-container {
    position: relative;
    right: 0;
    top: 0;
    padding: 10px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.comment-box {
    margin-bottom: 20px;
}