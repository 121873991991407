@import "../../../SCSS/variables";
.GamePostOutlet {
    padding-inline: 1rem;
    display: flex;
    min-height: 50dvh;

    .sidenav {
        border-right: 1px solid rgb(211, 208, 208);
    }

    .Outlet {
        padding: 1rem;
        width: 100%;
        .toggle_button{
          display: none;
        }
    }

}

@media screen and (max-width: 768px) {
    .GamePostOutlet {
        .sidenav {
            padding: 1rem;
            width: 250px;
            transition: 0.8s;
            position: absolute;
            transform: translateX(-120%) !important;
            background-color: #fff;
            z-index: 3;
            bottom: 0;
            height: 100%;
            transition: all 0.8s;
        }

        .open {
            transform: translate(0%) !important;
            transition: 0.8s;
            display: block;
        }
        .Outlet{
            .toggle_button {
                display: block !important;
                display: flex !important;
                justify-content: flex-end;
                button {
                  position: relative;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  overflow: hidden;
                  border: none;
                  background-color: transparent;
                  z-index: 4;
        
                  &.menuicons span {
                    transform: translateX(60px);
                  }
        
                  &.menuicons span:nth-child(1) {
                    transform: translateY(0px);
                    width: 20px;
                    transform: rotate(50deg);
                  }
        
                  &.menuicons span:nth-child(2) {
                    transform: translateY(0px);
                    width: 20px;
                    transform: rotate(128deg);
                  }
        
                  & span {
                    position: absolute;
                    width: 30px;
                    height: 2.5px;
                    background-color: $primary_color;
                    border-radius: 4px;
                    transition: 0.5s;
                    right: 15px;
        
                    &:nth-child(1) {
                      transform: translateY(-8px);
                      width: 20px;
                      left: 15px;
                    }
        
                    &:nth-child(2) {
                      transform: translateY(8px);
                      width: 20px;
                      left: 15px;
                    }
                  }
                }
        
                .menu-one {
                  display: none;
                }
              }
        }
    }
}