@import '../../SCSS/variables';

.personalDetails_con {
    width: 100%;
    padding: 2rem 3rem;

    .heading {
        text-transform: uppercase;
        font-size: 5rem;
        font-weight: 800;
        color: $cta_color;
    }

    .content_con {
        width: 100%;
        margin-top: 1.5rem;

        .content_box {
            border: 1px solid $cta_color;
            padding: 2rem;
            border-radius: 15px;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1), 3px 2px 13px rgba(0, 0, 0, 0.1);
            margin-bottom: 2rem;

            .personal-fields-container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                margin-top: 2rem;
            }

            .input-field-cont {
                display: flex;
                flex-direction: column;
                gap: 0.6rem;
                margin-inline: 1rem;
                margin-bottom: 1.2rem;

                label {
                    font-weight: bolder;
                    margin-bottom: 10px;
                    font-size: 20px;
                    font-weight: 700;
                    cursor: pointer;
                    color: $cta_color;
                }
                p.error-message{
                    color: red;
                }
                input,
                select {
                    padding: 0.5rem 1rem;
                    outline: none;
                    border-radius: 10px;
                    border: 1px solid grey;
                    text-align: start;
                    font-size: 24px;
                    color: $black_text_color;
                    font-weight: 500;
                    cursor: pointer;
                    width: 100%;
                    &:focus {
                        background-color: $secondary_color;
                    }
                }


            }

            .mobile-number-field-con {
                background-color: $white;
                display: flex;
                border-radius: 10px;

                select,
                input {
                    border-radius: 10px;
                    box-shadow: 0px 0px 0px;
                    cursor: pointer;
                   
                    &:hover {
                        cursor: auto;
                        /* Override cursor to default on hover */
                    }
                }


            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50%;
                margin-left: 25%;
                margin-top: 20px;
                // .otpBtn {
                //     background-color: $cta_color;
                //     // padding: 1rem;
                //     // text-transform: capitalize;
                //     // font-size: 24px;
                //     // font-weight: 700;
                //     // margin-bottom: 1rem;
                //     width: 300px; /* Adjust as needed */
                //     padding: 0.5rem 3rem;
                //     background-color: $primary_color !important; /* Ensure this variable is defined */
                //     color: $white; /* Set text color explicitly */
                //     border: none; /* Remove default border if needed */
                //     border-radius: 5px;
                //     // border: 1px solid $white;
                //     font-size: 24px;
                //     font-weight: 700;
                //     text-align: center;
                //     box-shadow: none;
                //     background-image: none;
                
                //     &:hover {
                //       background-color: rgba($color: $cta_color, $alpha: 0.8);
                //       cursor: pointer;
                //     }
                //   }
                button {
                    width: 300px; /* Adjust as needed */
                    padding: 0.5rem 3rem;
                    background-color: $primary_color !important; /* Ensure this variable is defined */
                    color: $white; /* Set text color explicitly */
                    border: none; /* Remove default border if needed */
                    border-radius: 5px;
                    // border: 1px solid $white;
                    font-size: 24px;
                    font-weight: 700;
                    text-align: center;
                    box-shadow: none;
                    background-image: none;

                    &:hover {
                      transition: all 0.1s ease-in 0.1s;
                      background-color: $white !important;
                      color: $cta_color;
                      border: 1px solid $cta_color;
                      }
                     
                }
              
            }
        }
    }
}


@media screen and (max-width: 1399px) {
    .personalDetails_con {
        width: 100%;
        padding: 1rem 3rem;
        margin-top: 5%;

        .heading {
            font-size: 4.5rem;
        }

        .content_con {
            margin-top: 1.5rem;

            .content_box {
                padding: 2rem;
                margin-bottom: 2rem;

                .personal-fields-container {
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 2rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.4rem;
                    margin-inline: 1rem;
                    margin-bottom: 1.2rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 22px;
                    }

                    input,
                    select {
                        padding: 0.5rem 1rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 20px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }

                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;
                        width: 100%; 

                        &:hover {
                            cursor: auto;
                        }
                    }


                }

                .button-container {
                    display: flex;
  justify-content: center;
  align-items: center;

                    button {
                        width: 100%; /* Adjust as needed */
                        padding: 0.5rem 3rem;
                        background-color: $primary_color !important; /* Ensure this variable is defined */
                        color: $white; /* Set text color explicitly */
                        border: none; /* Remove default border if needed */
                        border-radius: 5px;
                        font-size: 24px;
                        font-weight: 700;
                        text-align: center;
                        box-shadow: none;
                        &:hover {
                            transition: all 0.1s ease-in 0.1s;
                            background-color: $white !important;
                            color: $cta_color;
                            border: 1px solid $cta_color;
                            }
      
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .personalDetails_con {
        width: 100%;
        padding: 1rem 2.5rem;

        .heading {
            font-size: 3.8rem;
        }

        .content_con {
            margin-top: 1.5rem;

            .content_box {
                padding: 1.5rem;
                margin-bottom: 2rem;

                .personal-fields-container {
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 2rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.4rem;
                    margin-inline: 1rem;
                    margin-bottom: 1.2rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 18px;
                    }

                    input,
                    select {
                        padding: 0.5rem 1rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 18px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }

                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }


                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    button {
                        width: 100%; /* Adjust as needed */
                        padding: 0.5rem 3rem;
                        background-color: $primary_color !important; /* Ensure this variable is defined */
                        color: $white; /* Set text color explicitly */
                        border: none; /* Remove default border if needed */
                        border-radius: 5px;
                        font-size: 24px;
                        font-weight: 700;
                        text-align: center;
                        box-shadow: none;
                        &:hover {
                            transition: all 0.1s ease-in 0.1s;
                            background-color: $white !important;
                            color: $cta_color;
                            border: 1px solid $cta_color;
                            }
      
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .personalDetails_con {
        width: 100%;
        padding: 1rem 1.2rem;

        .heading {
            font-size: 3rem;
        }

        .content_con {
            margin-top: 1.5rem;

            .content_box {
                padding: 1rem;
                margin-bottom: 2rem;

                .personal-fields-container {
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 2rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.2rem;
                    margin-inline: 0.5rem;
                    margin-bottom: 1.2rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 14px;
                    }

                    input,
                    select {
                        padding: 0.5rem 0.8rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 14px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }

                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }


                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    button {
                        width: 100%; /* Adjust as needed */
                        padding: 0.5rem 3rem;
                        background-color: $primary_color !important; /* Ensure this variable is defined */
                        color: $white; /* Set text color explicitly */
                        border: none; /* Remove default border if needed */
                        border-radius: 5px;
                        font-size: 24px;
                        font-weight: 700;
                        text-align: center;
                        box-shadow: none;

                        &:hover {
                            transition: all 0.1s ease-in 0.1s;
                            background-color: $white !important;
                            color: $cta_color;
                            border: 1px solid $cta_color;
                            }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .personalDetails_con {
        width: 100%;
        padding: 3rem 1rem;

        .heading {
            font-size: 2.5rem;
        }

        .content_con {
            margin-top: 1.5rem;

            .content_box {
                padding: 0.8rem;
                margin-bottom: 1.5rem;

                .personal-fields-container {
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 1rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.1rem;
                    margin-inline: 0.2rem;
                    margin-bottom: 1rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 12px;
                    }

                    input,
                    select {
                        padding: 0.5rem 0.8rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 12px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }

                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }


                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .personalDetails_con {
        width: 100%;
        padding: 2rem 0.8rem;

        .heading {
            font-size: 2.1rem;
        }

        .content_con {
            margin-top: 1.2rem;

            .content_box {
                padding: 1rem;
                margin-bottom: 1.2rem;

                .personal-fields-container {
                    grid-template-columns: repeat(1, 1fr);
                    margin-top: 1rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.1rem;
                    margin-inline: 0.2rem;
                    margin-bottom: 1rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 11px;
                    }

                    input,
                    select {
                        padding: 0.5rem 0.8rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 11px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }


                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }



                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    button {
                        width: 100%; /* Adjust as needed */
                        padding: 0.2rem 1.8rem;
                        background-color: $primary_color !important; /* Ensure this variable is defined */
                        color: $white; /* Set text color explicitly */
                        border: none; /* Remove default border if needed */
                        border-radius: 5px;
                        font-size: 24px;
                        font-weight: 700;
                        text-align: center;
                        box-shadow: none;

                        &:hover {
                            transition: all 0.1s ease-in 0.1s;
                            background-color: $white !important;
                            color: $cta_color;
                            border: 1px solid $cta_color;
                            }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .personalDetails_con {
        width: 100%;
        padding: 2rem 0.5rem;

        .heading {
            font-size: 1.4rem;
        }

        .content_con {
            margin-top: 1rem;

            .content_box {
                padding: 1rem;
                margin-bottom: 1.2rem;
                .personal-fields-container {
                    grid-template-columns: repeat(1, 1fr);
                    margin-top: 0.8rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.1rem;
                    margin-inline: 0.2rem;
                    margin-bottom: 0.8rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 8px;
                    }

                    input,
                    select {
                        padding: 0.5rem 0.8rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 8px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }
                    .button-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50%;
                        margin-left: 30%;
                        margin-top: 20px;
                        button {
                        //    width: 200px;
                            padding: 0.2rem 1.8rem;
                            background-color: $primary_color !important; /* Ensure this variable is defined */
                            color: $white; /* Set text color explicitly */
                            border: none; /* Remove default border if needed */
                            border-radius: 5px;
                            font-size: 24px;
                            font-weight: 700;
                            text-align: center;
                            box-shadow: none;
    
                            &:hover {
                                transition: all 0.1s ease-in 0.1s;
                                background-color: $white !important;
                                color: $cta_color;
                                border: 1px solid $cta_color;
                                }
                        }
                    }

                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }


                }
            }
        }
    }
}

//otp
.header-section {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .divider {
      border: 2px solid black;
      width: 35px;
    }
    .heading-otp {
        h1 {
          font-size: 36px;
          font-weight: 800;
        }
  }
}

.otp-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  .terms_cond{
   text-align: center;
    font-size: 17px;
    // margin: 1rem;
    margin-top: 0.6rem;
    font-weight: 500;
    color: #494949;
    Link{
       color: $primary_color;
    }
   }
  h5 {
    font-size: 14px;
    text-align: center;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
  }

  .resend_otp {
    text-align: center;
    margin-top: 3rem;
  }

  a {
    font-weight: 300;
    color: $primary_color;
  }

  .otp-inputs {
    display: flex;
    justify-content: space-between;
    width: 62%;
    border: 1px solid #00000054;
    border-radius: 1px;
    padding: 0.5rem;
    box-sizing: border-box;
   gap: 10px;

  
    input[type="tel"] {
      width: 48px;
      height: 40px;
      text-align: center;
      font-size: 18px;
      border: none;
      box-sizing: border-box; 
      border-right: 1px solid #ccc;
    //   margin-right: 10px;
      &:focus {
        outline: none;
      }
    }

   
  }


}
#buton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .otpBtn {
        background-color: $cta_color;
        // padding: 1rem;
        text-transform: capitalize;
        font-size: 20%;
        font-weight: 700;
        margin-bottom: 1rem;
        color: $white;
        //padding: 10px 70px;
        border: 0px;
        border-radius: 5px;
        width: 35%;
        
        &:hover {
          background-color: rgba($color: $cta_color, $alpha: 0.8);
    
        }
      }
}
