@import "../../SCSS/variables";

.dropdown {
    position: relative;
    display: inline-block;
    min-width: 200px;

    &Toggle {
        border: 1px solid $cta_color;
        padding: 8px 12px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        color: $cta_color;
        border-radius: 5px;

        i {
            font-size: 20px;
            color: $cta_color;
        }
    }

    &Menu {
        position: absolute;
        top: 100%;
        left: 0;
        background: white;
        border: 1px solid green;
        width: 100%;
        z-index: 1;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &Item {
        padding: 8px 12px;
        cursor: pointer;
        color: $cta_color;
        font-weight: 600;

        &:hover {
            background: #f0f0f0;
        }
    }

    .arrow {
        margin-left: 8px;
    }
}

@media screen and (max-width:992px) {
    .dropdown {
        position: relative;
        display: inline-block;
        min-width: 200px;

        &Toggle {
            border: 1px solid $cta_color;
            padding: 8px 12px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            color: $cta_color;
            border-radius: 5px;
            font-size: 14px;

            i {
                font-size: 16px;
                color: $cta_color;
            }
        }

        &Menu {
            position: absolute;
            top: 100%;
            left: 0;
            background: white;
            border: 1px solid green;
            width: 100%;
            z-index: 1;
            list-style: none;
            padding: 0;
            margin: 0;
        }

        &Item {
            padding: 8px 12px;
            cursor: pointer;
            color: $cta_color;
            font-weight: 600;
            font-size: 14px;

            &:hover {
                background: #f0f0f0;
            }
        }

        .arrow {
            margin-left: 8px;
        }
    }
}