@import "../../../SCSS/variables";

.GameFeedbackSidebar {
    padding: 2rem;

    .backButton {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
    }

    hr {
        border-bottom: 1px solid gray;
    }

    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        li {

            margin-block: 0.5rem;
            width: max-content;

            a {
                display: flex;
                align-items: center;
                gap: 1rem;
                text-decoration: none;
                color: black;
                font-size: 1.2rem;
                padding: 0.7rem 1rem;
                border: 2px solid white;
                border-radius: 10px;
            }

            .active {
                background-color: $mediumgreen;
                border: 2px solid $cta_color;
            }
        }


    }



}