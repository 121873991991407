@import '../../../SCSS/variables';

.personalDetails_con {
    width: 100%;
    padding: 2rem 3rem;

    .heading {
        text-transform: uppercase;
        font-size: 5rem;
        font-weight: 800;
        color: $cta_color;
    }

    .content_con {
        width: 100%;
        margin-top: 1.5rem;

        .content_box {
            border: 1px solid $cta_color;
            padding: 2rem;
            border-radius: 15px;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1), 3px 2px 13px rgba(0, 0, 0, 0.1);
            margin-bottom: 2rem;

            .content_header {
                display: flex;
                justify-content: space-between;

                h2 {
                    font-size: 2rem;
                    font-weight: 700;
                }

                button {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    border: 1px solid $cta_color;
                    color: $cta_color;
                    padding: 10px 20px;
                    border-radius: 50px;
                    font-weight: 700;
                    font-size: 18px;
                    background-color: $white;
                }
            }

            .photo_container {
                display: flex;
                align-items: center;
                gap: 2rem;
                margin-top: 2rem;

                .img_cont {
                    width: 180px;
                    height: 180px;
                    border-radius: 50%;
                    border: 1px solid $cta_color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;

                    .image-box {
                        flex-grow: 1;
                        height: 100%;
                        width: 100%;
                    }

                }



                label {
                    font-size: 20px;
                    color: $cta_color;
                    font-weight: 700;
                    cursor: pointer;
                }

                input {
                    opacity: 0;
                    width: 0px;
                }
            }

            .personal-fields-container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                margin-top: 2rem;
            }

            .additional-fields-container {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                margin-top: 2rem;
            }

            .input-field-cont {
                display: flex;
                flex-direction: column;
                gap: 0.6rem;
                margin-inline: 1rem;
                margin-bottom: 1.2rem;

                label {
                    font-weight: bolder;
                    margin-bottom: 10px;
                    font-size: 20px;
                    font-weight: 700;
                    cursor: pointer;
                    color: $cta_color;
                }

                input,
                textarea,
                select {
                    padding: 0.5rem 1rem;
                    outline: none;
                    border-radius: 10px;
                    border: 1px solid grey;
                    text-align: start;
                    font-size: 24px;
                    color: $black_text_color;
                    font-weight: 500;
                    cursor: pointer;

                    &:focus {
                        background-color: $secondary_color;
                    }
                }


                ul {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 1rem;

                    li {
                        background-color: white;

                        padding: 1rem;
                        outline: none;
                        border-radius: 10px;
                        border: none;
                        font-size: 24px;
                        font-weight: 500;
                        color: $black_text_color;
                        text-align: center;
                        cursor: pointer;
                    }

                    .active {
                        background-color: $secondary_color;
                        color: black;
                    }
                }

            }

            .sub-input-field-cont {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            .mobile-number-field-con {
                background-color: $white;
                display: flex;
                border-radius: 10px;

                select,
                input {
                    border-radius: 10px;
                    box-shadow: 0px 0px 0px;
                    cursor: pointer;

                    &:hover {
                        cursor: auto;
                        /* Override cursor to default on hover */
                    }
                }


            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    background-color: $secondary_color;
                    padding: 0.5rem 3rem;
                    border-radius: 5px;
                    font-size: 24px;
                    font-weight: 500;
                }
            }
        }
    }
}


@media screen and (max-width: 1399px) {
    .personalDetails_con {
        width: 100%;
        padding: 1rem 3rem;

        .heading {
            font-size: 4.5rem;
        }

        .content_con {
            margin-top: 1.5rem;

            .content_box {
                padding: 2rem;
                margin-bottom: 2rem;

                .content_header {
                    justify-content: space-between;

                    h2 {
                        font-size: 1.8rem;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 10px 20px;
                        border-radius: 50px;
                        font-size: 18px;
                    }
                }

                .photo_container {
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    margin-top: 2rem;

                    .img_cont {
                        width: 180px;
                        height: 180px;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        .image-box {
                            flex-grow: 1;
                            height: 100%;
                            width: 100%;
                        }

                    }



                    label {
                        font-size: 20px;
                    }

                    input {
                        opacity: 0;
                        width: 0px;
                    }
                }

                .personal-fields-container {
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 2rem;
                }

                .additional-fields-container {
                    grid-template-columns: repeat(1, 1fr);
                    margin-top: 2rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.4rem;
                    margin-inline: 1rem;
                    margin-bottom: 1.2rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 22px;
                    }

                    input,
                    textarea,
                    select {
                        padding: 0.5rem 1rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 20px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }


                    ul {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 1rem;

                        li {
                            padding: 1rem;
                            outline: none;
                            border-radius: 10px;
                            border: none;
                            font-size: 24px;
                            text-align: center;
                        }

                    }

                }

                .sub-input-field-cont {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }


                }

                .button-container {
                    justify-content: center;
                    align-items: center;

                    button {
                        padding: 0.5rem 3rem;
                        border-radius: 5px;
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .personalDetails_con {
        width: 100%;
        padding: 1rem 2.5rem;

        .heading {
            font-size: 3.8rem;
        }

        .content_con {
            margin-top: 1.5rem;

            .content_box {
                padding: 1.5rem;
                margin-bottom: 2rem;

                .content_header {
                    justify-content: space-between;

                    h2 {
                        font-size: 1.7rem;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 10px 20px;
                        border-radius: 50px;
                        font-size: 16px;
                    }
                }

                .photo_container {
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    margin-top: 2rem;

                    .img_cont {
                        width: 160px;
                        height: 160px;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        .image-box {
                            flex-grow: 1;
                            height: 100%;
                            width: 100%;
                        }

                    }



                    label {
                        font-size: 20px;
                    }

                    input {
                        opacity: 0;
                        width: 0px;
                    }
                }

                .personal-fields-container {
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 2rem;
                }

                .additional-fields-container {
                    grid-template-columns: repeat(1, 1fr);
                    margin-top: 2rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.4rem;
                    margin-inline: 1rem;
                    margin-bottom: 1.2rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 18px;
                    }

                    input,
                    textarea,
                    select {
                        padding: 0.5rem 1rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 18px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }


                    ul {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 1rem;

                        li {
                            padding: 1rem;
                            outline: none;
                            border-radius: 10px;
                            border: none;
                            font-size: 24px;
                            text-align: center;
                        }

                    }

                }

                .sub-input-field-cont {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }


                }

                .button-container {
                    justify-content: center;
                    align-items: center;

                    button {
                        padding: 0.5rem 3rem;
                        border-radius: 5px;
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .personalDetails_con {
        width: 100%;
        padding: 1rem 1.2rem;

        .heading {
            font-size: 3rem;
        }

        .content_con {
            margin-top: 1.5rem;

            .content_box {
                padding: 1rem;
                margin-bottom: 2rem;

                .content_header {
                    justify-content: space-between;

                    h2 {
                        font-size: 1.6rem;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 8px 18px;
                        border-radius: 50px;
                        font-size: 15px;
                    }
                }

                .photo_container {
                    display: flex;
                    align-items: center;
                    gap: 1.6rem;
                    margin-top: 2rem;

                    .img_cont {
                        width: 140px;
                        height: 140px;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        .image-box {
                            flex-grow: 1;
                            height: 100%;
                            width: 100%;
                        }

                    }



                    label {
                        font-size: 18px;
                    }

                    input {
                        opacity: 0;
                        width: 0px;
                    }
                }

                .personal-fields-container {
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 2rem;
                }

                .additional-fields-container {
                    grid-template-columns: repeat(1, 1fr);
                    margin-top: 2rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.2rem;
                    margin-inline: 0.5rem;
                    margin-bottom: 1.2rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 14px;
                    }

                    input,
                    textarea,
                    select {
                        padding: 0.5rem 0.8rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 14px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }


                    ul {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 1rem;

                        li {
                            padding: 1rem;
                            outline: none;
                            border-radius: 10px;
                            border: none;
                            font-size: 24px;
                            text-align: center;
                        }

                    }

                }

                .sub-input-field-cont {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }


                }

                .button-container {
                    justify-content: center;
                    align-items: center;

                    button {
                        padding: 0.5rem 3rem;
                        border-radius: 5px;
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .personalDetails_con {
        width: 100%;
        padding: 3rem 1rem;

        .heading {
            font-size: 2.5rem;
        }

        .content_con {
            margin-top: 1.5rem;

            .content_box {
                padding: 0.8rem;
                margin-bottom: 1.5rem;

                .content_header {
                    justify-content: space-between;

                    h2 {
                        font-size: 1.3rem;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 6px 14px;
                        border-radius: 50px;
                        font-size: 12px;
                    }
                }

                .photo_container {
                    display: flex;
                    align-items: center;
                    gap: 1.6rem;
                    margin-top: 1.5rem;

                    .img_cont {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        .image-box {
                            flex-grow: 1;
                            height: 100%;
                            width: 100%;
                        }

                    }



                    label {
                        font-size: 14px;
                    }

                    input {
                        opacity: 0;
                        width: 0px;
                    }
                }

                .personal-fields-container {
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 1rem;
                }

                .additional-fields-container {
                    grid-template-columns: repeat(1, 1fr);
                    margin-top: 1rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.1rem;
                    margin-inline: 0.2rem;
                    margin-bottom: 1rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 12px;
                    }

                    input,
                    textarea,
                    select {
                        padding: 0.5rem 0.8rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 12px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }


                    ul {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 1rem;

                        li {
                            padding: 1rem;
                            outline: none;
                            border-radius: 10px;
                            border: none;
                            font-size: 24px;
                            text-align: center;
                        }

                    }

                }

                .sub-input-field-cont {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }


                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .personalDetails_con {
        width: 100%;
        padding: 2rem 0.8rem;

        .heading {
            font-size: 2.1rem;
        }

        .content_con {
            margin-top: 1.2rem;

            .content_box {
                padding: 1rem;
                margin-bottom: 1.2rem;

                .content_header {
                    justify-content: space-between;

                    h2 {
                        font-size: 1.2rem;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 4px 12px;
                        border-radius: 50px;
                        font-size: 11px;
                    }
                }

                .photo_container {
                    display: flex;
                    align-items: center;
                    gap: 1.6rem;
                    margin-top: 1.5rem;

                    .img_cont {
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        .image-box {
                            flex-grow: 1;
                            height: 100%;
                            width: 100%;
                        }

                    }



                    label {
                        font-size: 12px;
                    }

                    input {
                        opacity: 0;
                        width: 0px;
                    }
                }

                .personal-fields-container {
                    grid-template-columns: repeat(1, 1fr);
                    margin-top: 1rem;
                }

                .additional-fields-container {
                    grid-template-columns: repeat(1, 1fr);
                    margin-top: 1rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.1rem;
                    margin-inline: 0.2rem;
                    margin-bottom: 1rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 11px;
                    }

                    input,
                    textarea,
                    select {
                        padding: 0.5rem 0.8rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 11px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }


                }

                .sub-input-field-cont {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }


                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .personalDetails_con {
        width: 100%;
        padding: 2rem 0.5rem;

        .heading {
            font-size: 1.4rem;
        }

        .content_con {
            margin-top: 1rem;

            .content_box {
                padding: 1rem;
                margin-bottom: 1.2rem;

                .content_header {
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        font-size: 0.8rem;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        padding: 4px 10px;
                        border-radius: 50px;
                        font-size: 10px;
                    }
                }

                .photo_container {
                    display: flex;
                    align-items: center;
                    gap: 1.6rem;
                    margin-top: 1.2rem;

                    .img_cont {
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        .image-box {
                            flex-grow: 1;
                            height: 100%;
                            width: 100%;
                        }

                    }



                    label {
                        font-size: 11px;
                    }

                    input {
                        opacity: 0;
                        width: 0px;
                    }
                }

                .personal-fields-container {
                    grid-template-columns: repeat(1, 1fr);
                    margin-top: 0.8rem;
                }

                .additional-fields-container {
                    grid-template-columns: repeat(1, 1fr);
                    margin-top: 1rem;
                }

                .input-field-cont {
                    flex-direction: column;
                    gap: 0.1rem;
                    margin-inline: 0.2rem;
                    margin-bottom: 0.8rem;

                    label {
                        font-weight: bolder;
                        margin-bottom: 5px;
                        font-size: 8px;
                    }

                    input,
                    textarea,
                    select {
                        padding: 0.5rem 0.8rem;
                        border-radius: 10px;
                        text-align: start;
                        font-size: 8px;

                        &:focus {
                            background-color: $secondary_color;
                        }
                    }


                }

                .sub-input-field-cont {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                }

                .mobile-number-field-con {

                    select,
                    input {
                        box-shadow: 0px 0px 0px;
                        cursor: pointer;

                        &:hover {
                            cursor: auto;
                        }
                    }


                }
            }
        }
    }
}