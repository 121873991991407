@import "../../SCSS/variables";



.feedback_video {
  margin-bottom: 2rem;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .video_section {
    position: relative;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
    // width: 100%;
  }

  .video_container {
    position: relative;

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      /* Semi-transparent overlay */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }

    .poster {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      opacity: 0.8;
      /* Slight opacity for poster */
      z-index: 0;
    }

    .spinner {
      border: 4px solid rgba(255, 255, 255, 0.3);
      border-top: 4px solid white;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
      z-index: 1;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    // video {
    //   // z-index: 1;
    //   width:"auto" !important;

    // }

    .konva_stage {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: auto;
      z-index: 10;
    }

    .custom_loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(255, 255, 255);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 10;
      color: $primary_color;
    }

    .custom_loader .spinner {
      width: 50px;
      height: 50px;
      border: 5px solid #247e4568;
      border-top-color: $primary_color;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }

  .sidebar {
    width: 100%;
    padding: 20px 10px;
    border-left: 1px solid #ccc;
    background-color: #d9f0de;
    border-radius: 10px;
    height: 65vh;
    overflow-y: auto;

    .loader {
      text-align: center;
      padding: 20px;
      font-size: 18px;
      color: #555;
    }

    .no_data {
      text-align: center;
      color: gray;
      font-size: 12px;
      margin-top: 3rem;
    }

    h4 {
      margin-bottom: 1rem;
      font-size: 16px;
      font-weight: 16px;
    }

    textarea {
      width: 100%;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 14px;

    }

    .save_button {
      margin-top: 10px;
      border: none;
      width: 100%;
      background: #026b33;
      padding: 0.5rem;
      color: #fff;
      border-radius: 5px;
      font-size: 14px;
      .loader {
        text-align: center;
        padding: 20px;
        font-size: 18px;
        color: #555;
      }
      &:disabled {
        background-color: #d3d3d3; // Grey color for disabled state
        color: #a9a9a9;
        cursor: not-allowed;
        border: none;
      }
    }
        .audio_button {
      border: none;
      width: 100%;
      background: #026b33;
      padding: 0.5rem;
      color: #fff;
      border-radius: 5px;
      font-size: 14px;
      margin-top: 0.5rem;
      cursor: pointer;
      &:disabled {
        background-color: #d3d3d3; // Grey color for disabled state
        color: #a9a9a9;
        cursor: not-allowed;
        border: none;
      }
    }

    .audio_button.recording {
      color: red;
      animation: pulse 1s infinite;
    }


    .annotations_list {
      margin-top: 20px;
      transition: all 0.5s ease-in-out;

      .active_comment {
        background-color: #014f25 !important;
        border-left: 4px solid #005226;
        color: #ffffff;

        .comment_text {
          color: #026B33;
        }
      }

      p {
        margin: 0;
      }

      @keyframes move-to-top {
        0% {
          opacity: 0;
          transform: translateY(10px);
        }

        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .annotation_item {
        margin-bottom: 20px;
        border-radius: 8px;

        .annotation_header {
          background-color: #f7f7f7;
          border-radius: 5px;
          padding: 0.5rem;

          h6 {
            font-size: 12px;
            color: #005226;
          }

          p {
            margin: 0;
            font-size: 12px;
            color: #000;
            overflow-wrap: break-word;
            white-space: normal;
          }

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
          }

          strong {
            text-transform: capitalize;
            font-size: 14px;
          }

          .time_range {
            font-size: 10px;
            color: #aeaeae;
            font-weight: 400;
            display: flex;
            justify-content: flex-end;
          }

          audio {
            width: 100%;
            margin-top: 0.5rem;
          }
        }

        .annotation_body {
          padding-top: 0.5rem;
          font-size: 14px;
          width: 100%;
        }

        .seeMoreButton {
          background: none;
          border: none;
          color: rgb(11, 11, 103);
          cursor: pointer;
          font-size: 10px;
          text-decoration: underline;
          padding: 0;
          margin-top: 5px;
        }
      }
    }

    .comment_actions {
      display: flex;
      gap: 8px;
      // margin-top: 8px;
      justify-content: flex-end;

      button {
        background: none;
        border: none;
        padding: 2px;
        cursor: pointer;
        border-radius: 4px;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;

        &:hover {
          transform: scale(1.1);
        }
      }

      .edit_button {
        color: #4a90e2;

        &:hover {
          background-color: rgba(74, 144, 226, 0.1);
        }
      }

      .delete_button {
        color: #e25c5c;

        &:hover {
          background-color: rgba(226, 92, 92, 0.1);
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    margin: 1rem 0;



    button {
      width: 20%;
      padding: 0.5rem;
      border-radius: 5px;

      &.cancel {
        background: transparent;
        color: #000;
        border: 2px solid #026b33;
      }



      &.submit {
        background: #026b33;
        color: #fff;
        border: 2px solid #026b33;

        &:disabled {
          background-color: #d3d3d3; // Grey color for disabled state
          color: #a9a9a9;
          cursor: not-allowed;
          border: none;
        }
      }
    }
  }

  .feedback_input_container {
    position: relative;
    display: block;
    
    textarea {
      width: 100%;
      padding: 0.5rem;
      padding-right: 50px; // Make space for the mic button
      margin-bottom: 0.5rem;
      resize: vertical;
      min-height: 80px;
    }
    
    .audio_button {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      min-width: 30px;
      background: #026b33;
      color: #fff;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      margin: 0;
      padding: 0;
      
      &.recording {
        background: #ff4444;
        color: #fff;
        animation: pulse 1.5s ease-in-out infinite;
      }
    }
  }

  .audio_preview {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    audio {
      flex: 1;
    }
    .delete_button {
      background: none;
      border: none;
      color: red;
      cursor: pointer;
      margin-left: 0.5rem;
    }
  }

}


@media screen and (max-width: 768px) {
  .feedback_video {
    margin-bottom: 2rem;

    h1 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .video_section {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
      margin-top: 1rem;
      width: 100%;
    }

    .video_container {
      display: block;

      video {
        border-radius: 10px;
        width: 100% !important;
      }

      .konva_stage {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: auto;
        touch-action: none;
      }

      .custom_loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;
        color: $primary_color;
      }

      .custom_loader .spinner {
        width: 50px;
        height: 50px;
        border: 5px solid #247e4568;
        border-top-color: $primary_color;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    }

    .sidebar {
      width: 100%;
      padding: 20px 10px;
      border-left: 1px solid #ccc;
      background-color: #d9f0de;
      border-radius: 10px;
      height: 65vh;
      overflow-y: auto;


      h4 {
        margin-bottom: 1rem;
        font-size: 16px;
        font-weight: 16px;
      }

      textarea {
        width: 100%;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 14px;
      }

      .save_button {
        border: none;
        width: 100%;
        background: #026b33;
        padding: 0.5rem;
        color: #fff;
        border-radius: 5px;
        font-size: 14px;
      }

      .annotations_list {
        margin-top: 20px;
        transition: all 0.5s ease-in-out;

        .active_comment {
          background-color: #014f25 !important;
          border-left: 4px solid #005226;
          color: #ffffff;

          .comment_text {
            color: #026B33;
          }
        }

        p {
          margin: 0;
        }

        @keyframes move-to-top {
          0% {
            opacity: 0;
            transform: translateY(10px);
          }

          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .annotation_item {
          margin-bottom: 20px;
          border-radius: 8px;

          .annotation_header {
            background-color: #f7f7f7;
            border-radius: 5px;
            padding: 0.5rem;

            h6 {
              font-size: 12px;
              color: #005226;
            }

            p {
              margin: 0;
              font-size: 12px;
              color: #000;
            }

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
              margin-right: 10px;
            }

            strong {
              text-transform: capitalize;
              font-size: 14px;
            }

            .time_range {
              font-size: 10px;
              color: #aeaeae;
              font-weight: 400;
              display: flex;
              justify-content: flex-end;
            }
          }

          .annotation_body {
            padding-top: 0.5rem;
            font-size: 14px;
            width: 100%;
          }

          .seeMoreButton {
            background: none;
            border: none;
            color: rgb(11, 11, 103);
            cursor: pointer;
            font-size: 10px;
            text-decoration: underline;
            padding: 0;
            margin-top: 5px;
          }
        }
      }

      .comment_actions {
        display: flex;
        gap: 8px;
        margin-top: 8px;

        button {
          background: none;
          border: none;
          padding: 6px;
          cursor: pointer;
          border-radius: 4px;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            transform: scale(1.1);
          }
        }

        .edit_button {
          color: #4a90e2;

          &:hover {
            background-color: rgba(74, 144, 226, 0.1);
          }
        }

        .delete_button {
          color: #e25c5c;

          &:hover {
            background-color: rgba(226, 92, 92, 0.1);
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: space-between;
      margin: 1rem 0;



      button {
        width: 50%;
        padding: 0.5rem 0;
        border-radius: 5px;
        font-size: 12px;

        &.cancel {
          background: transparent;
          color: #000;
          border: 2px solid #026b33;
        }



        &.submit {
          background: #026b33;
          color: #fff;
          border: 2px solid #026b33;

          &:disabled {
            background-color: #d3d3d3; // Grey color for disabled state
            color: #a9a9a9;
            cursor: not-allowed;
            border: none;
          }
        }
      }
    }

  }
}

.edit_actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &.save_edit:hover {
      background-color: rgba(40, 167, 69, 0.1); // Light green background on hover
    }

    &.cancel_edit:hover {
      background-color: rgba(220, 53, 69, 0.1); // Light red background on hover
    }
  }
}

.comment_text {
  white-space: pre-wrap;
  word-break: break-word;
  margin: 5px 0;
}

.seeMoreButton {
  background: none;
  border: none;
  color: #007bff;
  padding: 0;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 5px;
  
  &:hover {
    text-decoration: underline;
  }
}

.fullPageLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    background: #ff0000;
  }
  100% {
    transform: scale(1);
  }
}