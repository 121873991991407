@import "../../../SCSS/variables";

.ReportSideBar {
    background-color: $primary_color;
    padding-top: 1rem;
    padding-right: 5px;
    height: 100vh;

    .report_side_nav {
        .head {
            h3 {
                border-bottom: 1px solid $white;
                color: $white;
                padding: 0.5rem;
                font-size: 24px;
                margin: 0 3rem;
            }
        }

        .loading_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: #fff;
        }

        .video_cont {
            overflow-y: scroll;
            max-height: 80vh;
            padding: 0 2.5rem;
            margin-top: 2rem;
            width: 100%;
            padding-bottom: 50px;

            .displayNone {
                display: none !important;
            }

            .uploaded_video {
                cursor: pointer;

                .uploadVideoHeader {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    h6 {
                        margin-left: 1rem;
                        position: relative;
                        font-size: 18px;
                        font-weight: 500;
                        color: $white;

                        &::after {
                            position: absolute;
                            top: 0;
                            left: -15px;
                            right: 10;
                            bottom: 0;
                            content: "";
                            background-color: $white;
                            height: 100%;
                            width: 3px;
                        }
                    }



                }



                .uploadeVideoThumbnailImg {
                    width: 100%;
                    border-radius: 10px;

                    &:hover {
                        border: 3px solid white;
                    }
                }

                .active_uploaded_video {
                    border: 3px solid white;

                }
            }



            .option {
                margin-top: 2rem;

                .techniqueHeader {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    h6 {
                        margin-left: 1rem;
                        position: relative;
                        font-size: 18px;
                        font-weight: 500;
                        color: $white;
                        text-transform: capitalize;

                        &::after {
                            position: absolute;
                            top: 0;
                            left: -15px;
                            right: 10;
                            bottom: 0;
                            content: "";
                            background-color: $white;
                            height: 100%;
                            width: 3px;
                        }
                    }
                }

                .videoBox {

                    background-color: $white_text_color;
                    padding: 1rem;
                    border-radius: 10px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;

                    .approach_container {
                        width: 100%;

                        h3 {
                            color: $cta_color;
                            margin-bottom: 5px;
                            font-weight: 600;
                            font-size: 14px;
                        }

                        .image_container {
                            position: relative;
                            border-radius: 10px;

                            .slide_panel {
                                width: 100%;
                                display: flex;
                                gap: 10px;
                                overflow-x: scroll;
                                scroll-snap-type: x mandatory;
                                scroll-behavior: smooth;
                                border-radius: 5px;

                                .slider_image {
                                    width: 100%;
                                    scroll-snap-align: start;
                                    cursor: pointer;
                                    object-fit: fill;
                                }
                            }

                            ::-webkit-scrollbar {
                                display: none;
                            }

                            span {
                                position: absolute;
                                bottom: 10px;
                                right: 10px;
                                background-color: #fff;
                                padding: 5px 12px;
                                font-size: 14px;
                                color: $cta_color;
                                font-weight: 500;
                                border-radius: 5px;
                            }

                            &:hover {
                                border: 3px solid black;
                            }
                        }

                        .image_container.selected {
                            border: 3px solid black;
                        }


                    }

                }

                .displayNone {
                    display: none !important;
                }
            }

            &::-webkit-scrollbar {
                width: 7px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: #fff;
                border-radius: 10px;
            }

            .shadow::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }
}

@media screen and (max-width: 1399px) {}


@media screen and (max-width: 1199px) {
    .ReportSideBar {
        background-color: $primary_color;
        padding-top: 1rem;
        padding-right: 5px;

        .report_side_nav {
            .head {
                h3 {
                    border-bottom: 1px solid $white;
                    color: $white;
                    padding: 0.5rem;
                    font-size: 22px;
                    margin: 0 3rem;
                }
            }

            .loading_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: #fff;
            }

            .video_cont {
                overflow-y: scroll;
                max-height: 80vh;
                padding: 0 1.5rem;
                margin-top: 2rem;
                width: 100%;
                padding-bottom: 50px;

                .displayNone {
                    display: none !important;
                }

                .uploaded_video {
                    cursor: pointer;

                    .uploadVideoHeader {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h6 {
                            margin-left: 1rem;
                            position: relative;
                            font-size: 16px;
                            font-weight: 500;
                            color: $white;

                            &::after {
                                position: absolute;
                                top: 0;
                                left: -15px;
                                right: 10;
                                bottom: 0;
                                content: "";
                                background-color: $white;
                                height: 100%;
                                width: 3px;
                            }
                        }



                    }



                    .uploadeVideoThumbnailImg {
                        width: 100%;
                        border-radius: 10px;

                        &:hover {
                            border: 3px solid white;
                        }
                    }

                    .active_uploaded_video {
                        border: 3px solid white;

                    }
                }



                .option {
                    margin-top: 2rem;

                    .techniqueHeader {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h6 {
                            margin-left: 1rem;
                            position: relative;
                            font-size: 16px;
                            font-weight: 500;
                            color: $white;
                            text-transform: capitalize;

                            &::after {
                                position: absolute;
                                top: 0;
                                left: -15px;
                                right: 10;
                                bottom: 0;
                                content: "";
                                background-color: $white;
                                height: 100%;
                                width: 3px;
                            }
                        }
                    }

                    .videoBox {

                        background-color: $white_text_color;
                        padding: 1rem;
                        border-radius: 10px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 1.5rem;

                        .approach_container {
                            width: 100%;

                            h3 {
                                color: $cta_color;
                                margin-bottom: 5px;
                                font-weight: 600;
                                font-size: 14px;
                            }

                            .image_container {
                                position: relative;
                                border-radius: 10px;

                                .slide_panel {
                                    width: 100%;
                                    display: flex;
                                    gap: 10px;
                                    overflow-x: scroll;
                                    scroll-snap-type: x mandatory;
                                    scroll-behavior: smooth;
                                    border-radius: 5px;

                                    .slider_image {
                                        width: 100%;
                                        height: 100%;
                                        scroll-snap-align: start;
                                    }
                                }

                                ::-webkit-scrollbar {
                                    display: none;
                                }

                                span {
                                    position: absolute;
                                    bottom: 10px;
                                    right: 10px;
                                    background-color: #fff;
                                    padding: 5px 12px;
                                    font-size: 14px;
                                    color: $cta_color;
                                    font-weight: 500;
                                    border-radius: 5px;
                                }

                                &:hover {
                                    border: 3px solid black;
                                }
                            }

                            .image_container.selected {
                                border: 3px solid black;
                            }


                        }

                    }

                    .displayNone {
                        display: none !important;
                    }
                }

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: #fff;
                    border-radius: 10px;
                }

                .shadow::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .ReportSideBar {
        background-color: $primary_color;
        padding-top: 1rem;
        padding-right: 5px;

        .report_side_nav {
            .head {
                h3 {
                    border-bottom: 1px solid $white;
                    color: $white;
                    padding: 0.5rem;
                    font-size: 20px;
                    margin: 0 3rem;
                }
            }

            .loading_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: #fff;
            }

            .video_cont {
                overflow-y: scroll;
                max-height: 80vh;
                padding: 0 1rem;
                margin-top: 2rem;
                width: 100%;
                padding-bottom: 50px;

                .displayNone {
                    display: none !important;
                }

                .uploaded_video {
                    cursor: pointer;

                    .uploadVideoHeader {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h6 {
                            margin-left: 1rem;
                            position: relative;
                            font-size: 14px;
                            font-weight: 500;
                            color: $white;

                            &::after {
                                position: absolute;
                                top: 0;
                                left: -15px;
                                right: 10;
                                bottom: 0;
                                content: "";
                                background-color: $white;
                                height: 100%;
                                width: 2px;
                            }
                        }



                    }



                    .uploadeVideoThumbnailImg {
                        width: 100%;
                        border-radius: 10px;

                        &:hover {
                            border: 3px solid white;
                        }
                    }

                    .active_uploaded_video {
                        border: 3px solid white;

                    }
                }



                .option {
                    margin-top: 2rem;

                    .techniqueHeader {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h6 {
                            margin-left: 1rem;
                            position: relative;
                            font-size: 14px;
                            font-weight: 500;
                            color: $white;
                            text-transform: capitalize;

                            &::after {
                                position: absolute;
                                top: 0;
                                left: -15px;
                                right: 10;
                                bottom: 0;
                                content: "";
                                background-color: $white;
                                height: 100%;
                                width: 2px;
                            }
                        }
                    }

                    .videoBox {

                        background-color: $white_text_color;
                        padding: 1rem;
                        border-radius: 10px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 1.2rem;

                        .approach_container {
                            width: 100%;

                            h3 {
                                color: $cta_color;
                                margin-bottom: 5px;
                                font-weight: 600;
                                font-size: 12px;
                            }

                            .image_container {
                                position: relative;
                                border-radius: 10px;

                                .slide_panel {
                                    width: 100%;
                                    display: flex;
                                    gap: 10px;
                                    overflow-x: scroll;
                                    scroll-snap-type: x mandatory;
                                    scroll-behavior: smooth;
                                    border-radius: 5px;

                                    .slider_image {
                                        width: 100%;
                                        height: 100%;
                                        scroll-snap-align: start;
                                    }
                                }

                                ::-webkit-scrollbar {
                                    display: none;
                                }

                                span {
                                    position: absolute;
                                    bottom: 10px;
                                    right: 10px;
                                    background-color: #fff;
                                    padding: 5px 10px;
                                    font-size: 12px;
                                    color: $cta_color;
                                    font-weight: 500;
                                    border-radius: 5px;
                                }

                                &:hover {
                                    border: 3px solid black;
                                }
                            }

                            .image_container.selected {
                                border: 3px solid black;
                            }


                        }

                    }

                    .displayNone {
                        display: none !important;
                    }
                }

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: #fff;
                    border-radius: 10px;
                }

                .shadow::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .ReportSideBar {
        background-color: $primary_color;
        padding-top: 2rem;
        padding-right: 5px;

        .report_side_nav {
            .head {
                h3 {
                    border-bottom: 1px solid $white;
                    color: $white;
                    padding: 0.5rem;
                    font-size: 17px;
                    margin: 0 1.5rem;
                }
            }

            .loading_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: #fff;
            }

            .video_cont {
                overflow-y: scroll;
                max-height: 80vh;
                padding: 0 1rem;
                margin-top: 2rem;
                width: 100%;
                padding-bottom: 50px;

                .displayNone {
                    display: none !important;
                }

                .uploaded_video {
                    cursor: pointer;

                    .uploadVideoHeader {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h6 {
                            margin-left: 1rem;
                            position: relative;
                            font-size: 12px;
                            font-weight: 500;
                            color: $white;

                            &::after {
                                position: absolute;
                                top: 0;
                                left: -15px;
                                right: 10;
                                bottom: 0;
                                content: "";
                                background-color: $white;
                                height: 100%;
                                width: 2px;
                            }
                        }



                    }



                    .uploadeVideoThumbnailImg {
                        width: 100%;
                        border-radius: 10px;

                        &:hover {
                            border: 3px solid white;
                        }
                    }

                    .active_uploaded_video {
                        border: 3px solid white;

                    }
                }



                .option {
                    margin-top: 2rem;

                    .techniqueHeader {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h6 {
                            margin-left: 1rem;
                            position: relative;
                            font-size: 12px;
                            font-weight: 500;
                            color: $white;
                            text-transform: capitalize;

                            &::after {
                                position: absolute;
                                top: 0;
                                left: -15px;
                                right: 10;
                                bottom: 0;
                                content: "";
                                background-color: $white;
                                height: 100%;
                                width: 2px;
                            }
                        }
                    }

                    .videoBox {

                        background-color: $white_text_color;
                        padding: 0.8rem;
                        border-radius: 10px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 1.2rem;

                        .approach_container {
                            width: 100%;

                            h3 {
                                color: $cta_color;
                                margin-bottom: 5px;
                                font-weight: 600;
                                font-size: 11px;
                            }

                            .image_container {
                                position: relative;
                                border-radius: 10px;

                                .slide_panel {
                                    width: 100%;
                                    display: flex;
                                    gap: 10px;
                                    overflow-x: scroll;
                                    scroll-snap-type: x mandatory;
                                    scroll-behavior: smooth;
                                    border-radius: 5px;

                                    .slider_image {
                                        width: 100%;
                                        height: 100%;
                                        scroll-snap-align: start;
                                    }
                                }

                                ::-webkit-scrollbar {
                                    display: none;
                                }

                                span {
                                    position: absolute;
                                    bottom: 10px;
                                    right: 10px;
                                    background-color: #fff;
                                    padding: 2px 8px;
                                    font-size: 11px;
                                    color: $cta_color;
                                    font-weight: 500;
                                    border-radius: 5px;
                                }

                                &:hover {
                                    border: 3px solid black;
                                }
                            }

                            .image_container.selected {
                                border: 3px solid black;
                            }


                        }

                    }

                    .displayNone {
                        display: none !important;
                    }
                }

                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: #fff;
                    border-radius: 10px;
                }

                .shadow::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .ReportSideBar {
        background-color: $primary_color;
        padding-top: 1.5rem;
        padding-right: 2px;

        .report_side_nav {
            .head {
                h3 {
                    border-bottom: 1px solid $white;
                    color: $white;
                    padding: 0.5rem;
                    font-size: 12px;
                    margin: 0 1rem;
                }
            }

            .loading_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: #fff;
            }

            .video_cont {
                overflow-y: scroll;
                max-height: 100vh;
                padding: 0 0.4rem;
                margin-top: 1rem;
                width: 100%;
                padding-bottom: 50px;

                .displayNone {
                    display: none !important;
                }

                .uploaded_video {
                    cursor: pointer;

                    .uploadVideoHeader {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h6 {
                            margin-left: 1rem;
                            position: relative;
                            font-size: 10px;
                            font-weight: 500;
                            color: $white;

                            &::after {
                                position: absolute;
                                top: 0;
                                left: -15px;
                                right: 10;
                                bottom: 0;
                                content: "";
                                background-color: $white;
                                height: 100%;
                                width: 2px;
                            }
                        }



                    }



                    .uploadeVideoThumbnailImg {
                        width: 100%;
                        border-radius: 10px;

                        &:hover {
                            border: 2px solid white;
                        }
                    }

                    .active_uploaded_video {
                        border: 2px solid white;

                    }
                }



                .option {
                    margin-top: 2rem;

                    .techniqueHeader {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h6 {
                            margin-left: 1rem;
                            position: relative;
                            font-size: 10px;
                            font-weight: 500;
                            color: $white;
                            text-transform: capitalize;

                            &::after {
                                position: absolute;
                                top: 0;
                                left: -15px;
                                right: 10;
                                bottom: 0;
                                content: "";
                                background-color: $white;
                                height: 100%;
                                width: 2px;
                            }
                        }
                    }

                    .videoBox {

                        background-color: $white_text_color;
                        padding: 0.3rem;
                        border-radius: 10px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        .approach_container {
                            width: 100%;

                            h3 {
                                color: $cta_color;
                                margin-bottom: 5px;
                                font-weight: 600;
                                font-size: 8px;
                            }

                            .image_container {
                                position: relative;
                                border-radius: 10px;

                                .slide_panel {
                                    width: 100%;
                                    display: flex;
                                    gap: 10px;
                                    overflow-x: scroll;
                                    scroll-snap-type: x mandatory;
                                    scroll-behavior: smooth;
                                    border-radius: 5px;

                                    .slider_image {
                                        width: 100%;
                                        height: 100%;
                                        scroll-snap-align: start;
                                    }
                                }

                                ::-webkit-scrollbar {
                                    display: none;
                                }

                                span {
                                    position: absolute;
                                    bottom: 5px;
                                    right: 5px;
                                    background-color: #fff;
                                    padding: 2px 8px;
                                    font-size: 8px;
                                    color: $cta_color;
                                    font-weight: 500;
                                    border-radius: 5px;
                                }

                                &:hover {
                                    border: 2px solid black;
                                }
                            }

                            .image_container.selected {
                                border: 2px solid black;
                            }


                        }

                    }

                    .displayNone {
                        display: none !important;
                    }
                }

                &::-webkit-scrollbar {
                    width: 3px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: #fff;
                    border-radius: 10px;
                }

                .shadow::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }
    }
}