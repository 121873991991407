@import '../../SCSS/variables';

.blogDetails {
    background-color: $secondary_color;
    padding-top: 2rem;

    .fluid-container {
        max-width: 95%;
        margin: auto;
        display: flex;

        .left_container {
            max-width: 65%;

            .heading {
                margin-bottom: 1.5rem;

                h2 {
                    font-weight: 800;
                    font-size: 36px;
                    color: #000000;
                    text-transform: uppercase;
                }
            }

            .date_box {
                display: flex;
                align-items: center;
                margin-bottom: 3rem;

                span {
                    width: 5px;
                    box-sizing: border-box;
                    background-color: $cta_color;
                    height: 1.5rem;
                    width: 5px;
                    margin-right: 5px;
                }

                p {
                    margin: 0;
                    padding-inline: 10px;
                    font-size: 18px;
                }

                .horizontal-line {
                    flex-grow: 1;
                    border-top: 2px solid $cta_color;
                }


            }

            .blog_information {
                max-width: 90%;



                .analysis_points_cont {
                    margin-bottom: 2.5rem;
                    max-width: 90%;


                    .analysis_box {
                        margin-bottom: 1rem;

                        h4 {
                            font-weight: 800;
                            color: $cta_color;
                            margin-top: 2rem;
                            text-transform: uppercase;
                            font-size: 32px;
                        }

                        h5 {
                            font-weight: 500;
                            font-size: 20px;
                            text-transform: uppercase;
                            margin-top: 1rem;
                            margin-left: 3px;
                        }

                        .analysis_subheading {
                            p {
                                color: black;
                                font-weight: 500;
                                text-align: justify;
                                font-size: 20px;
                            }
                        }

                        img {
                            max-width: 100%;
                            border-radius: 5px;
                        }

                        .multiple_images {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 10px;

                            img {
                                height: 100%;
                            }
                        }

                        .links {

                            font-size: 20px;
                        }

                        .list {
                            color: black;
                            font-weight: 500;
                            text-align: justify;
                            font-size: 20px;

                            li {
                                margin-block: 10px;
                            }

                        }

                    }
                }
            }

            .button_Container {
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    color: $white_text_color;
                    background-color: $cta_color;
                    padding: 0.7rem 5rem;
                    border: none;
                    font-size: 20px;
                    font-weight: 500;
                    border: 1px solid $cta_color;
                    text-transform: uppercase;

                    &:hover {
                        color: $cta_color;
                        background-color: $white_text_color;

                    }
                }
            }
        }

        .right_container {
            border-radius: 10px;
            border: 2px solid $cta_color;
            height: fit-content;
            display: flex;
            flex-direction: column;
            background-color: $cta_color;
            position: sticky;
            top: 100px;
            right: 20px;

            h4 {
                color: white;
                background-color: $cta_color;
                font-weight: 800;
                padding: 10px 28px;
                margin-bottom: 0;
                font-size: 32px;
            }

            .recent_blog_containers {
                flex-grow: 1;
                background-color: #FFFFFF;
                padding: 10px 28px;
                display: flex;
                flex-direction: column;

                .recent_blog_box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    padding: 1rem;


                    .left_recent_blog {
                        padding-right: 0.1rem;

                        h5 {
                            color: black;
                            font-weight: 700;
                            font-size: 22px;
                            text-transform: uppercase;
                        }

                        p {
                            color: black;
                            font-weight: 500;
                            margin: 0px;
                            font-size: 14px;
                        }
                    }

                    .right_recent_blog {
                        img {
                            width: 100px;
                            max-height: 100px;
                            border-radius: 10px;
                        }
                    }
                }

                .horizontal-line {
                    flex-grow: 1;
                    border-top: 2px solid $cta_color;
                }
            }

            button {
                color: $white_text_color;
                background-color: $cta_color;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 0px;
                margin: 0px;
                padding: 0.5rem;
                border: none;
                font-size: 24px;
                font-weight: 500;

                &:hover {
                    color: $cta_color;
                    background-color: $white_text_color;
                    border-top: 2px solid $cta_color;
                }
            }

        }
    }
}

@media screen and (max-width: 1399px) {

    .blogDetails {
        background-color: $secondary_color;
        padding-top: 2rem;

        .fluid-container {
            max-width: 95%;
            margin: auto;
            display: flex;

            .left_container {
                max-width: 65%;

                .heading {
                    margin-bottom: 1.5rem;

                    h2 {
                        font-weight: 800;
                        font-size: 32px;
                        color: #000000;
                        text-transform: uppercase;
                    }
                }

                .date_box {
                    display: flex;
                    align-items: center;
                    margin-bottom: 3rem;

                    span {
                        width: 5px;
                        box-sizing: border-box;
                        background-color: $cta_color;
                        height: 1.5rem;
                        width: 5px;
                        margin-right: 5px;
                    }

                    p {
                        margin: 0;
                        padding-inline: 10px;
                        font-size: 16px;
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 2px solid $cta_color;
                    }


                }

                .blog_information {
                    max-width: 90%;



                    .analysis_points_cont {
                        margin-bottom: 2.5rem;
                        max-width: 90%;

                        .analysis_box {
                            margin-bottom: 1rem;

                            h4 {
                                font-weight: 800;
                                color: $cta_color;
                                margin-top: 2rem;
                                text-transform: uppercase;
                                font-size: 26px;
                            }

                            h5 {
                                font-size: 17px;
                            }

                            .analysis_subheading {
                                p {
                                    color: black;
                                    font-weight: 500;
                                    text-align: justify;
                                    font-size: 15px;
                                }
                            }

                            img {
                                max-width: 100%;
                                border-radius: 5px;
                            }

                            .multiple_images {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 10px;

                                img {
                                    height: 100%;
                                }
                            }

                            .links {

                                font-size: 15px;
                            }

                            .list {
                                color: black;
                                font-weight: 500;
                                text-align: justify;
                                font-size: 15px;
                            }

                        }
                    }
                }

                .button_Container {
                    button {
                        padding: 0.5rem 4rem;
                        font-size: 20px;
                    }
                }
            }

            .right_container {
                border-radius: 10px;
                border: 2px solid $cta_color;
                height: fit-content;
                display: flex;
                flex-direction: column;
                background-color: $cta_color;
                position: sticky;
                top: 100px;
                right: 20px;

                h4 {
                    color: white;
                    background-color: $cta_color;
                    font-weight: 800;
                    padding: 10px 28px;
                    margin-bottom: 0;
                    font-size: 22px;
                }

                .recent_blog_containers {
                    flex-grow: 1;
                    background-color: #FFFFFF;
                    padding: 10px 20px;
                    display: flex;
                    flex-direction: column;

                    .recent_blog_box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        padding: 1rem;


                        .left_recent_blog {
                            padding-right: 0.5rem;

                            h5 {
                                color: black;
                                font-weight: 700;
                                font-size: 18px;
                            }

                            p {
                                color: black;
                                font-weight: 500;
                                margin: 0px;
                                font-size: 13px;
                            }
                        }

                        .right_recent_blog {
                            img {
                                width: 100px;
                                border-radius: 10px;
                            }
                        }
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 2px solid $cta_color;
                    }
                }

                button {
                    color: $white_text_color;
                    background-color: $cta_color;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 0px;
                    margin: 0px;
                    padding: 0.5rem;
                    border: none;
                    font-size: 18px;
                    font-weight: 500;

                    &:hover {
                        color: $cta_color;
                        background-color: $white_text_color;
                        border-top: 2px solid $cta_color;
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .blogDetails {
        background-color: $secondary_color;
        padding-top: 2rem;

        .fluid-container {
            max-width: 95%;
            margin: auto;
            display: flex;

            .left_container {
                max-width: 65%;

                .heading {
                    margin-bottom: 1.5rem;

                    h2 {
                        font-weight: 800;
                        font-size: 26px;
                        color: #000000;
                        text-transform: uppercase;
                    }
                }

                .date_box {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2rem;

                    span {
                        width: 5px;
                        box-sizing: border-box;
                        background-color: $cta_color;
                        height: 1.5rem;
                        width: 5px;
                        margin-right: 5px;
                    }

                    p {
                        margin: 0;
                        padding-inline: 10px;
                        font-size: 14px;
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 2px solid $cta_color;
                    }


                }

                .blog_information {
                    max-width: 90%;



                    .analysis_points_cont {
                        margin-bottom: 2.5rem;
                        max-width: 90%;

                        .analysis_box {
                            margin-bottom: 1rem;

                            h4 {
                                font-weight: 800;
                                color: $cta_color;
                                margin-top: 2rem;
                                text-transform: uppercase;
                                font-size: 22px;
                            }

                            h5 {
                                font-size: 16px;
                            }

                            .analysis_subheading {
                                p {
                                    color: black;
                                    font-weight: 500;
                                    text-align: justify;
                                    font-size: 14px;
                                }
                            }

                            img {
                                max-width: 100%;
                                border-radius: 5px;
                            }

                            .multiple_images {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 10px;

                                img {
                                    height: 100%;
                                }
                            }

                            .links {

                                font-size: 14px;
                            }

                            .list {
                                color: black;
                                font-weight: 500;
                                text-align: justify;
                                font-size: 14px;
                            }

                        }
                    }
                }

                .button_Container {
                    button {
                        padding: 0.5rem 4rem;
                        font-size: 18px;
                    }
                }
            }

            .right_container {
                border-radius: 10px;
                border: 2px solid $cta_color;
                height: fit-content;
                display: flex;
                flex-direction: column;
                background-color: $cta_color;
                position: sticky;
                top: 100px;
                right: 20px;

                h4 {
                    color: white;
                    background-color: $cta_color;
                    font-weight: 800;
                    padding: 10px 28px;
                    margin-bottom: 0;
                    font-size: 22px;
                }

                .recent_blog_containers {
                    flex-grow: 1;
                    background-color: #FFFFFF;
                    padding: 10px 18px;
                    display: flex;
                    flex-direction: column;

                    .recent_blog_box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        padding: 0.8rem;


                        .left_recent_blog {

                            h5 {
                                color: black;
                                font-weight: 700;
                                font-size: 14px;
                            }

                            p {
                                color: black;
                                font-weight: 500;
                                margin: 0px;
                                font-size: 12px;
                            }
                        }

                        .right_recent_blog {
                            img {
                                width: 100px;
                                border-radius: 10px;
                            }
                        }
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 2px solid $cta_color;
                    }
                }

                button {
                    color: $white_text_color;
                    background-color: $cta_color;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 0px;
                    margin: 0px;
                    padding: 0.5rem;
                    border: none;
                    font-size: 14px;
                    font-weight: 500;

                    &:hover {
                        color: $cta_color;
                        background-color: $white_text_color;
                        border-top: 2px solid $cta_color;
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 991px) {
    .blogDetails {
        background-color: $secondary_color;
        padding-top: 1rem;

        .fluid-container {
            max-width: 95%;
            margin: auto;
            display: flex;

            .left_container {
                max-width: 65%;

                .heading {
                    margin-bottom: 1.5rem;

                    h2 {
                        font-weight: 800;
                        font-size: 24px;
                        color: #000000;
                        text-transform: uppercase;
                    }
                }

                .date_box {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2rem;

                    span {
                        width: 5px;
                        box-sizing: border-box;
                        background-color: $cta_color;
                        height: 1.5rem;
                        width: 5px;
                        margin-right: 5px;
                    }

                    p {
                        margin: 0;
                        padding-inline: 10px;
                        font-size: 13px;
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 2px solid $cta_color;
                    }


                }

                .blog_information {
                    max-width: 90%;



                    .analysis_points_cont {
                        margin-bottom: 2.5rem;
                        max-width: 90%;

                        .analysis_box {
                            margin-bottom: 1rem;

                            h4 {
                                font-weight: 800;
                                color: $cta_color;
                                margin-top: 2rem;
                                text-transform: uppercase;
                                font-size: 18px;
                            }

                            h5 {
                                font-size: 14px;
                            }

                            .analysis_subheading {
                                p {
                                    color: black;
                                    font-weight: 500;
                                    text-align: justify;
                                    font-size: 13px;
                                }
                            }

                            img {
                                max-width: 100%;
                                border-radius: 5px;
                            }

                            .multiple_images {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 10px;

                                img {
                                    height: 100%;
                                }
                            }

                            .links {

                                font-size: 13px;
                            }

                            .list {
                                color: black;
                                font-weight: 500;
                                text-align: justify;
                                font-size: 13px;
                            }

                        }
                    }
                }

                .button_Container {
                    button {
                        padding: 0.5rem 3.5rem;
                        font-size: 16px;
                    }
                }
            }

            .right_container {
                border-radius: 10px;
                border: 2px solid $cta_color;
                height: fit-content;
                display: flex;
                flex-direction: column;
                background-color: $cta_color;
                position: sticky;
                top: 80px;
                right: 20px;

                h4 {
                    color: white;
                    background-color: $cta_color;
                    font-weight: 800;
                    padding: 8px 20px;
                    margin-bottom: 0;
                    font-size: 16px;
                }

                .recent_blog_containers {
                    flex-grow: 1;
                    background-color: #FFFFFF;
                    padding: 8px 12px;
                    display: flex;
                    flex-direction: column;

                    .recent_blog_box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        padding: 0.8rem;


                        .left_recent_blog {

                            h5 {
                                color: black;
                                font-weight: 700;
                                font-size: 13px;
                            }

                            p {
                                color: black;
                                font-weight: 500;
                                margin: 0px;
                                font-size: 11px;
                            }
                        }

                        .right_recent_blog {
                            img {
                                width: 100px;
                                border-radius: 10px;
                            }
                        }
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 2px solid $cta_color;
                    }
                }

                button {
                    color: $white_text_color;
                    background-color: $cta_color;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 0px;
                    margin: 0px;
                    padding: 0.5rem;
                    border: none;
                    font-size: 14px;
                    font-weight: 500;

                    &:hover {
                        color: $cta_color;
                        background-color: $white_text_color;
                        border-top: 2px solid $cta_color;
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 767px) {
    .blogDetails {
        background-color: $secondary_color;
        padding-top: 3.5rem;

        .fluid-container {
            max-width: 95%;
            margin: auto;
            display: flex;

            .left_container {
                max-width: 65%;

                .heading {
                    margin-bottom: 1.5rem;

                    h2 {
                        font-weight: 800;
                        font-size: 20px;
                        color: #000000;
                        text-transform: uppercase;
                    }
                }

                .date_box {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2rem;

                    span {
                        width: 5px;
                        box-sizing: border-box;
                        background-color: $cta_color;
                        height: 1.5rem;
                        width: 5px;
                        margin-right: 5px;
                    }

                    p {
                        margin: 0;
                        padding-inline: 10px;
                        font-size: 11px;
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 2px solid $cta_color;
                    }


                }

                .blog_information {
                    max-width: 90%;



                    .analysis_points_cont {
                        margin-bottom: 2.5rem;
                        max-width: 90%;

                        .analysis_box {
                            margin-bottom: 1rem;

                            h4 {
                                font-weight: 800;
                                color: $cta_color;
                                margin-top: 2rem;
                                text-transform: uppercase;
                                font-size: 16px;
                            }

                            h5 {
                                font-size: 12px;
                                margin-top: 0.5rem;
                            }

                            .analysis_subheading {
                                p {
                                    color: black;
                                    font-weight: 500;
                                    text-align: justify;
                                    font-size: 11px;
                                }
                            }

                            img {
                                max-width: 100%;
                                border-radius: 5px;
                            }

                            .multiple_images {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 10px;

                                img {
                                    height: 100%;
                                }
                            }

                            .links {

                                font-size: 13px;
                            }

                            .list {
                                color: black;
                                font-weight: 500;
                                text-align: justify;
                                font-size: 11px;
                            }

                        }
                    }
                }

                .button_Container {
                    button {
                        padding: 0.5rem 3.5rem;
                        font-size: 15px;
                    }
                }
            }

            .right_container {
                border-radius: 10px;
                border: 2px solid $cta_color;
                height: fit-content;
                display: flex;
                flex-direction: column;
                background-color: $cta_color;
                position: sticky;
                top: 130px;
                right: 20px;

                h4 {
                    color: white;
                    background-color: $cta_color;
                    font-weight: 800;
                    padding: 8px 20px;
                    margin-bottom: 0;
                    font-size: 14px;
                }

                .recent_blog_containers {
                    flex-grow: 1;
                    background-color: #FFFFFF;
                    padding: 8px 5px;
                    display: flex;
                    flex-direction: column;

                    .recent_blog_box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        padding: 0.5rem;


                        .left_recent_blog {

                            h5 {
                                color: black;
                                font-weight: 700;
                                font-size: 12px;
                            }

                            p {
                                color: black;
                                font-weight: 500;
                                margin: 0px;
                                font-size: 10px;
                            }
                        }

                        .right_recent_blog {
                            img {
                                width: 100px;
                                border-radius: 10px;
                            }
                        }
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 1.5px solid $cta_color;
                    }
                }

                button {
                    color: $white_text_color;
                    background-color: $cta_color;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 0px;
                    margin: 0px;
                    padding: 0.5rem;
                    border: none;
                    font-size: 14px;
                    font-weight: 500;

                    &:hover {
                        color: $cta_color;
                        background-color: $white_text_color;
                        border-top: 2px solid $cta_color;
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 670px) {
    .blogDetails {
        background-color: $secondary_color;
        padding-top: 3.5rem;

        .fluid-container {
            max-width: 95%;
            margin: auto;
            display: flex;

            .left_container {
                max-width: 65%;

                .heading {
                    margin-bottom: 1.5rem;

                    h2 {
                        font-weight: 800;
                        font-size: 20px;
                        color: #000000;
                        text-transform: uppercase;
                    }
                }

                .date_box {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2rem;

                    span {
                        width: 5px;
                        box-sizing: border-box;
                        background-color: $cta_color;
                        height: 1.5rem;
                        width: 5px;
                        margin-right: 5px;
                    }

                    p {
                        margin: 0;
                        padding-inline: 10px;
                        font-size: 11px;
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 2px solid $cta_color;
                    }


                }

                .blog_information {
                    max-width: 90%;



                    .analysis_points_cont {
                        margin-bottom: 2.5rem;
                        max-width: 90%;

                        .analysis_box {
                            margin-bottom: 1rem;

                            h4 {
                                font-weight: 800;
                                color: $cta_color;
                                margin-top: 2rem;
                                text-transform: uppercase;
                                font-size: 16px;
                            }

                            h5 {
                                font-size: 12px;
                                margin-top: 0.5rem;
                            }

                            .analysis_subheading {
                                p {
                                    color: black;
                                    font-weight: 500;
                                    text-align: justify;
                                    font-size: 11px;
                                }
                            }

                            img {
                                max-width: 100%;
                                border-radius: 5px;
                            }

                            .multiple_images {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 10px;

                                img {
                                    height: 100%;
                                }
                            }

                            .links {

                                font-size: 13px;
                                padding-right: 1rem;
                            }

                            .list {
                                color: black;
                                font-weight: 500;
                                text-align: justify;
                                font-size: 11px;
                            }

                        }
                    }
                }

                .button_Container {
                    button {
                        padding: 0.5rem 3.5rem;
                        font-size: 14px;
                    }
                }
            }

            .right_container {
                border-radius: 10px;
                border: 2px solid $cta_color;
                height: fit-content;
                display: flex;
                flex-direction: column;
                background-color: $cta_color;
                position: sticky;
                top: 130px;
                right: 20px;

                h4 {
                    color: white;
                    background-color: $cta_color;
                    font-weight: 800;
                    padding: 8px 20px;
                    margin-bottom: 0;
                    font-size: 14px;
                }

                .recent_blog_containers {
                    flex-grow: 1;
                    background-color: #FFFFFF;
                    padding: 5px 5px;
                    display: flex;
                    flex-direction: column;

                    .recent_blog_box {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        cursor: pointer;
                        padding: 0.3rem;


                        .left_recent_blog {

                            h5 {
                                color: black;
                                font-weight: 700;
                                font-size: 12px;
                            }

                            p {
                                color: black;
                                font-weight: 500;
                                margin: 0px;
                                font-size: 10px;
                                text-align: justify;
                            }
                        }

                        .right_recent_blog {
                            align-self: center;

                            img {
                                width: 100px;
                                border-radius: 10px;
                            }
                        }
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 1.5px solid $cta_color;
                    }
                }

                button {
                    color: $white_text_color;
                    background-color: $cta_color;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 0px;
                    margin: 0px;
                    padding: 0.3rem;
                    border: none;
                    font-size: 12px;
                    font-weight: 500;

                    &:hover {
                        color: $cta_color;
                        background-color: $white_text_color;
                        border-top: 2px solid $cta_color;
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 575px) {
    .blogDetails {
        background-color: $secondary_color;
        padding-top: 3rem;

        .fluid-container {
            max-width: 98%;
            margin: auto;
            display: flex;

            .left_container {
                max-width: 65%;

                .heading {
                    margin-bottom: 1.5rem;

                    h2 {
                        font-weight: 800;
                        font-size: 16px;
                        color: #000000;
                        text-transform: uppercase;
                        margin-bottom: 5px;
                        padding-bottom: 4px;
                    }
                }

                .date_box {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.5rem;

                    span {
                        width: 5px;
                        box-sizing: border-box;
                        background-color: $cta_color;
                        height: 1.1rem;
                        width: 3px;
                        margin-right: 5px;
                    }

                    p {
                        margin: 0;
                        padding-inline: 5px;
                        font-size: 10px;
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 2px solid $cta_color;
                    }


                }

                .blog_information {
                    max-width: 100%;

                    .analysis_points_cont {
                        margin-bottom: 2.5rem;
                        max-width: 90%;

                        .analysis_box {
                            margin-bottom: 1rem;

                            h4 {
                                font-weight: 800;
                                color: $cta_color;
                                margin-top: 1rem;
                                text-transform: uppercase;
                                font-size: 14px;
                            }

                            h5 {
                                font-size: 11px;
                                margin-top: 0.5rem;
                            }

                            .analysis_subheading {
                                p {
                                    color: black;
                                    font-weight: 500;
                                    text-align: justify;
                                    font-size: 10px;
                                }
                            }

                            img {
                                max-width: 100%;
                                border-radius: 5px;
                            }

                            .multiple_images {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 10px;

                                img {
                                    height: 100%;
                                }
                            }

                            .links {
                                max-width: 100%;
                                font-size: 13px;
                                overflow: hidden;


                            }

                            .list {
                                color: black;
                                font-weight: 500;
                                text-align: justify;
                                font-size: 10px;
                                padding-left: 12px;
                            }

                        }
                    }
                }

                .button_Container {
                    button {
                        padding: 0.5rem 3rem;
                        font-size: 12px;
                    }
                }
            }

            .right_container {
                border-radius: 10px;
                border: 2px solid $cta_color;
                height: fit-content;
                display: flex;
                flex-direction: column;
                background-color: $cta_color;
                position: sticky;
                top: 110px;
                right: 10px;

                h4 {
                    color: white;
                    background-color: $cta_color;
                    font-weight: 800;
                    padding: 5px 5px;
                    margin-bottom: 0;
                    font-size: 14px;
                }

                .recent_blog_containers {
                    flex-grow: 1;
                    background-color: #FFFFFF;
                    padding: 5px 5px;
                    display: flex;
                    flex-direction: column;

                    .recent_blog_box {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        cursor: pointer;
                        padding: 0.2rem;
                        margin-bottom: 0.5rem;


                        .left_recent_blog {


                            h5 {
                                color: black;
                                font-weight: 700;
                                font-size: 10px;
                                margin: 0px;
                                padding: 0px;
                            }

                            p {
                                color: black;
                                font-weight: 500;
                                margin: 0px;
                                font-size: 9px;
                                padding-block: 5px;
                            }
                        }

                        .right_recent_blog {
                            align-self: center;

                            img {
                                width: 120px;
                                max-height: 100px;
                                border-radius: 10px;
                            }
                        }
                    }

                    .horizontal-line {
                        flex-grow: 1;
                        border-top: 1.5px solid $cta_color;
                    }
                }

                button {
                    color: $white_text_color;
                    background-color: $cta_color;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 0px;
                    margin: 0px;
                    padding: 0.3rem;
                    border: none;
                    font-size: 11px;
                    font-weight: 500;

                    &:hover {
                        color: $cta_color;
                        background-color: $white_text_color;
                        border-top: 2px solid $cta_color;
                    }
                }

            }
        }
    }
}