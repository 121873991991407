@import "../../assets/variables.scss";
@import "../../SCSS/variables";


.greetings {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-block: 5rem;

    p {
        padding: 2rem;
        color: black;
        font-size: 32px;
        font-size: 500;
        text-align: justify;

        span {
            a {
                color: $cta_color;
                font-weight: 600;
                text-decoration: underline;
            }
        }
    }

    button {
        text-align: center;
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 500;
        background-color: $cta_color;
        border: none;
        color: $white;
        padding: 10px 40px;

        &:hover {
            background-color: $white;
            color: $cta_color;
            border: 1px solid $cta_color;
        }

        .icon {
            margin-left: 10px;
        }
    }
}

@media screen and (max-width: 1399px) {
    .greetings {
        margin-block: 4.5rem;

        p {
            padding: 1rem;
            font-size: 24px;
            margin-bottom: 2rem;
        }

        button {
            font-size: 20px;
        }
    }
}


@media screen and (max-width: 1199px) {
    .greetings {
        margin-block: 4rem;

        p {
            padding: 0.8rem;
            font-size: 28px;
        }

        button {
            font-size: 20px;
        }
    }
}


@media screen and (max-width: 991px) {
    .greetings {
        margin-block: 3.5rem;

        p {
            padding: 0.8rem;
            font-size: 24px;
        }

        button {
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 767px) {
    .greetings {
        margin-block: 3.5rem;

        p {
            padding: 0.8rem;
            font-size: 20px;
        }

        button {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 575px) {

    .greetings {
        margin-block: 3rem;

        p {
            padding: 0.8rem;
            font-size: 16px;
        }

        button {
            font-size: 14px;
        }
    }
}