@import "../../../SCSS/variables";

.reportContent {
    max-width: 95%;

    .content {
        padding: 2rem 4rem;
        padding-bottom: 0px;

        .cont_head {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .rp {
                p {
                    margin: 0;
                    position: relative;
                    margin-left: 15px;
                    text-transform: capitalize;
                    font-size: 20px;
                    font-weight: 600;

                    &::after {
                        position: absolute;
                        top: 0;
                        left: -10px;
                        right: 10;
                        bottom: 0;
                        content: "";
                        background-color: $cta_color;
                        height: 100%;
                        width: 4px;
                    }
                }

                h1 {
                    font-size: 72px;
                    font-weight: 800;
                    text-transform: uppercase;
                    color: $cta_color;
                }
            }

            .mar {
                text-align: end;

                h3 {
                    background-color: $cta_color;
                    display: inline;
                    padding: 0.5rem 2rem;
                    font-size: 20px;
                    border-radius: 50px;
                    color: $white;
                    font-weight: 500;

                    i {
                        margin-right: 15px;
                        cursor: pointer;
                    }
                }

                h4 {
                    margin: 0;
                    margin-top: 1rem;
                    font-size: 20px;

                    span {
                        color: $cta_color;
                        font-weight: bold;

                    }
                }
            }
        }

        .video_cont {
            margin-top: 10px;
            text-align: center;

            h5 {
                font-size: 26px;
                margin-bottom: 15px;
                text-align: left;


            }

            .imageContainer {
                height: 40vh;
                width: auto;
                background-size: cover;
                background-position: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 10px;

                >div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    i {
                        font-size: 3.5rem;
                        color: white;
                    }

                    span {
                        font-size: 1.5rem;
                        color: white;
                    }
                }

            }

            .videoContainer {
                height: 40vh;
                width: auto;

                video {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    // object-fit: fill;
                }
            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1.5rem;
                margin-bottom: 0rem;

                button {
                    margin-top: 1rem;
                    padding: 0.5rem;
                    width: 323px;
                    border: 2px solid $cta_color;
                    background-color: transparent;
                    font-size: 22px;
                    color: $cta_color;
                    font-weight: 500;

                    &:hover {
                        background-color: $cta_color;
                        color: $white;
                    }

                    i {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1399px) {
    .reportContent {
        .content {
            padding: 2rem 4rem;
            padding-bottom: 0px;

            .cont_head {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .rp {
                    p {
                        margin: 0;
                        position: relative;
                        margin-left: 15px;
                        text-transform: capitalize;
                        font-size: 18px;
                        font-weight: 600;

                        &::after {
                            position: absolute;
                            top: 0;
                            left: -10px;
                            right: 10;
                            bottom: 0;
                            content: "";
                            height: 100%;
                            width: 4px;
                        }
                    }

                    h1 {
                        font-size: 60px;
                    }
                }

                .mar {
                    text-align: end;

                    h3 {
                        display: inline;
                        padding: 0.5rem 2rem;
                        font-size: 18px;
                        border-radius: 50px;
                        color: $white;
                        font-weight: 500;

                        i {
                            margin-right: 15px;
                            cursor: pointer;
                        }
                    }

                    h4 {
                        margin: 0;
                        margin-top: 1rem;
                        font-size: 18px;

                        span {
                            font-weight: bold;

                        }
                    }
                }
            }

            .video_cont {
                margin-top: 10px;
                text-align: center;

                h5 {
                    font-size: 22px;
                    margin-bottom: 15px;
                    text-align: left;


                }

                .imageContainer {
                    max-width: 90%;
                    margin: auto;
                    height: 47vh;

                    background-size: cover;
                    background-position: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    >div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            font-size: 3.5rem;
                            color: white;
                        }

                        span {
                            font-size: 1.5rem;
                            color: white;
                        }
                    }

                }

                .videoContainer {
                    height: 47vh;
                    max-width: 90%;
                    margin: auto;

                    video {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                    }
                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1.5rem;
                    margin-bottom: 0rem;

                    button {
                        padding: 0.5rem;
                        width: 300px;
                        background-color: transparent;
                        font-size: 20px;
                        font-weight: 500;

                        i {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1199px) {
    .reportContent {
        .content {
            padding: 2rem 4rem;
            padding-bottom: 0px;

            .cont_head {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .rp {
                    p {
                        margin: 0;
                        position: relative;
                        margin-left: 15px;
                        text-transform: capitalize;
                        font-size: 16px;
                        font-weight: 600;

                        &::after {
                            position: absolute;
                            top: 0;
                            left: -10px;
                            right: 10;
                            bottom: 0;
                            content: "";
                            height: 100%;
                            width: 4px;
                        }
                    }

                    h1 {
                        font-size: 48px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }
                }

                .mar {
                    text-align: end;

                    h3 {
                        display: inline;
                        padding: 0.5rem 2rem;
                        font-size: 16px;
                        border-radius: 50px;
                        color: $white;
                        font-weight: 500;

                        i {
                            margin-right: 15px;
                            cursor: pointer;
                        }
                    }

                    h4 {
                        margin: 0;
                        margin-top: 1rem;
                        font-size: 16px;

                        span {
                            font-weight: bold;

                        }
                    }
                }
            }

            .video_cont {
                margin-top: 10px;
                text-align: center;

                h5 {
                    font-size: 20px;
                    margin-bottom: 15px;
                    text-align: left;


                }

                .imageContainer {
                    max-width: 90%;
                    margin: auto;
                    height: 47vh;

                    background-size: cover;
                    background-position: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    >div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            font-size: 3rem;
                            color: white;
                        }

                        span {
                            font-size: 1.2rem;
                            color: white;
                        }
                    }

                }

                .videoContainer {
                    height: 47vh;
                    max-width: 90%;
                    margin: auto;

                    video {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                    }
                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1.5rem;
                    margin-bottom: 0rem;

                    button {
                        padding: 0.4rem;
                        width: 260px;
                        background-color: transparent;
                        font-size: 20px;
                        font-weight: 500;


                        i {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) and (min-height:750px) {
    .reportContent {
        .content {
            padding: 2rem 4rem;
            padding-bottom: 0px;

            .cont_head {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .rp {
                    p {
                        margin: 0;
                        position: relative;
                        margin-left: 15px;
                        text-transform: capitalize;
                        font-size: 16px;
                        font-weight: 600;

                        &::after {
                            position: absolute;
                            top: 0;
                            left: -10px;
                            right: 10;
                            bottom: 0;
                            content: "";
                            height: 100%;
                            width: 4px;
                        }
                    }

                    h1 {
                        font-size: 48px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }
                }

                .mar {
                    text-align: end;

                    h3 {
                        display: inline;
                        padding: 0.5rem 2rem;
                        font-size: 16px;
                        border-radius: 50px;
                        color: $white;
                        font-weight: 500;

                        i {
                            margin-right: 15px;
                            cursor: pointer;
                        }
                    }

                    h4 {
                        margin: 0;
                        margin-top: 1rem;
                        font-size: 16px;

                        span {
                            font-weight: bold;

                        }
                    }
                }
            }

            .video_cont {
                margin-top: 10px;
                text-align: center;

                h5 {
                    font-size: 20px;
                    margin-bottom: 15px;
                    text-align: left;


                }

                .imageContainer {
                    max-width: 90%;
                    margin: auto;
                    height: 30vh;

                    background-size: cover;
                    background-position: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    >div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            font-size: 3rem;
                            color: white;
                        }

                        span {
                            font-size: 1.2rem;
                            color: white;
                        }
                    }

                }

                .videoContainer {
                    height: 30vh;
                    max-width: 90%;
                    margin: auto;

                    video {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                    }
                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1.5rem;
                    margin-bottom: 0rem;

                    button {
                        padding: 0.4rem;
                        width: 260px;
                        background-color: transparent;
                        font-size: 20px;
                        font-weight: 500;


                        i {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .reportContent {
        .content {
            padding: 2rem 4rem;
            padding-bottom: 0px;

            .cont_head {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .rp {
                    p {
                        margin: 0;
                        position: relative;
                        margin-left: 15px;
                        text-transform: capitalize;
                        font-size: 14px;
                        font-weight: 600;

                        &::after {
                            position: absolute;
                            top: 0;
                            left: -10px;
                            right: 10;
                            bottom: 0;
                            content: "";
                            height: 100%;
                            width: 4px;
                        }
                    }

                    h1 {
                        font-size: 40px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }
                }

                .mar {
                    text-align: end;

                    h3 {
                        display: inline;
                        padding: 0.5rem 2rem;
                        font-size: 14px;
                        border-radius: 50px;
                        color: $white;
                        font-weight: 500;

                        i {
                            margin-right: 15px;
                            cursor: pointer;
                        }
                    }

                    h4 {
                        margin: 0;
                        margin-top: 1rem;
                        font-size: 14px;

                        span {
                            font-weight: bold;

                        }
                    }
                }
            }

            .video_cont {
                margin-top: 10px;
                text-align: center;

                h5 {
                    font-size: 18px;
                    margin-bottom: 15px;
                    text-align: left;


                }

                .imageContainer {
                    max-width: 100%;
                    margin: auto;
                    height: 47vh;

                    background-size: cover;
                    background-position: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    >div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            font-size: 3rem;
                            color: white;
                        }

                        span {
                            font-size: 1.2rem;
                            color: white;
                        }
                    }

                }

                .videoContainer {
                    height: 47vh;
                    max-width: 100%;
                    margin: auto;

                    video {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                    }
                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1.5rem;
                    margin-bottom: 0rem;

                    button {
                        padding: 0.2rem;
                        width: 210px;
                        background-color: transparent;
                        font-size: 18px;
                        font-weight: 500;

                        &:hover {
                            color: $white;
                        }

                        i {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px)and (min-height:750px) {
    .reportContent {
        .content {
            padding: 3rem 4rem;
            padding-bottom: 0px;

            .cont_head {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .rp {
                    p {
                        margin: 0;
                        position: relative;
                        margin-left: 15px;
                        text-transform: capitalize;
                        font-size: 14px;
                        font-weight: 600;

                        &::after {
                            position: absolute;
                            top: 0;
                            left: -10px;
                            right: 10;
                            bottom: 0;
                            content: "";
                            height: 100%;
                            width: 4px;
                        }
                    }

                    h1 {
                        font-size: 40px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }
                }

                .mar {
                    text-align: end;

                    h3 {
                        display: inline;
                        padding: 0.5rem 2rem;
                        font-size: 14px;
                        border-radius: 50px;
                        color: $white;
                        font-weight: 500;

                        i {
                            margin-right: 15px;
                            cursor: pointer;
                        }
                    }

                    h4 {
                        margin: 0;
                        margin-top: 1rem;
                        font-size: 14px;

                        span {
                            font-weight: bold;

                        }
                    }
                }
            }

            .video_cont {
                margin-top: 10px;
                text-align: center;

                h5 {
                    font-size: 18px;
                    margin-bottom: 15px;
                    text-align: left;


                }

                .imageContainer {
                    max-width: 100%;
                    margin: auto;
                    height: 30vh;
                    background-size: cover;
                    background-position: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    >div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            font-size: 3rem;
                            color: white;
                        }

                        span {
                            font-size: 1.2rem;
                            color: white;
                        }
                    }

                }

                .videoContainer {
                    height: 30vh;
                    max-width: 100%;
                    margin: auto;

                    video {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                    }
                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1.5rem;
                    margin-bottom: 0rem;

                    button {
                        padding: 0.2rem;
                        width: 210px;
                        background-color: transparent;
                        font-size: 18px;
                        font-weight: 500;

                        &:hover {
                            color: $white;
                        }

                        i {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .reportContent {
        .content {
            padding: 4rem 2rem;
            padding-bottom: 0px;

            .cont_head {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .rp {
                    p {
                        margin: 0;
                        position: relative;
                        margin-left: 15px;
                        text-transform: capitalize;
                        font-size: 12px;
                        font-weight: 600;

                        &::after {
                            position: absolute;
                            top: 0;
                            left: -10px;
                            right: 10;
                            bottom: 0;
                            content: "";
                            height: 100%;
                            width: 3.5px;
                        }
                    }

                    h1 {
                        font-size: 32px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }
                }

                .mar {
                    text-align: end;

                    h3 {
                        display: inline;
                        padding: 0.5rem 2rem;
                        font-size: 12px;
                        border-radius: 50px;
                        color: $white;
                        font-weight: 500;

                        i {
                            margin-right: 15px;
                            cursor: pointer;
                        }
                    }

                    h4 {
                        margin: 0;
                        margin-top: 1rem;
                        font-size: 12px;

                        span {
                            font-weight: bold;

                        }
                    }
                }
            }

            .video_cont {
                margin-top: 5px;
                text-align: center;

                h5 {
                    font-size: 16px;
                    margin-bottom: 15px;
                    text-align: left;


                }

                .imageContainer {
                    height: 48vh;
                    width: auto;
                    background-size: cover;
                    background-position: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    >div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            font-size: 2.5rem;
                            color: white;
                        }

                        span {
                            font-size: 1rem;
                            color: white;
                        }
                    }

                }

                .videoContainer {
                    height: 48vh;
                    width: auto;

                    video {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                    }
                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1.5rem;
                    margin-bottom: 0rem;

                    button {
                        margin-top: 0.8rem;
                        padding: 0.2rem;
                        width: 140px;
                        background-color: transparent;
                        font-size: 14px;
                        font-weight: 500;

                        i {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .reportContent {
        .content {
            padding: 3rem 1rem;
            padding-bottom: 0px;

            .cont_head {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .rp {
                    p {
                        margin: 0;
                        position: relative;
                        margin-left: 15px;
                        text-transform: capitalize;
                        font-size: 11px;
                        font-weight: 600;

                        &::after {
                            position: absolute;
                            top: 0;
                            left: -10px;
                            right: 10;
                            bottom: 0;
                            content: "";
                            height: 100%;
                            width: 3.5px;
                        }
                    }

                    h1 {
                        font-size: 26px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }
                }

                .mar {
                    text-align: end;

                    h3 {
                        display: inline;
                        padding: 0.5rem 1rem;
                        font-size: 11px;
                        border-radius: 50px;
                        color: $white;
                        font-weight: 500;

                        i {
                            margin-right: 15px;
                            cursor: pointer;
                        }
                    }

                    h4 {
                        margin: 0;
                        margin-top: 1rem;
                        font-size: 8px;

                        span {
                            font-weight: bold;

                        }
                    }
                }
            }

            .video_cont {
                margin-top: 5px;
                text-align: center;

                h5 {
                    font-size: 12px;
                    margin-bottom: 15px;
                    text-align: left;


                }

                .imageContainer {
                    height: 25vh;
                    width: auto;
                    background-size: cover;
                    background-position: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    >div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            font-size: 3.5rem;
                            color: white;
                        }

                        span {
                            font-size: 1.5rem;
                            color: white;
                        }
                    }

                }

                .videoContainer {
                    height: 25vh;
                    width: auto;

                    video {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                    }
                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1.5rem;
                    margin-bottom: 0rem;

                    button {
                        margin-top: 0.8rem;
                        padding: 0.3rem;
                        width: 120px;
                        background-color: transparent;
                        font-size: 10px;
                        font-weight: 500;

                        &:hover {
                            color: $white;
                        }

                        i {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}