$darkgreen: #329633;
$mediumgreen: #D9F0DE;
$lightgreen: #E8FFE8;
$white: #FFFFFF;
$black: #000000;

$primary_color: #026B33;
$secondary_color: #D9F0DE;
$cta_color: #247E45;
$incomplete_progress_bar_color: #E0E1E6;
$inactive_text_color: #8B8D98;
$black_text_color: #000000;
$white_text_color: #FFFFFF;
$background_color: #FFFFFF;
$light_orange: #FDEAB8;
$red_color: #970000;


$h1_font_size: 160pts;
$h1_font_weight: 900;

$h2_font_size: 128pts;
$h2_font_weight: 800;

$h3_font_size: 96pts;
$h3_font_weight: 800;

$h4_font_size: 48pts;
$h4_font_weight: 800;

$h5_font_size: 48pts;
$h5_font_weight: 700;

$h6_font_size: 36pts;
$h6_font_weight: 600;

$h7_font_size: 32pts;
$h7_font_weight: 600;

$h8_font_size: 28pts;
$h8_font_weight: 800;

$h9_font_size: 24pts;
$h9_font_weight: 800;