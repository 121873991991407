 @import "../../SCSS/variables";

 .right_container {
     position: relative;
     padding: 2rem 3rem;


     .header {

         display: flex;
         justify-content: space-between;
         align-items: center;

         h2 {
             font-size: 1.6rem;
             font-weight: 700;
             margin-bottom: 1.8rem;
         }

         button {
             background-color: transparent;
             outline: none;
             border: 2px solid $inactive_text_color;
             padding: 0px;
             margin: 0px;
             padding: 4px 10px;
             border-radius: 50%;
             font-size: 14px;
             font-weight: 600;
             position: absolute;
             top: 1rem;
             right: 1rem;
         }
     }

     .video_box {
         video {
             width: 100%;
             height: 100%;
         }
     }




 }