@import '../../../SCSS/variables';

.support_container {
    padding: 2rem;

    .heading {
        text-transform: uppercase;
        font-size: 5rem;
        font-weight: 800;
        color: $cta_color;
    }

    p {
        margin-top: 2rem;
        font-size: 2rem;
        font-weight: 600;

        a {
            color: $cta_color;
        }
    }
}


@media screen and (max-width:1399px) {
    .support_container {
        padding: 2rem;

        .heading {
            font-size: 4.5rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1.8rem;
        }
    }
}

@media screen and (max-width:1199px) {
    .support_container {
        padding: 2rem;

        .heading {
            font-size: 3.8rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1.6rem;
        }
    }
}

@media screen and (max-width:991px) {
    .support_container {
        padding: 2rem;

        .heading {
            font-size: 3rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1.4rem;
        }
    }
}

@media screen and (max-width:767px) {
    .support_container {
        padding: 2rem;
        padding-top: 3rem;

        .heading {
            font-size: 2.5rem;
        }

        p {
            margin-top: 1.5rem;
            font-size: 1.2rem;
        }
    }
}

@media screen and (max-width:500px) {
    .support_container {
        padding: 2rem;
        padding-top: 2rem;

        .heading {
            font-size: 1.6rem;
        }

        p {
            margin-top: 1rem;
            font-size: 1rem;
        }
    }
}