@import "../../assets/variables.scss";
@import "../../SCSS/variables";




.chooseplan-container {
  padding-block: 1.5rem;

  >div {
    max-width: 1140px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    button {
      background-color: transparent;
      color: #000;
      font-size: 24px;
      font-weight: 600;
      padding: 0;
      border: none;

      i {
        padding-right: 0.5rem;
      }
    }

    .exit {
      text-decoration: underline;
    }
  }

  .progress-bar-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .progress-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    background-color: transparent;
  }

  .step {
    flex: 1;
    text-align: center;
    font-weight: bold;
    padding: 4px 30px;
    background-color: #B1B1B1;
    margin: 0 0.5rem;
  }

  .step.active {
    background-color: $cta_color;
    color: #fff;
  }

  .indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .step-content {
    margin-top: 20px;

    .heading {
      margin-bottom: 0;
      margin-top: 2.5rem;
      font-size: 36px;
      font-weight: 600;
    }

    .grid_container {
      margin-top: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .specific-area {
        border: none;
        outline: none;
        cursor: pointer;
        margin: 5px;
        width: 100%;
        padding-block: 1rem;
        // height: 100px;
        border-radius: 20px;
        background-color: $white_text_color;
        color: #000000;
        font-weight: 600;
        font-size: 32px;
        border: 2px solid $cta_color;
      }

      .specific-area:hover {
        background-color: $secondary_color;
        color: $cta_color;
      }

      .specific-area.selected:hover,
      .specific-area.selected {
        background-color: $secondary_color;
        color: $cta_color;
      }
    }

    .container {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 0;
      box-sizing: border-box;

      .area_container {
        border: 2px solid $cta_color;
        border-bottom: none;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        button {
          margin-top: 0;
          border-right: 2px solid $cta_color;
          font-size: 32;
          font-weight: 600;
        }

        .specific-technique {
          border: none;
          outline: none;
          cursor: pointer;
          width: 100%;
          height: 100px;
          // border-radius: 5px;
          background-color: $white;
          color: #000;
          font-weight: 600;
          font-size: 22px;
        }

        .specific-technique.selected {
          background-color: $cta_color;
          color: #fff;
        }
      }

      .options_container {
        min-height: 200px;
        background-color: #fff;
        border: 2px solid $cta_color;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        display: flex;
        flex-wrap: wrap;
        padding: 2rem 1rem;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;



        .option_box {
          display: flex;
          align-items: center;
          margin-right: 1rem;
          border: 1px solid $cta_color;
          margin-bottom: 0.5rem;
          border-radius: 10px;
          padding: 10px 35px;
          cursor: pointer;
          font-size: 20px;

          icon {
            margin-right: 10px;
          }
        }
      }

      .options_container .selected_option {
        background-color: $secondary_color;
      }
    }

  }

  .button-bottm {
    margin-top: 3rem;
    text-align: center;

    button {
      padding: 0.5rem 5rem;
      font-size: 24px;
      font-weight: 500;
      background-color: $cta_color;
      color: $white;
      border: none;

      i {
        padding-left: 0.5rem;

      }
    }
  }

  :global {
    .css-mhc70k-MuiGrid-root {
      justify-content: space-between !important;
    }
  }

  .plan_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1rem 3rem;
    margin-top: 4rem;
    // column-gap: 3rem;
    box-sizing: border-box;

    .left_box {
      display: flex;
      flex-direction: column;
      width: 45%;

      .left_top {
        height: 40px;
        background-color: $cta_color;
        text-align: center;
        color: #fff;
        padding-left: 0.5rem;
        margin-bottom: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-top: 10px solid $cta_color;
        border-inline: 10px solid $cta_color;
        text-transform: uppercase;
        font-weight: 500;
        width: 50%;
        cursor: pointer;
      }

      .left_container {
        background-color: $white_text_color;
        border: 10px solid $cta_color;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        border-top-left-radius: 0px;
        padding: 20px;

        .top {
          display: flex;
          justify-content: space-around;
          align-content: center;
          width: 100%;
          margin-bottom: 1rem;

          .left {
            border-right: 1px solid black;
            width: 50%;
            align-content: center;
            line-height: 1;

            p {
              font-weight: 600;
              color: #000;
              font-size: 22px;
              text-align: center;
            }

            h1 {
              text-align: center;

              span {
                color: $cta_color;
                font-weight: 700;
                // font-size: 24px;
              }
            }
          }

          .right {
            width: 50%;
            align-items: center;
            justify-content: center;
            line-height: 1;

            p {
              text-align: center;
              font-weight: 600;
              color: #000;
              font-size: 22px;
              margin-bottom: 0.5rem;
            }

            p:nth-child(1) {
              text-decoration: line-through;
              font-size: 20px;
              font-weight: 600;
              color: black;
            }

            h1 {
              font-weight: 700;
              text-align: center;
              color: $cta_color;
            }
          }
        }

        .bottom {
          div {
            color: black;

            .icon {
              margin-right: 10px;
              color: $cta_color;
            }
          }
        }
      }
    }

    .left_container:hover {
      background-color: $secondary_color;
    }

    .left_box.selectedPlan .left_container {
      background-color: $secondary_color;
    }

    .right_box {
      display: flex;
      flex-direction: column;
      width: 45%;

      .right_top {
        height: 45px;
      }

      .right_container {
        background-color: $white_text_color;
        border: 2px solid $cta_color;
        padding: 1rem;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        padding: 20px;

        .top {
          display: flex;
          justify-content: space-around;
          align-content: center;
          width: 100%;
          margin-bottom: 1rem;

          .left {
            border-right: 1px solid black;
            width: 50%;
            align-content: center;
            line-height: 1;

            p {
              font-weight: 600;
              color: #000;
              font-size: 22px;
              text-align: center;
            }

            h1 {
              text-align: center;

              span {
                color: $cta_color;
                font-weight: 700;
              }
            }
          }

          .right {
            width: 50%;
            align-items: center;
            justify-content: center;
            line-height: 1;

            p {
              text-align: center;
              font-weight: 600;
              color: #000;
              font-size: 22px;
              margin-bottom: 0.5rem;
            }

            p:nth-child(1) {
              text-decoration: line-through;
              font-size: 20px;
              font-weight: 600;
              color: black;
            }

            h1 {
              font-weight: 700;
              text-align: center;
              color: $cta_color;
            }
          }
        }

        .bottom {
          div {
            color: black;

            .icon {
              margin-right: 10px;
              color: $cta_color;
            }
          }
        }
      }
    }

    .right_container:hover {
      background-color: $secondary_color;
    }

    .right_container.selectedPlan {
      background-color: $secondary_color;
    }
  }

  .error {
    font-size: 16px;
    font-weight: 400;
    color: red;
    padding: 1rem;
    min-height: 20px;
    text-align: center;

  }

}


@media screen and (max-width: 1399px) {
  .chooseplan-container {
    padding-block: 2.5rem;
    padding-inline: 1rem;

    >div {
      max-width: 1140px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      button {
        background-color: transparent;
        color: #000;
        font-size: 22px;
        font-weight: 600;
        padding: 0;
        border: none;

        i {
          padding-right: 0.5rem;
        }
      }

      .exit {
        text-decoration: underline;
      }
    }

    .progress-bar-container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .progress-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      background-color: transparent;
    }

    .step {
      flex: 1;
      text-align: center;
      font-weight: bold;
      padding: 4px 30px;
      background-color: #B1B1B1;
      margin: 0 0.5rem;
    }

    .step.active {
      background-color: $cta_color;
      color: #fff;
    }

    .indicator {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .step-content {
      margin-top: 20px;

      .heading {
        margin-bottom: 0;
        margin-top: 2rem;
        font-size: 26px;
        font-weight: 600;
      }

      .grid_container {
        margin-top: 4rem;


        .specific-area {
          border: none;
          outline: none;
          cursor: pointer;
          margin: 5px;
          width: 100%;
          padding-block: 1rem;
          // height: 100px;
          border-radius: 20px;
          background-color: $white_text_color;
          color: #000000;
          font-weight: 600;
          font-size: 22px;
          border: 2px solid $cta_color;
        }

        .specific-area:hover {
          background-color: $secondary_color;
          color: $cta_color;
        }

        .specific-area.selected:hover,
        .specific-area.selected {
          background-color: $secondary_color;
          color: $cta_color;
        }
      }

      .container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        row-gap: 0;
        box-sizing: border-box;

        .area_container {
          border: 2px solid $cta_color;
          border-bottom: none;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;

          button {
            margin-top: 0;
            border-right: 2px solid $cta_color;
            font-size: 26;
            font-weight: 600;
          }

          .specific-technique {
            border: none;
            outline: none;
            cursor: pointer;
            width: 100%;
            height: 100px;
            // border-radius: 5px;
            background-color: $white;
            color: #000;
            font-weight: 600;
            font-size: 22px;
          }

          .specific-technique.selected {
            background-color: $cta_color;
            color: #fff;
          }
        }

        .options_container {
          min-height: 160px;
          background-color: #fff;
          border: 2px solid $cta_color;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          display: flex;
          flex-wrap: wrap;
          padding: 1rem 1rem;
          justify-content: center;
          align-items: flex-start;
          gap: 1rem;



          .option_box {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            border: 1px solid $cta_color;
            margin-bottom: 0.5rem;
            border-radius: 10px;
            padding: 10px 30px;
            cursor: pointer;
            font-size: 16px;

            icon {
              margin-right: 8px;
            }
          }
        }

        .options_container .selected_option {
          background-color: $secondary_color;
        }
      }

    }

    .button-bottm {
      margin-top: 1rem;
      text-align: center;

      button {
        padding: 0.5rem 4.5rem;
        font-size: 20px;
        font-weight: 500;
        background-color: $cta_color;
        color: $white;
        border: none;

        i {
          padding-left: 0.5rem;

        }
      }
    }

    :global {
      .css-mhc70k-MuiGrid-root {
        justify-content: space-between !important;
      }
    }

    .plan_container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 1rem 3rem;
      // column-gap: 3rem;
      box-sizing: border-box;

      .left_box {
        display: flex;
        flex-direction: column;
        width: 45%;

        .left_top {
          height: 40px;
          background-color: $cta_color;
          text-align: center;
          color: #fff;
          padding-left: 0.5rem;
          margin-bottom: 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-top: 10px solid $cta_color;
          border-inline: 10px solid $cta_color;
          text-transform: uppercase;
          font-weight: 500;
          width: 50%;
          cursor: pointer;
        }

        .left_container {
          background-color: $white_text_color;
          border: 10px solid $cta_color;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          border-top-left-radius: 0px;
          padding: 20px;

          .top {
            display: flex;
            justify-content: space-around;
            align-content: center;
            width: 100%;
            margin-bottom: 1rem;

            .left {
              border-right: 1px solid black;
              width: 50%;
              align-content: center;
              line-height: 1;

              p {
                font-weight: 600;
                color: #000;
                font-size: 20px;
                text-align: center;
              }

              h1 {
                text-align: center;
                font-size: 2rem;

                span {
                  color: $cta_color;
                  font-weight: 700;
                  // font-size: 24px;
                }
              }
            }

            .right {
              width: 50%;
              align-items: center;
              justify-content: center;
              line-height: 1;

              p {
                text-align: center;
                font-weight: 600;
                color: #000;
                font-size: 18px;
                margin-bottom: 0.5rem;
              }

              p:nth-child(1) {
                text-decoration: line-through;
                font-size: 20px;
                font-weight: 600;
                color: black;
              }

              h1 {
                font-weight: 700;
                text-align: center;
                color: $cta_color;
                font-size: 2rem;
              }
            }
          }

          .bottom {
            div {
              color: black;

              .icon {
                margin-right: 10px;
                color: $cta_color;
              }
            }
          }
        }
      }

      .left_container:hover {
        background-color: $secondary_color;
      }

      .left_box.selectedPlan .left_container {
        background-color: $secondary_color;
      }

      .right_box {
        display: flex;
        flex-direction: column;
        width: 45%;

        .right_top {
          height: 45px;
        }

        .right_container {
          background-color: $white_text_color;
          border: 2px solid $cta_color;
          padding: 1rem;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          padding: 20px;

          .top {
            display: flex;
            justify-content: space-around;
            align-content: center;
            width: 100%;
            margin-bottom: 1rem;

            .left {
              border-right: 1px solid black;
              width: 50%;
              align-content: center;
              line-height: 1;

              p {
                font-weight: 600;
                color: #000;
                font-size: 20px;
                text-align: center;
              }

              h1 {
                text-align: center;
                font-size: 2rem;

                span {
                  color: $cta_color;
                  font-weight: 700;
                }
              }
            }

            .right {
              width: 50%;
              align-items: center;
              justify-content: center;
              line-height: 1;

              p {
                text-align: center;
                font-weight: 600;
                color: #000;
                font-size: 20px;
                margin-bottom: 0.5rem;
              }

              p:nth-child(1) {
                text-decoration: line-through;
                font-size: 20px;
                font-weight: 600;
                color: black;
              }

              h1 {
                font-weight: 700;
                text-align: center;
                color: $cta_color;
                font-size: 2rem;
              }
            }
          }

          .bottom {
            div {
              color: black;

              .icon {
                margin-right: 10px;
                color: $cta_color;
              }
            }
          }
        }
      }

      .right_container:hover {
        background-color: $secondary_color;
      }

      .right_container.selectedPlan {
        background-color: $secondary_color;
      }
    }

    .error {
      font-size: 16px;
      font-weight: 400;
      color: red;
      padding: 1rem;
      min-height: 20px;
      text-align: center;

    }

  }
}



@media screen and (max-width: 1199px) {}


@media screen and (max-width: 992px) {
  .chooseplan-container {
    padding-block: 3rem;
    padding-inline: 1.5rem;

    >div {
      max-width: 1140px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      button {
        background-color: transparent;
        color: #000;
        font-size: 22px;
        font-weight: 600;
        padding: 0;
        border: none;

        i {
          padding-right: 0.5rem;
        }
      }

      .exit {
        text-decoration: underline;
      }
    }

    .progress-bar-container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .progress-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      background-color: transparent;
    }

    .step {
      flex: 1;
      text-align: center;
      font-weight: bold;
      padding: 4px 30px;
      background-color: #B1B1B1;
      margin: 0 0.5rem;
    }

    .step.active {
      background-color: $cta_color;
      color: #fff;
    }

    .indicator {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .step-content {
      margin-top: 20px;

      .heading {
        margin-bottom: 0;
        margin-top: 2.2rem;
        font-size: 26px;
        font-weight: 600;
      }

      .grid_container {
        margin-top: 7rem;


        .specific-area {
          border: none;
          outline: none;
          cursor: pointer;
          margin: 5px;
          width: 100%;
          padding-block: 1rem;
          // height: 100px;
          border-radius: 20px;
          background-color: $white_text_color;
          color: #000000;
          font-weight: 600;
          font-size: 24px;
          border: 2px solid $cta_color;
        }

        .specific-area:hover {
          background-color: $secondary_color;
          color: $cta_color;
        }

        .specific-area.selected:hover,
        .specific-area.selected {
          background-color: $secondary_color;
          color: $cta_color;
        }
      }

      .container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        row-gap: 0;
        box-sizing: border-box;

        .area_container {
          border: 2px solid $cta_color;
          border-bottom: none;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;

          button {
            margin-top: 0;
            border-right: 2px solid $cta_color;
            font-size: 30;
            font-weight: 600;
          }

          .specific-technique {
            border: none;
            outline: none;
            cursor: pointer;
            width: 100%;
            height: 100px;
            // border-radius: 5px;
            background-color: $white;
            color: #000;
            font-weight: 600;
            font-size: 20px;
          }

          .specific-technique.selected {
            background-color: $cta_color;
            color: #fff;
          }
        }

        .options_container {
          min-height: 200px;
          background-color: #fff;
          border: 2px solid $cta_color;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          display: flex;
          flex-wrap: wrap;
          padding: 2rem 1rem;
          justify-content: center;
          align-items: flex-start;
          gap: 1rem;



          .option_box {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            border: 1px solid $cta_color;
            margin-bottom: 0.5rem;
            border-radius: 10px;
            padding: 8px 30px;
            cursor: pointer;
            font-size: 18px;

            icon {
              margin-right: 10px;
            }
          }
        }

        .options_container .selected_option {
          background-color: $secondary_color;
        }
      }

    }

    .button-bottm {
      margin-top: 3rem;
      text-align: center;

      button {
        padding: 0.5rem 4.5rem;
        font-size: 20px;
        font-weight: 500;
        background-color: $cta_color;
        color: $white;
        border: none;

        i {
          padding-left: 0.5rem;

        }
      }
    }

    :global {
      .css-mhc70k-MuiGrid-root {
        justify-content: space-between !important;
      }
    }

    .plan_container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 1rem 3rem;
      margin-top: 3rem;
      // column-gap: 3rem;
      box-sizing: border-box;

      .left_box {
        display: flex;
        flex-direction: column;
        width: 47%;

        .left_top {
          height: 40px;
          background-color: $cta_color;
          text-align: center;
          color: #fff;
          padding-left: 0.5rem;
          margin-bottom: 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-top: 10px solid $cta_color;
          border-inline: 10px solid $cta_color;
          text-transform: uppercase;
          font-weight: 500;
          width: 50%;
          cursor: pointer;
        }

        .left_container {
          background-color: $white_text_color;
          border: 10px solid $cta_color;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          border-top-left-radius: 0px;
          padding: 20px;

          .top {
            display: flex;
            justify-content: space-around;
            align-content: center;
            width: 100%;
            margin-bottom: 1rem;

            .left {
              border-right: 1px solid black;
              width: 50%;
              align-content: center;
              line-height: 1;

              p {
                font-weight: 600;
                color: #000;
                font-size: 20px;
                text-align: center;
              }

              h1 {
                text-align: center;

                span {
                  color: $cta_color;
                  font-weight: 700;
                  // font-size: 24px;
                }
              }
            }

            .right {
              width: 50%;
              align-items: center;
              justify-content: center;
              line-height: 1;

              p {
                text-align: center;
                font-weight: 600;
                color: #000;
                font-size: 20px;
                margin-bottom: 0.5rem;
              }

              p:nth-child(1) {
                text-decoration: line-through;
                font-size: 18px;
                font-weight: 600;
                color: black;
              }

              h1 {
                font-weight: 700;
                text-align: center;
                color: $cta_color;
              }
            }
          }

          .bottom {
            div {
              color: black;

              .icon {
                margin-right: 10px;
                color: $cta_color;
              }
            }
          }
        }
      }

      .left_container:hover {
        background-color: $secondary_color;
      }

      .left_box.selectedPlan .left_container {
        background-color: $secondary_color;
      }

      .right_box {
        display: flex;
        flex-direction: column;
        width: 47%;

        .right_top {
          height: 45px;
        }

        .right_container {
          background-color: $white_text_color;
          border: 2px solid $cta_color;
          padding: 1rem;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          padding: 20px;

          .top {
            display: flex;
            justify-content: space-around;
            align-content: center;
            width: 100%;
            margin-bottom: 1rem;

            .left {
              border-right: 1px solid black;
              width: 50%;
              align-content: center;
              line-height: 1;

              p {
                font-weight: 600;
                color: #000;
                font-size: 20px;
                text-align: center;
              }

              h1 {
                text-align: center;

                span {
                  color: $cta_color;
                  font-weight: 700;
                }
              }
            }

            .right {
              width: 50%;
              align-items: center;
              justify-content: center;
              line-height: 1;

              p {
                text-align: center;
                font-weight: 600;
                color: #000;
                font-size: 20px;
                margin-bottom: 0.5rem;
              }

              p:nth-child(1) {
                text-decoration: line-through;
                font-size: 18px;
                font-weight: 600;
                color: black;
              }

              h1 {
                font-weight: 700;
                text-align: center;
                color: $cta_color;
              }
            }
          }

          .bottom {
            div {
              color: black;

              .icon {
                margin-right: 10px;
                color: $cta_color;
              }
            }
          }
        }
      }

      .right_container:hover {
        background-color: $secondary_color;
      }

      .right_container.selectedPlan {
        background-color: $secondary_color;
      }
    }

    .error {
      font-size: 16px;
      font-weight: 400;
      color: red;
      padding: 1rem;
      min-height: 20px;
      text-align: center;

    }

  }
}

@media screen and (max-width: 768px) {
  .chooseplan-container {
    padding-block: 5rem;
    padding-inline: 1rem;

    >div {
      max-width: 1140px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      button {
        background-color: transparent;
        color: #000;
        font-size: 20px;
        font-weight: 600;
        padding: 0;
        border: none;

        i {
          padding-right: 0.5rem;
        }
      }

      .exit {
        text-decoration: underline;
      }
    }

    .progress-bar-container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .progress-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      background-color: transparent;
    }

    .step {
      flex: 1;
      text-align: center;
      font-weight: bold;
      padding: 4px 30px;
      background-color: #B1B1B1;
      margin: 0 0.5rem;
    }

    .step.active {
      background-color: $cta_color;
      color: #fff;
    }

    .indicator {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .step-content {
      margin-top: 20px;

      .heading {
        margin-bottom: 0;
        margin-top: 2.2rem;
        font-size: 24px;
        font-weight: 600;
      }

      .grid_container {
        margin-top: 7rem;


        .specific-area {
          border: none;
          outline: none;
          cursor: pointer;
          margin: 5px;
          width: 100%;
          padding-block: 0.9rem;
          // height: 100px;
          border-radius: 20px;
          background-color: $white_text_color;
          color: #000000;
          font-weight: 600;
          font-size: 20px;
          border: 2px solid $cta_color;
        }

        .specific-area:hover {
          background-color: $secondary_color;
          color: $cta_color;
        }

        .specific-area.selected:hover,
        .specific-area.selected {
          background-color: $secondary_color;
          color: $cta_color;
        }
      }

      .container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        row-gap: 0;
        box-sizing: border-box;

        .area_container {
          border: 2px solid $cta_color;
          border-bottom: none;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;

          button {
            margin-top: 0;
            border-right: 2px solid $cta_color;
            font-size: 32;
            font-weight: 600;
          }

          .specific-technique {
            border: none;
            outline: none;
            cursor: pointer;
            width: 100%;
            height: 100px;
            // border-radius: 5px;
            background-color: $white;
            color: #000;
            font-weight: 600;
            font-size: 18px;
          }

          .specific-technique.selected {
            background-color: $cta_color;
            color: #fff;
          }
        }

        .options_container {
          min-height: 200px;
          background-color: #fff;
          border: 2px solid $cta_color;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          display: flex;
          flex-wrap: wrap;
          padding: 2rem 1rem;
          justify-content: center;
          align-items: flex-start;
          gap: 1rem;



          .option_box {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            border: 1px solid $cta_color;
            margin-bottom: 0.5rem;
            border-radius: 10px;
            padding: 8px 26px;
            cursor: pointer;
            font-size: 16px;

            icon {
              margin-right: 10px;
            }
          }
        }

        .options_container .selected_option {
          background-color: $secondary_color;
        }
      }

    }

    .button-bottm {
      margin-top: 3rem;
      text-align: center;

      button {
        padding: 0.5rem 4.5rem;
        font-size: 18px;
        font-weight: 500;
        background-color: $cta_color;
        color: $white;
        border: none;

        i {
          padding-left: 0.5rem;

        }
      }
    }

    :global {
      .css-mhc70k-MuiGrid-root {
        justify-content: space-between !important;
      }
    }

    .plan_container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0rem 0rem;
      margin-top: 2rem;
      box-sizing: border-box;

      .left_box {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: auto;

        .left_top {
          height: 40px;
          background-color: $cta_color;
          color: #fff;
          padding-left: 0.5rem;
          margin-bottom: 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-top: 10px solid $cta_color;
          border-inline: 10px solid $cta_color;
          text-transform: uppercase;
          font-weight: 500;
          width: 50%;
          cursor: pointer;
        }

        .left_container {
          background-color: $white_text_color;
          border: 8px solid $cta_color;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          border-top-left-radius: 0px;
          padding: 10px;

          .top {
            display: flex;
            justify-content: space-around;
            align-content: center;
            width: 100%;
            margin-bottom: 0.8rem;

            .left {
              border-right: 1px solid black;
              width: 50%;
              align-content: center;
              line-height: 1;

              p {
                font-weight: 600;
                color: #000;
                font-size: 18px;
                text-align: center;
              }

              h1 {
                text-align: center;

                span {
                  color: $cta_color;
                  font-weight: 700;
                  // font-size: 24px;
                }
              }
            }

            .right {
              width: 50%;
              align-items: center;
              justify-content: center;
              line-height: 1;

              p {
                text-align: center;
                font-weight: 600;
                color: #000;
                font-size: 18px;
              }

              p:nth-child(1) {
                text-decoration: line-through;
                font-size: 18px;
                font-weight: 600;
                color: black;
              }

              h1 {
                font-weight: 700;
                text-align: center;
                color: $cta_color;
              }
            }
          }

          .bottom {
            div {
              color: black;

              p {
                display: flex;
                flex-direction: row;
                align-items: first baseline;

                .icon {
                  margin-right: 10px;
                  color: $cta_color;
                }
              }
            }
          }
        }
      }

      .left_container:hover {
        background-color: $secondary_color;
      }

      .left_box.selectedPlan .left_container {
        background-color: $secondary_color;
      }

      .right_box {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: auto;

        .right_top {
          height: 45px;
        }

        .right_container {
          background-color: $white_text_color;
          border: 2px solid $cta_color;
          padding: 1rem;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          padding: 10px;

          .top {
            display: flex;
            justify-content: space-around;
            align-content: center;
            width: 100%;
            margin-bottom: 0.8rem;

            .left {
              border-right: 1px solid black;
              width: 50%;
              align-content: center;
              line-height: 1;

              p {
                font-weight: 600;
                color: #000;
                font-size: 18px;
                text-align: center;
              }

              h1 {
                text-align: center;

                span {
                  color: $cta_color;
                  font-weight: 700;
                }
              }
            }

            .right {
              width: 50%;
              align-items: center;
              justify-content: center;
              line-height: 1;

              p {
                text-align: center;
                font-weight: 600;
                color: #000;
                font-size: 18px;
              }

              p:nth-child(1) {
                text-decoration: line-through;
                font-size: 18px;
                font-weight: 600;
                color: black;
              }

              h1 {
                font-weight: 700;
                text-align: center;
                color: $cta_color;
              }
            }
          }

          .bottom {
            div {
              color: black;

              p {
                display: flex;
                flex-direction: row;
                align-items: first baseline;

                .icon {
                  margin-right: 10px;
                  color: $cta_color;
                }
              }
            }
          }
        }
      }

      .right_container:hover {
        background-color: $secondary_color;
      }

      .right_container.selectedPlan {
        background-color: $secondary_color;
      }
    }

    .error {
      font-size: 16px;
      font-weight: 400;
      color: red;
      padding: 1rem;
      min-height: 20px;
      text-align: center;

    }

  }
}

@media screen and (max-width: 576px) {
  .chooseplan-container {
    padding-block: 3rem;

    >div {
      width: 100%;
      max-width: 1140px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      button {
        background-color: transparent;
        color: #000;
        font-size: 16px;
        font-weight: 600;
        padding: 0;

        i {
          padding-right: 0.5rem;
        }
      }

      .exit {
        text-decoration: underline;
      }
    }

    .progress-bar-container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .progress-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      background-color: transparent;
    }

    .step {
      flex: 1;
      text-align: center;
      font-weight: bold;
      padding: 4px 30px;
      background-color: #B1B1B1;
      margin: 0 0.5rem;
    }

    .step.active {
      background-color: $cta_color;
      color: #fff;
    }

    .indicator {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .step-content {
      margin-top: 20px;

      .heading {
        margin-bottom: 0;
        margin-top: 2rem;
        font-size: 20px;
        font-weight: 600;
        text-align: start;
        padding-inline: 0.5rem;
      }

      .grid_container {
        margin-top: 1.5rem;

        .specific-area {
          border: none;
          outline: none;
          cursor: pointer;
          margin: 5px;
          width: 100%;
          padding-block: 0.5rem;
          // height: 100px;
          border-radius: 20px;
          background-color: $white_text_color;
          color: #000000;
          font-weight: 600;
          font-size: 16px;
          border: 2px solid $cta_color;
        }

        .specific-area:hover {
          background-color: $secondary_color;
          color: $cta_color;
        }

        .specific-area.selected:hover,
        .specific-area.selected {
          background-color: $secondary_color;
          color: $cta_color;
        }
      }

      .container {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        row-gap: 0;
        box-sizing: border-box;

        .area_container {
          border: 2px solid $cta_color;
          border-bottom: none;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;

          // button {
          //   margin-top: 0;
          //   border-right: 2px solid $cta_color;
          //   font-size: 18;
          //   font-weight: 600;
          // }

          .specific-technique {
            border: none;
            outline: none;
            cursor: pointer;
            width: 100%;
            background-color: $white;
            color: #000;
            font-weight: 600;
            font-size: 16px;
            height: 50px;
          }

          .specific-technique.selected {
            background-color: $cta_color;
            color: #fff;
          }
        }

        .options_container {
          min-height: 100px;
          background-color: #fff;
          border: 2px solid $cta_color;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          display: flex;
          flex-wrap: wrap;
          padding: 1rem;
          justify-content: center;
          align-items: flex-start;
          gap: 0.4rem;

          .option_box {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            border: 1px solid $cta_color;
            margin-bottom: 0.5rem;
            border-radius: 10px;
            padding: 5px 10px;
            cursor: pointer;
            font-size: 14px;

            icon {
              margin-right: 10px;
            }
          }
        }

        .options_container .selected_option {
          background-color: $secondary_color;
        }
      }

    }

    .button-bottm {
      margin-top: 2rem;
      text-align: center;

      button {
        padding: 0.5rem 3rem;
        font-size: 16px;
        font-weight: 500;
        background-color: $cta_color;
        color: $white;
        border: none;

        i {
          padding-left: 0.5rem;

        }
      }
    }

    :global {
      .css-mhc70k-MuiGrid-root {
        justify-content: space-between !important;
      }
    }

    .plan_container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0rem 0rem;
      margin-top: 2rem;
      box-sizing: border-box;

      .left_box {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: auto;

        .left_top {
          height: 40px;
          background-color: $cta_color;
          color: #fff;
          padding-left: 0.5rem;
          margin-bottom: 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-top: 10px solid $cta_color;
          border-inline: 10px solid $cta_color;
          text-transform: uppercase;
          font-weight: 500;
          width: 50%;
          cursor: pointer;
        }

        .left_container {
          background-color: $white_text_color;
          border: 8px solid $cta_color;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          border-top-left-radius: 0px;
          padding: 10px;

          .top {
            display: flex;
            justify-content: space-around;
            align-content: center;
            width: 100%;
            margin-bottom: 0.8rem;

            .left {
              border-right: 1px solid black;
              width: 50%;
              align-content: center;
              line-height: 1;

              p {
                font-weight: 600;
                color: #000;
                font-size: 18px;
                text-align: center;
              }

              h1 {
                text-align: center;

                span {
                  color: $cta_color;
                  font-weight: 700;
                  // font-size: 24px;
                }
              }
            }

            .right {
              width: 50%;
              align-items: center;
              justify-content: center;
              line-height: 1;

              p {
                text-align: center;
                font-weight: 600;
                color: #000;
                font-size: 18px;
              }

              p:nth-child(1) {
                text-decoration: line-through;
                font-size: 18px;
                font-weight: 600;
                color: black;
              }

              h1 {
                font-weight: 700;
                text-align: center;
                color: $cta_color;
              }
            }
          }

          .bottom {
            div {
              color: black;

              p {
                display: flex;
                flex-direction: row;
                align-items: first baseline;

                .icon {
                  margin-right: 10px;
                  color: $cta_color;
                }
              }
            }
          }
        }
      }

      .left_container:hover {
        background-color: $secondary_color;
      }

      .left_box.selectedPlan .left_container {
        background-color: $secondary_color;
      }

      .right_box {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: auto;

        .right_top {
          height: 45px;
        }

        .right_container {
          background-color: $white_text_color;
          border: 2px solid $cta_color;
          padding: 1rem;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          padding: 10px;

          .top {
            display: flex;
            justify-content: space-around;
            align-content: center;
            width: 100%;
            margin-bottom: 0.8rem;

            .left {
              border-right: 1px solid black;
              width: 50%;
              align-content: center;
              line-height: 1;

              p {
                font-weight: 600;
                color: #000;
                font-size: 18px;
                text-align: center;
              }

              h1 {
                text-align: center;

                span {
                  color: $cta_color;
                  font-weight: 700;
                }
              }
            }

            .right {
              width: 50%;
              align-items: center;
              justify-content: center;
              line-height: 1;

              p {
                text-align: center;
                font-weight: 600;
                color: #000;
                font-size: 18px;
              }

              p:nth-child(1) {
                text-decoration: line-through;
                font-size: 18px;
                font-weight: 600;
                color: black;
              }

              h1 {
                font-weight: 700;
                text-align: center;
                color: $cta_color;
              }
            }
          }

          .bottom {
            div {
              color: black;

              p {
                display: flex;
                flex-direction: row;
                align-items: first baseline;

                .icon {
                  margin-right: 10px;
                  color: $cta_color;
                }
              }
            }
          }
        }
      }

      .right_container:hover {
        background-color: $secondary_color;
      }

      .right_container.selectedPlan {
        background-color: $secondary_color;
      }
    }

    .error {
      font-size: 14px;
      font-weight: 400;
      color: red;
      padding: 1rem;
      min-height: 20px;
      text-align: center;

    }

  }
}