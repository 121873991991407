@import '../../../SCSS/variables';

.profileSidebar_container {
    min-height: 100vh;
    background-color: $primary_color;
    padding-block: 2rem;
    padding-inline: 1rem;
    padding-right: 2rem;

    .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: "#fff";

        ul {
            margin: 0px;
            padding: 0px;
            list-style-type: none;

            margin-top: 5rem;

            li {

                width: 100%;

                a {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-decoration: none;
                    color: white;
                    padding: 5px 15px;
                    margin-right: 10px;
                    font-weight: 500;
                    font-size: 20px;
                    border-radius: 10px;
                    margin-block: 20px;

                    span {
                        margin-left: 10px;
                    }

                    &:hover {
                        background-color: white;
                        color: black;
                    }

                }

                .activeLink {
                    background-color: white;
                    color: black;
                }


            }
        }

        .logout_button {
            width: 100%;
            text-align: start;
            padding: 5px 10px;
            font-size: 20px;
            color: white;
            font-weight: 500;
            background-color: black;
            border: none;
            outline: none;
            border-radius: 10px;

            i {
                margin-right: 10px;
            }
        }
    }

}

@media screen and (max-width:1399px) {}

@media screen and (max-width:1199px) {

    .profileSidebar_container {
        padding-block: 2rem;
        padding-inline: 1rem;
        padding-right: 1.5rem;

        .content {
            flex-direction: column;
            justify-content: space-between;
            color: $white;

            ul {
                margin-top: 3.5rem;

                li {

                    width: 100%;

                    a {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 5px 15px;
                        margin-right: 10px;
                        font-weight: 500;
                        font-size: 18px;
                        border-radius: 10px;
                        margin-block: 20px;

                        span {
                            margin-left: 10px;
                        }

                        &:hover {
                            color: black;
                        }

                    }


                }
            }

            .logout_button {
                text-align: start;
                padding: 5px 10px;
                font-size: 18px;
                font-weight: 500;
                border-radius: 10px;

                i {
                    margin-right: 10px;
                }
            }
        }

    }
}

@media screen and (max-width:991px) {}

@media screen and (max-width:767px) {
    .profileSidebar_container {
        padding-block: 1.8rem;
        padding-inline: 0.8rem;
        padding-right: 0.8rem;

        .content {
            flex-direction: column;
            justify-content: space-between;
            color: $white;

            ul {
                margin-top: 2.5rem;

                li {

                    width: 100%;

                    a {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 5px 10px;
                        margin-right: 10px;
                        font-weight: 500;
                        font-size: 15px;
                        border-radius: 10px;
                        margin-block: 12px;

                        span {
                            margin-left: 8px;
                        }

                        &:hover {
                            color: black;
                        }

                    }


                }
            }

            .logout_button {
                text-align: start;
                padding: 5px 8px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 10px;

                i {
                    margin-right: 10px;
                }
            }
        }

    }
}

@media screen and (max-width:575px) {

    .profileSidebar_container {
        padding-block: 1.8rem;
        padding-inline: 0.8rem;
        padding-right: 0.8rem;

        .content {
            flex-direction: column;
            justify-content: space-between;
            color: $white;

            ul {
                margin-top: 2.5rem;

                li {

                    width: 100%;

                    a {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 5px 6px;
                        margin-right: 8px;
                        font-weight: 500;
                        font-size: 10px;
                        border-radius: 10px;
                        margin-block: 8px;

                        span {
                            margin-left: 8px;
                        }

                        &:hover {
                            color: black;
                        }

                    }


                }
            }

            .logout_button {
                text-align: start;
                padding: 5px 6px;
                font-size: 10px;
                font-weight: 500;
                border-radius: 10px;

                i {
                    margin-right: 10px;
                }
            }
        }

    }
}