@import "../../../SCSS/variables";

.GamePostSidebar {
    padding-right: 1rem;
    width: 320px;
    height: 100%;
    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        li {
            margin-block: 2rem;

            a {
                text-decoration: none;
                color: inherit;
                padding: 8px 16px;
                font-weight: 500;

                i {
                    margin-right: 10px;
                }

                &.active {
                    background-color: $mediumgreen;
                    border: 2px solid $darkgreen;
                    border-radius: 8px;
                }
            }
        }
    }

    .guideline_video_wrap {
        border-top: 1px solid rgb(211, 208, 208);
        padding-block: 1rem;

        .content_wrap {
            .header {
                background-color: $inactive_text_color;
                padding-block: 10px;
                color: $white;
                font-weight: 500;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;

                h2 {
                    font-size: 1rem;
                    text-align: center;
                }
            }

            .content {
                padding: 1rem;
                border-left: 1px solid $inactive_text_color;
                border-bottom: 1px solid $inactive_text_color;
                border-right: 1px solid $inactive_text_color;

                .guidelines_wrp {
                    padding-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    row-gap: 2px;

                    .guidelines_list {
                        display: flex;
                        column-gap: 8px;
                        row-gap: 2px;

                        p {
                            font-size: 12px;
                            text-align: left;
                            font-weight: 500;
                            color: #000000;
                            text-align: justify;
                        }
                    }
                }

                .bottom_wrap {
                    margin-top: 2rem;

                    p {
                        text-align: center;
                        font-weight: 500;

                        a {
                            color: $primary_color;
                            font-weight: 600;
                        }
                    }
                }
            }




        }
    }
}

@media screen and (max-width: 768px) {
    .GamePostSidebar {
        padding-right: 0;
        width: 100%;
        transition: 0.8s;
        position: fixed;
        background-color: #fff;
        z-index: 3;
        bottom: 0;
        height: 100%;
        transition: all 0.8s;

        .open {
            transform: translate(0%) !important;
            transition: 0.8s;
            display: block;
        }
        ul{margin-top: 7rem;}
        .content_wrap{
            background: #ffffff;
            height: 100%;
        }
    }
    
}