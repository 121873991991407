@import '../../SCSS/variables';

.homePage {
  .banner {
    padding: 6px;
    padding-top: 15px;
    background-color: #C7C7C7;

    marquee {
      color: #970000;
      font-weight: 500;
      text-align: center;
    }
  }

  .background-video {
    position: relative;
    width: 100%;
    height: 91vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    video {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      pointer-events: none;


    }


    .text-overlay {
      position: relative;
      z-index: 2;
      // height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

    }

    .info {
      position: relative;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      z-index: 2;
    }

    .info h1 {
      font-size: 5.2rem;
      text-transform: uppercase;
      font-weight: 900;
      text-align: center;
    }

    .horizontal_line {
      border: 2px solid white;
      width: 492px;
      text-align: center;
    }

    .info p {
      text-align: center;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: auto;
      color: $white;
      max-width: 70%;
      padding: 0px;
      margin: 0px;
    }


    .ctaButton {
      width: 340px;
      height: 65px;
      font-weight: 700;
      font-size: 20px;
      background-color: $cta_color;
      color: $white;
      border: 1px solid $white;
      padding: 0.5rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .ctaButton:hover {
      background-color: $white !important;
      color: $cta_color !important;
    }

    .ctaButton img {
      width: 120px;
      height: 90px;
    }

    .ctaButton.ctaClicked {
      background-color: $white !important;
      color: $cta_color !important;
    }

    .arrow_dwn {
      display: block;
      text-align: center;
      background-color: transparent !important;
      border: none;
      margin-top: 5rem
    }


  }

  .Personalized_Report {
    background-color: $primary_color;
    padding: 3.5rem 0 2rem;

    .heading {
      text-align: left;
      padding-bottom: 1.5rem;
      margin-bottom: 2.5rem;

      h1 {
        font-size: 3.4rem;
        font-weight: 900;
        line-height: 78.02px;
        text-transform: uppercase;
        color: $white_text_color;

        span {
          color: $cta_color;
          background-color: $white_text_color;
          position: relative;
          padding: 0.2rem 1rem;
          border-radius: 10px;
        }
      }
    }

    .box {
      display: grid;
      grid-template-columns: 2fr 1fr;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 0 20px #0000004f;
      margin-bottom: 5rem;


      .info_box {
        padding: 4.5rem 5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;


        h4 {
          font-size: 3rem;
          font-weight: 800;
          line-height: 48px;
          padding-bottom: 2rem;

          p {
            font-size: 3rem;
            font-weight: 800;
            display: inline;
            color: #000;
          }

          span {
            color: #000;
            position: relative;

            &::after {
              background-color: $cta_color;
              position: absolute;
              // top: 0;
              bottom: -10px;
              left: 0%;
              width: 100%;
              height: 6px;
              content: "";
              border-radius: 10px;
            }
          }

          .darkGrrenSpan {
            color: $cta_color;
          }

        }

        p {
          font-size: 1.5rem;
          font-weight: 400;
          padding-bottom: 1rem;
          line-height: 30px;
          color: $black_text_color;
        }

        >button {
          color: $white_text_color;
          font-weight: 600;
          font-size: 1.5rem;
          line-height: 30px;
          padding: 0.8rem 3rem;
          background-color: $cta_color;
          border: none;
        }

        >button:hover {
          border: 2px solid $cta_color;
          color: $cta_color;
          background-color: $white_text_color;


        }

      }

      img {
        width: 100%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

      }
    }
  }

  .our_experts {
    position: relative;
    padding: 4rem 0;
    background-color: $primary_color !important;
    z-index: -2;

    .heading {
      text-align: left;
      padding-bottom: 1.5rem;
      margin-bottom: 2.5rem;

      h1 {
        font-size: 5.5rem;
        font-weight: 900;
        line-height: 78.02px;
        text-transform: uppercase;
        border-radius: 10px;
        color: #fff;

        span {
          background-color: $white_text_color;
          color: $cta_color;
          margin-right: 1.5rem;
          border-radius: 10px;
          padding: 1rem 2rem;
        }
      }
    }

    .hero_color {
      position: absolute;
      content: "";
      background-color: $secondary_color !important;
      width: 100%;
      height: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    .experts_conatiner {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;

      .experts_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        background-color: white;
        justify-content: space-between;
        box-shadow: 0 0 15px #00000031;
        border-radius: 10px;

        .experts_top_cont {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          h6 {
            color: #000;
            font-weight: 800;
            font-size: 1.35rem;
            text-align: center;
            margin-top: 2rem;
          }

          img {
            width: 100%;
            max-height: 300px;
          }
        }

        .experts_bottom_cont {
          background-color: $cta_color;
          width: 100%;
          text-align: center;
          border-radius: 0px 0px 10px 10px;

          p:nth-child(1) {
            color: #FFFFFF;
            font-size: 20px;
            font-weight: 700;
            padding-top: 1rem;
          }

          p:nth-child(2) {
            color: $cta_color;
            background-color: #FFFFFF;
            font-size: 28px;
            font-weight: 800;
          }
        }
      }
    }

  }


  .homeBlog {
    padding: 4rem 0;
    position: relative;
    background-color: $primary_color ;
    z-index: 1;

    .hero_color {
      position: absolute;
      content: "";
      background-color: $secondary_color ;
      width: 100%;
      height: 50%;
      top: 0%;
      left: 0;
      right: 0;
      // bottom:50%;
      z-index: -1;
    }

    .heading {
      padding-bottom: 20px;

      h1 {
        font-size: 9rem;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 90%;
        text-align: start;
        color: $cta_color !important;
      }
    }

    .box {
      padding: 1rem;
      margin-bottom: 1rem;
      max-width: 370px;
      position: relative;
      cursor: pointer;

      .item {
        background-color: #fff;
        // background-color: red;
        padding: 1rem 1rem;
        box-shadow: 0 0 15px #00000031;
        border-radius: 10px;


        img {
          width: 100%;
          height: 239px;
          border-radius: 10px;
        }

        .blg_info {
          padding: 1rem 0.5rem;

          h5 {
            font-size: 1rem;
            font-weight: 400;
            color: $black_text_color;
          }

          ul {
            margin: 0px;
            padding: 0px;
            display: grid;
            grid-template-rows: auto auto;
            grid-template-columns: 1fr 1fr;
            text-align: center;
            column-gap: 0.5rem;
            row-gap: 0.5rem;
            margin: 1rem 0;

            li {
              list-style-type: none;
              border: 1px solid #0000006e;
              padding: 0.3rem 0.3rem;
              margin: 0 0.1rem;
              border-radius: 50px;
              font-size: 1rem;
              font-weight: 400;
              color: $black_text_color;
              cursor: pointer;
              transition: all 0.3s ease-in-out;

              &:hover {
                background-color: rgba(199, 199, 199, 0.461);
              }
            }
          }

          h2 {
            font-size: 1.5rem;
            font-weight: 700;
            color: $cta_color;
            text-transform: uppercase;

          }

          p {
            font-size: 1rem;
            font-weight: 400;
            color: $black_text_color;
            line-height: auto;
          }

          h3 {
            font-size: 1rem;
            font-weight: 400;
            color: $cta_color;
            text-transform: uppercase;
          }

          h5 {
            font-size: 1rem;
            font-weight: 500;
            color: $black_text_color;
            line-height: 150%;
          }
        }
      }

      .readMore {
        position: absolute;
        z-index: 9999999999999;
        bottom: -2rem;
        left: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: none;

        svg {
          color: $cta_color;
        }

        p {
          text-align: center;
          color: $white_text_color;
          font-size: 16px;
        }
      }

      &:hover {
        .readMore {
          display: flex;
        }
      }

    }

    // .pagination {
    //   text-align: center;
    //   color: $white;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;

    //   button {
    //     margin-right: 10px;
    //     border: none;
    //     background: transparent;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;

    //     i {
    //       font-size: 20px;
    //     }
    //   }
    // }
  }

  .review {
    padding: 4rem 0;
    background-color: $primary_color;
    position: relative;

    .heading {
      text-align: center;
      padding-bottom: 3rem;

      h2 {
        font-size: 5.5rem;
        font-weight: 800;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 4px;
      }
    }


    .image-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 150px;
      gap: 10px;
      justify-content: center;
      justify-items: center;
      align-items: center;
      margin-top: 7%;
      margin-bottom: 7%;
    }

    .image-grid .grid-item {
      height: 100%;
    }

    .button {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);

      button {
        padding: 0.5rem 3rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.5rem;
        background-color: $cta_color;
        color: $white_text_color;
        border: 2px solid $white_text_color;
      }

      button:hover {
        background-color: $white_text_color;
        color: $cta_color;
        border: 2px solid $cta_color;
      }
    }
  }


}

@media screen and (max-width: 1399px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 91vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        // height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 4.5rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 2px solid white;
        width: 450px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 350px;
        height: 60px;
        font-weight: 700;
        font-size: 20px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 120px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem
      }


    }

    .Personalized_Report {
      padding: 2rem 0 1rem;


      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;

        h1 {
          font-size: 3rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 2fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 4rem;


        .info_box {
          padding: 2rem 5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.5rem;

          h4 {
            font-size: 2.1rem;
            font-weight: 800;
            line-height: 46px;
            padding-bottom: 2rem;

            p {
              font-size: 2.2rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 6px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 1.2rem;
            font-weight: 400;
            padding-bottom: 1rem;
            line-height: 30px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 30px;
            padding: 0.8rem 3rem;
            background-color: $cta_color;
            border: none;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 3rem 0;
      z-index: -2;

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2.5rem;

        h1 {
          font-size: 4.5rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.8rem 2rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 1.1rem;
              text-align: center;
              margin-top: 2rem;
            }

            img {
              width: 100%;
              max-height: 280px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 16px;
              font-weight: 700;
              padding-top: 0.8rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 22px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 1.5rem 0;
      position: relative;
      z-index: 1;

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 7.5rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 1rem;
        margin-bottom: 1rem;
        max-width: 350px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          // background-color: red;
          padding: 1rem 1rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            height: 210px;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 0.8rem;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.3rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.8rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 1.2rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.8rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.8rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.8rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 150%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 16px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 2rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 1rem;

        h2 {
          font-size: 4.5rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 3px;
        }
      }

      .review_item {

        margin-bottom: 1rem;

        .box {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 0.8rem;
          background-color: #fff;
          padding: 1rem 1.5rem 1rem;
          border-radius: 10px;

          h3 {
            font-size: 1.2rem;
            font-weight: 700;
            text-transform: uppercase;
            padding-bottom: 1rem;
            padding-left: 1.2rem;

          }

          p {
            font-size: 0.9rem;
            font-weight: 400;
            position: relative;
            padding-left: 1.5rem;
            color: #000;

            span {
              font-size: 4rem;
              position: absolute;
              top: -25px;
              bottom: 0;
              left: -3px;
            }
          }
        }
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.5rem 3rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.2rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

@media screen and (max-width: 1399px)and (min-height:800px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 67vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        // height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 4.5rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 2px solid white;
        width: 450px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 350px;
        height: 60px;
        font-weight: 700;
        font-size: 20px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 120px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem
      }


    }

    .Personalized_Report {
      padding: 2rem 0 1rem;


      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;

        h1 {
          font-size: 3rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 2fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 4rem;


        .info_box {
          padding: 2rem 5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.5rem;

          h4 {
            font-size: 2.1rem;
            font-weight: 800;
            line-height: 46px;
            padding-bottom: 2rem;

            p {
              font-size: 2.2rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 6px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 1.2rem;
            font-weight: 400;
            padding-bottom: 1rem;
            line-height: 30px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 30px;
            padding: 0.8rem 3rem;
            background-color: $cta_color;
            border: none;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 3rem 0;
      z-index: -2;

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2.5rem;

        h1 {
          font-size: 4.5rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.8rem 2rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 1.1rem;
              text-align: center;
              margin-top: 2rem;
            }

            img {
              width: 100%;
              max-height: 280px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 16px;
              font-weight: 700;
              padding-top: 0.8rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 22px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 1.5rem 0;
      position: relative;
      z-index: 1;

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 7.5rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 1rem;
        margin-bottom: 1rem;
        max-width: 350px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          // background-color: red;
          padding: 1rem 1rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            height: 210px;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 0.8rem;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.3rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.8rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 1.2rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.8rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.8rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.8rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 150%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 16px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 2rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 1rem;

        h2 {
          font-size: 4.5rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 3px;
        }
      }

      .image-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 150px;
        gap: 10px;
        justify-content: center;
        justify-items: center;
        align-items: center;
        margin-top: 7%;
        margin-bottom: 7%;
      }


      .image-grid .grid-item {
        height: 100%;
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.5rem 3rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.2rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 91vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 3.5rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 2px solid white;
        width: 400px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 350px;
        height: 60px;
        font-weight: 700;
        font-size: 20px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 120px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem
      }


    }

    .Personalized_Report {
      padding: 3rem 0 1rem;

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2.5rem;

        h1 {
          font-size: 3rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 2fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 4rem;


        .info_box {
          padding: 2rem 5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.5rem;

          h4 {
            font-size: 2.5rem;
            font-weight: 800;
            line-height: 46px;
            padding-bottom: 2rem;

            p {
              font-size: 2.5rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 6px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 1.2rem;
            font-weight: 400;
            padding-bottom: 1rem;
            line-height: 30px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 30px;
            padding: 0.8rem 2.5rem;
            background-color: $cta_color;
            border: none;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          height: 100%;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 4rem 0;
      z-index: -2;

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2.5rem;

        h1 {
          font-size: 5rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.8rem 2rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.8rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 1rem;
              text-align: center;
              margin-top: 1.8rem;
            }

            img {
              width: 100%;
              max-height: 220px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 16px;
              font-weight: 700;
              padding-top: 0.8rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 22px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 3rem 0;
      position: relative;
      z-index: 1;

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 7rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 1rem;
        margin-bottom: 1rem;
        max-width: 370px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          // background-color: red;
          padding: 1rem 1rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            height: 220px;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 1rem;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.4rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.8rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 1.2rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.8rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.9rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.9rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 150%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 16px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 3rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 2rem;

        h2 {
          font-size: 4.5rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 4px;
        }
      }

      .review_item {

        // margin: 1rem;
        .box {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 0.8rem;
          background-color: #fff;
          padding: 1rem 1.5rem 1rem;
          border-radius: 10px;

          h3 {
            font-size: 1.2rem;
            font-weight: 700;
            text-transform: uppercase;
            padding-bottom: 1rem;
            padding-left: 1.2rem;

          }

          p {
            font-size: 0.8rem;
            font-weight: 400;
            position: relative;
            padding-left: 1.5rem;
            color: #000;

            span {
              font-size: 4rem;
              position: absolute;
              top: -25px;
              bottom: 0;
              left: -3px;
            }
          }
        }
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.5rem 3rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.2rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) and (min-height:750px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 60vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 3.5rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 2px solid white;
        width: 400px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 350px;
        height: 60px;
        font-weight: 700;
        font-size: 20px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 120px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem
      }


    }

    .Personalized_Report {
      padding: 3rem 0 1rem;

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2.5rem;

        h1 {
          font-size: 3rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 2fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 4rem;


        .info_box {
          padding: 2rem 5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.5rem;

          h4 {
            font-size: 2.5rem;
            font-weight: 800;
            line-height: 46px;
            padding-bottom: 2rem;

            p {
              font-size: 2.5rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 6px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 1.2rem;
            font-weight: 400;
            padding-bottom: 1rem;
            line-height: 30px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 30px;
            padding: 0.8rem 2.5rem;
            background-color: $cta_color;
            border: none;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          height: 100%;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 4rem 0;
      z-index: -2;

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2.5rem;

        h1 {
          font-size: 5rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.8rem 2rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.8rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 1rem;
              text-align: center;
              margin-top: 1.8rem;
            }

            img {
              width: 100%;
              max-height: 220px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 16px;
              font-weight: 700;
              padding-top: 0.8rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 22px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 3rem 0;
      position: relative;
      z-index: 1;

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 7rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 1rem;
        margin-bottom: 1rem;
        max-width: 370px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          // background-color: red;
          padding: 1rem 1rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            height: 220px;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 1rem;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.4rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.8rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 1.2rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.8rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.9rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.9rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 150%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 16px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 3rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 2rem;

        h2 {
          font-size: 4.5rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 4px;
        }
      }

      .image-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 150px;
        gap: 10px;
        justify-content: center;
        justify-items: center;
        align-items: center;
        margin-top: 7%;
        margin-bottom: 7%;
      }


      .image-grid .grid-item {
        height: 70%;
        width: 70%;
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.5rem 3rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.2rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 91vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 2px solid white;
        width: 350px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 320px;
        height: 60px;
        font-weight: 700;
        font-size: 18px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 120px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem
      }


    }

    .Personalized_Report {
      padding: 2rem 0 1rem;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;

        h1 {
          font-size: 2.5rem;
          font-weight: 900;
          line-height: 60px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 2rem;


        .info_box {
          padding: 1.5rem 2rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.2rem;

          h4 {
            font-size: 2rem;
            font-weight: 800;
            line-height: 46px;
            padding-bottom: 2rem;

            p {
              font-size: 2rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 6px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 1rem;
            font-weight: 400;
            padding-bottom: 1rem;
            line-height: 30px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 1rem;
            line-height: 30px;
            padding: 0.8rem 2.5rem;
            background-color: $cta_color;
            border: none;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          height: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 4rem 0;
      z-index: -2;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;

        h1 {
          font-size: 4rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.8rem 2rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.5rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 0.8rem;
              text-align: center;
              margin-top: 1.8rem;
            }

            img {
              width: 100%;
              max-height: 200px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 0.8rem;
              font-weight: 700;
              padding-top: 0.8rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 18px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 2rem 0;
      position: relative;
      z-index: 1;

      .container {
        max-width: 95%;
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 4.6rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 1rem;
        margin-bottom: 1rem;
        max-width: 370px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          // background-color: red;
          padding: 1rem 1rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            height: 220px;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 0.8rem !important;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.4rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.6rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 1rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.7rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.8rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.8rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 150%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 16px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 2.5rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 2rem;

        h2 {
          font-size: 4rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 4px;
        }
      }

      .image-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 150px;
        gap: 10px;
        justify-content: center;
        justify-items: center;
        align-items: center;
        margin-top: 7%;
        margin-bottom: 7%;
      }


      .image-grid .grid-item {
        height: 60%;
      }


      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.5rem 3rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.2rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-height:800px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 40vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 2px solid white;
        width: 350px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 320px;
        height: 60px;
        font-weight: 700;
        font-size: 18px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 120px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem
      }


    }

    .Personalized_Report {
      padding: 2rem 0 1rem;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;

        h1 {
          font-size: 2.5rem;
          font-weight: 900;
          line-height: 60px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 2rem;


        .info_box {
          padding: 1.5rem 2rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.2rem;

          h4 {
            font-size: 2rem;
            font-weight: 800;
            line-height: 46px;
            padding-bottom: 2rem;

            p {
              font-size: 2rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 6px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 1rem;
            font-weight: 400;
            padding-bottom: 1rem;
            line-height: 30px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 1rem;
            line-height: 30px;
            padding: 0.8rem 2.5rem;
            background-color: $cta_color;
            border: none;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          height: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 4rem 0;
      z-index: -2;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;

        h1 {
          font-size: 4rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.8rem 2rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.5rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 0.8rem;
              text-align: center;
              margin-top: 1.8rem;
            }

            img {
              width: 100%;
              max-height: 200px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 0.8rem;
              font-weight: 700;
              padding-top: 0.8rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 18px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 2rem 0;
      position: relative;
      z-index: 1;

      .container {
        max-width: 95%;
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 4.6rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 1rem;
        margin-bottom: 1rem;
        max-width: 370px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          // background-color: red;
          padding: 1rem 1rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            height: 180px;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 0.8rem !important;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.2rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.6rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 1rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.7rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.8rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.8rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 150%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 16px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 2.5rem 0;
      position: relative;

      img {
        width: 10%;
      }

      .heading {
        text-align: center;
        padding-bottom: 2rem;

        h2 {
          font-size: 4rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 4px;
        }
      }

      .review_item {

        // margin: 1rem;
        .box {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 0.4rem;
          background-color: #fff;
          padding: 0.5rem 0.5rem 0.5rem;
          border-radius: 10px;

          h3 {
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            padding-bottom: 1rem;
            padding-left: 1.2rem;

          }

          p {
            font-size: 0.8rem;
            font-weight: 400;
            position: relative;
            padding-left: 1.5rem;
            color: #000;

            span {
              font-size: 4rem;
              position: absolute;
              top: -25px;
              bottom: 0;
              left: -3px;
            }
          }
        }
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.5rem 3rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.2rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

@media screen and (max-width:850px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 91vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 2.4rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 2px solid white;
        width: 300px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 300px;
        height: 55px;
        font-weight: 700;
        font-size: 18px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 120px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem;

        img {
          width: 50px;
        }
      }


    }

    .Personalized_Report {
      padding: 2rem 0 1rem;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;

        h1 {
          font-size: 2.2rem;
          font-weight: 900;
          line-height: 60px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 2rem;


        .info_box {
          padding: 1.5rem 2rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.2rem;

          h4 {
            font-size: 1.8rem;
            font-weight: 800;
            line-height: 46px;
            padding-bottom: 2rem;

            p {
              font-size: 1.8rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 6px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 0.9rem;
            font-weight: 400;
            padding-bottom: 1rem;
            line-height: 30px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 0.9rem;
            line-height: 30px;
            padding: 0.8rem 2.5rem;
            background-color: $cta_color;
            border: none;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          height: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 4rem 0;
      z-index: -2;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;

        h1 {
          font-size: 3.5rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.8rem 2rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 0.8rem;
              text-align: center;
              margin-top: 1.8rem;
            }

            img {
              width: 100%;
              max-height: 180px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 0.7rem;
              font-weight: 700;
              padding-top: 0.4rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 16px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 2rem 0;
      position: relative;
      z-index: 1;

      .container {
        max-width: 95%;
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 4rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 0.5rem;
        margin-bottom: 1rem;
        max-width: 370px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          padding: 1rem 0.5rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            min-height: 100%;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 0.8rem !important;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.4rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.6rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 1rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.7rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.8rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.7rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 150%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 15px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 2rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 2rem;

        h2 {
          font-size: 3.5rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 4px;
        }
      }

      .review_item {

        // margin: 1rem;
        .box {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 0.8rem;
          background-color: #fff;
          padding: 1rem 1.5rem 1rem;
          border-radius: 10px;

          h3 {
            font-size: 0.9rem;
            font-weight: 700;
            text-transform: uppercase;
            padding-bottom: 0.8rem;
            padding-left: 1rem;

          }

          p {
            font-size: 0.8rem;
            font-weight: 400;
            position: relative;
            padding-left: 1.5rem;
            color: #000;

            span {
              font-size: 4rem;
              position: absolute;
              top: -25px;
              bottom: 0;
              left: -3px;
            }
          }
        }
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.5rem 3rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.1rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

@media screen and (max-width:850px) and (min-height:800px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 40vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 2.4rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 2px solid white;
        width: 300px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 300px;
        height: 55px;
        font-weight: 700;
        font-size: 18px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 120px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem;

        img {
          width: 50px;
        }
      }


    }

    .Personalized_Report {
      padding: 2rem 0 1rem;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;

        h1 {
          font-size: 2.2rem;
          font-weight: 900;
          line-height: 60px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 2rem;


        .info_box {
          padding: 1.5rem 2rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.2rem;

          h4 {
            font-size: 1.8rem;
            font-weight: 800;
            line-height: 46px;
            padding-bottom: 2rem;

            p {
              font-size: 1.8rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 6px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 0.9rem;
            font-weight: 400;
            padding-bottom: 1rem;
            line-height: 30px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 0.9rem;
            line-height: 30px;
            padding: 0.8rem 2.5rem;
            background-color: $cta_color;
            border: none;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          height: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 4rem 0;
      z-index: -2;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;

        h1 {
          font-size: 3.5rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.8rem 2rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 0.8rem;
              text-align: center;
              margin-top: 1.8rem;
            }

            img {
              width: 100%;
              max-height: 180px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 0.7rem;
              font-weight: 700;
              padding-top: 0.4rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 16px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 2rem 0;
      position: relative;
      z-index: 1;

      .container {
        max-width: 95%;
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 4rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 0.5rem;
        margin-bottom: 1rem;
        max-width: 370px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          padding: 1rem 0.5rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            min-height: 100%;
            max-height: 170px;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 0.8rem !important;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.2rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.6rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 1rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.7rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.8rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.7rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 150%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 15px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 2rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 2rem;

        h2 {
          font-size: 3.5rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 4px;
        }
      }

      .review_item {

        // margin: 1rem;
        .box {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 0.4rem;
          background-color: #fff;
          padding: 1rem 1rem 1rem;
          border-radius: 10px;


          h3 {
            font-size: 0.9rem;
            font-weight: 700;
            text-transform: uppercase;
            padding-bottom: 0.8rem;
            padding-left: 1rem;

          }

          p {
            font-size: 0.8rem;
            font-weight: 400;
            position: relative;
            padding-left: 1.5rem;
            color: #000;

            span {
              font-size: 4rem;
              position: absolute;
              top: -25px;
              bottom: 0;
              left: -3px;
            }
          }
        }
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.5rem 3rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.1rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .homePage {
    .banner {
      padding: 5px;
      padding-top: 40px;

      p {
        color: $cta_color;
        font-size: 0.7rem;
      }
    }

    .background-video {
      position: relative;
      width: 100%;
      height: 91vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 1.8px solid white;
        width: 240px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 270px;
        height: 55px;
        font-weight: 700;
        font-size: 15px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 120px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem;

        img {
          width: 45px;
        }
      }


    }

    .Personalized_Report {
      padding: 2rem 0 1rem;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;

        h1 {
          font-size: 1.8rem;
          font-weight: 900;
          line-height: 60px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 2rem;


        .info_box {
          padding: 1.5rem 1.5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.2rem;

          h4 {
            font-size: 1.4rem;
            font-weight: 800;
            line-height: 46px;
            padding-bottom: 2rem;

            p {
              font-size: 1.4rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 6px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 0.8rem;
            font-weight: 400;
            padding-bottom: 1rem;
            line-height: 30px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 0.8rem;
            line-height: 30px;
            padding: 0.7rem 2rem;
            background-color: $cta_color;
            border: none;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          height: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 2rem 0;
      z-index: -2;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;

        h1 {
          font-size: 3rem;
          font-weight: 900;
          line-height: 78.02px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.8rem 2rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        // background-color: $secondary_color !important;
        background-color: $cta_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;
          max-width: 250px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 0.8rem;
              text-align: center;
              margin-top: 1.8rem;
            }

            img {
              width: 100%;
              max-height: 180px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 0.7rem;
              font-weight: 700;
              padding-top: 0.4rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 16px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 2rem 0;
      position: relative;
      z-index: 1;

      .container {
        max-width: 95%;
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 4rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 0.5rem;
        margin-bottom: 1rem;
        max-width: 370px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          padding: 1rem 0.5rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            min-height: 100%;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 0.8rem !important;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.4rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.6rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 1rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.7rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.8rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.7rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 150%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 15px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 1.2rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 2rem;

        h2 {
          font-size: 3rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 4px;
        }
      }

      .image-grid .grid-item {
        height: 40%;
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.5rem 3rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.1rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

//Mobile portrait
@media screen and (max-width: 567px) {
  .homePage {
    .banner {
      padding: 8px;
      padding-top: 35px;
      font-size: 0.7rem;

      marquee {
        padding: 0px;
        margin: 0px;
        font-size: 0.7rem;
      }
    }

    .background-video {
      position: relative;
      width: 100%;
      height: 60vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 1.8rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 1.8px solid white;
        width: 200px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 230px;
        height: 50px;
        font-weight: 700;
        font-size: 13px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 100px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem;

        img {
          width: 45px;
        }
      }


    }

    .Personalized_Report {
      padding: 1.5rem 0 1rem;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 0.8rem;

        h1 {
          font-size: 1.7rem;
          font-weight: 900;
          line-height: 50px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 0.8rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 2rem;



        .info_box {
          padding: 1rem 1rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.2rem;
          max-height: 400px;

          h4 {
            font-size: 1.4rem;
            font-weight: 800;
            line-height: 30px;
            padding-bottom: 1rem;

            p {
              font-size: 1.2rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -5px;
                left: 0%;
                width: 100%;
                height: 3px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 0.75rem;
            font-weight: 400;
            padding-bottom: 0.75rem;
            line-height: 18px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 18px;
            padding: 0.7rem 2rem;
            background-color: $cta_color;
            border: none;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          height: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }

    .our_experts {
      position: relative;
      padding: 2rem 0;
      z-index: -2;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 1rem;

        h1 {
          font-size: 2.5rem;
          font-weight: 900;
          line-height: 60px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.8rem 2rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        // background-color: $secondary_color !important;
        background-color: $cta_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;
          max-width: 250px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 0.8rem;
              text-align: center;
              margin-top: 1.8rem;
            }

            img {
              width: 100%;
              max-height: 160px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 0.7rem;
              font-weight: 700;
              padding-top: 0.4rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 14px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 2rem 0;
      position: relative;
      z-index: 1;

      .container {
        max-width: 95%;
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 3.5rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 0.5rem;
        margin-bottom: 1rem;
        max-width: 370px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          padding: 1rem 0.5rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            min-height: 100%;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 0.8rem !important;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.4rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.6rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 1rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.7rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.8rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.7rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 150%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 15px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 2rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 1.2rem;

        h2 {
          font-size: 2.5rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 4px;
        }
      }

      .image-grid .grid-item {
        height: 30%;
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.6rem 2rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

//Mobile portrait
@media screen and (max-width: 440px) and (max-height: 750px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 60vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 1.8px solid white;
        width: 140px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 0.7rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 200px;
        height: 45px;
        font-weight: 700;
        font-size: 11px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 100px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem;
        margin-bottom: 15px;

        img {
          width: 30px;
        }
      }


    }

    .Personalized_Report {
      padding: 1.2rem 0 1rem;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 0.5rem;

        h1 {
          font-size: 1.4rem;
          font-weight: 900;
          line-height: 35px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 2rem;


        .info_box {
          padding: 0.5rem 0.8rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.2rem;
          max-height: fit-content;

          h4 {
            font-size: 1rem;
            font-weight: 800;
            line-height: 22px;
            padding-bottom: 0.5rem;

            p {
              font-size: 1rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 3px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 0.65rem;
            font-weight: 400;
            padding-bottom: 0.5rem;
            line-height: 16px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 0.65rem;
            line-height: 16px;
            padding: 0.3rem 1.2rem;
            background-color: $cta_color;
            border: none;
            border-radius: 5px;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          height: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 2rem 0;
      z-index: -2;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.2rem;
        margin-bottom: 0.8rem;

        h1 {
          font-size: 2rem;
          font-weight: 900;
          line-height: 50px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.6rem 1.8rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        // background-color: $secondary_color !important;
        background-color: $cta_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;
          max-width: 250px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 0.7rem;
              text-align: center;
              margin-top: 1.8rem;
            }

            img {
              width: 100%;
              max-height: 140px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 0.6rem;
              font-weight: 700;
              padding-top: 0.4rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 14px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 2rem 0;
      position: relative;
      z-index: 1;

      .container {
        max-width: 85%;
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 3rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 0.5rem;
        margin-bottom: 1rem;
        max-width: 320px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          padding: 1rem 0.8rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            min-height: 100%;
            max-height: 160px;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 0.7rem !important;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.3rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.55rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 0.8rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.6rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.75rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.7rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 120%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 15px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 1.5rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 1.2rem;

        h2 {
          font-size: 2rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }

      .review_item {

        margin-bottom: 2rem;

        .box {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 0.2rem;
          background-color: #fff;
          padding: 0.5rem;
          border-radius: 10px;

          h3 {
            font-size: 0.75rem;
            font-weight: 700;
            text-transform: uppercase;
            padding-bottom: 0.8rem;
            padding-left: 1rem;

          }

          p {
            font-size: 0.65rem;
            font-weight: 400;
            position: relative;
            padding-left: 1.5rem;
            color: #000;

            span {
              font-size: 4rem;
              position: absolute;
              top: -25px;
              bottom: 0;
              left: -3px;
            }
          }
        }
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.6rem 1rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 0.85rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

//Mobile portrait
@media screen and (max-width: 440px)and (min-height:751px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 50vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 1.8px solid white;
        width: 140px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 0.7rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 200px;
        height: 45px;
        font-weight: 700;
        font-size: 11px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 100px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 5rem;
        margin-bottom: 15px;

        img {
          width: 30px;
        }
      }


    }

    .Personalized_Report {
      padding: 1.2rem 0 1rem;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.5rem;
        margin-bottom: 0.5rem;

        h1 {
          font-size: 1.4rem;
          font-weight: 900;
          line-height: 35px;
          text-transform: uppercase;
          color: $white_text_color;

          span {
            color: $cta_color;
            background-color: $white_text_color;
            position: relative;
            padding: 0.2rem 1rem;
            border-radius: 10px;
          }
        }
      }

      .box {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px #0000004f;
        margin-bottom: 2rem;


        .info_box {
          padding: 0.5rem 0.8rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.2rem;
          max-height: fit-content;

          h4 {
            font-size: 1rem;
            font-weight: 800;
            line-height: 22px;
            padding-bottom: 0.5rem;

            p {
              font-size: 1rem;
              font-weight: 800;
              display: inline;
              color: #000;
            }

            span {
              color: #000;
              position: relative;

              &::after {
                background-color: $cta_color;
                position: absolute;
                // top: 0;
                bottom: -10px;
                left: 0%;
                width: 100%;
                height: 3px;
                content: "";
                border-radius: 10px;
              }
            }

            .darkGrrenSpan {
              color: $cta_color;
            }

          }

          p {
            font-size: 0.65rem;
            font-weight: 400;
            padding-bottom: 0.5rem;
            line-height: 16px;
            color: $black_text_color;
          }

          >button {
            color: $white_text_color;
            font-weight: 600;
            font-size: 0.65rem;
            line-height: 16px;
            padding: 0.3rem 1.2rem;
            background-color: $cta_color;
            border: none;
            border-radius: 5px;
          }

          >button:hover {
            border: 2px solid $cta_color;
            color: $cta_color;
            background-color: $white_text_color;


          }

        }

        img {
          width: 100%;
          height: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

        }
      }
    }

    .our_experts {
      position: relative;
      padding: 2rem 0;
      z-index: -2;

      .container {
        max-width: 95%;
      }

      .heading {
        text-align: left;
        padding-bottom: 1.2rem;
        margin-bottom: 0.8rem;

        h1 {
          font-size: 2rem;
          font-weight: 900;
          line-height: 50px;
          text-transform: uppercase;
          border-radius: 10px;
          color: #fff;

          span {
            background-color: $white_text_color;
            color: $cta_color;
            margin-right: 1.5rem;
            border-radius: 10px;
            padding: 0.6rem 1.8rem;
          }
        }
      }

      .hero_color {
        position: absolute;
        content: "";
        // background-color: $secondary_color !important;
        background-color: $cta_color !important;
        width: 100%;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .experts_conatiner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;

        .experts_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          background-color: white;
          justify-content: space-between;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;
          max-width: 250px;

          .experts_top_cont {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h6 {
              color: #000;
              font-weight: 800;
              font-size: 0.7rem;
              text-align: center;
              margin-top: 1.8rem;
            }

            img {
              width: 100%;
              max-height: 140px;
            }
          }

          .experts_bottom_cont {
            background-color: $cta_color;
            width: 100%;
            text-align: center;
            border-radius: 0px 0px 10px 10px;

            p:nth-child(1) {
              color: #FFFFFF;
              font-size: 0.6rem;
              font-weight: 700;
              padding-top: 0.4rem;
            }

            p:nth-child(2) {
              color: $cta_color;
              background-color: #FFFFFF;
              font-size: 14px;
              font-weight: 800;
            }
          }
        }
      }

    }

    .homeBlog {
      padding: 2rem 0;
      position: relative;
      z-index: 1;

      .container {
        max-width: 85%;
      }

      .hero_color {
        position: absolute;
        content: "";
        background-color: $secondary_color ;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0;
        right: 0;
        // bottom:50%;
        z-index: -1;
      }

      .heading {
        padding-bottom: 20px;

        h1 {
          font-size: 3rem;
          font-weight: 900;
          text-transform: uppercase;
          line-height: 90%;
          text-align: start;
          color: $cta_color !important;
        }
      }

      .box {
        padding: 0.5rem;
        margin-bottom: 1rem;
        max-width: 320px;
        position: relative;
        cursor: pointer;

        .item {
          background-color: #fff;
          padding: 1rem 0.8rem;
          box-shadow: 0 0 15px #00000031;
          border-radius: 10px;


          img {
            width: 100%;
            min-height: 100%;
            max-height: 160px;
            border-radius: 10px;
          }

          .blg_info {
            padding: 1rem 0.5rem;

            h5 {
              font-size: 0.7rem !important;
              font-weight: 400;
              color: $black_text_color;
            }

            ul {
              margin: 0px;
              padding: 0px;
              display: grid;
              grid-template-rows: auto auto;
              grid-template-columns: 1fr 1fr;
              text-align: center;
              column-gap: 0.5rem;
              row-gap: 0.5rem;
              margin: 1rem 0;

              li {
                list-style-type: none;
                border: 1px solid #0000006e;
                padding: 0.3rem 0.3rem;
                margin: 0 0.1rem;
                border-radius: 50px;
                font-size: 0.55rem;
                font-weight: 400;
                color: $black_text_color;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                  background-color: rgba(199, 199, 199, 0.461);
                }
              }
            }

            h2 {
              font-size: 0.8rem;
              font-weight: 700;
              color: $cta_color;
              text-transform: uppercase;

            }

            p {
              font-size: 0.6rem;
              font-weight: 400;
              color: $black_text_color;
              line-height: auto;
            }

            h3 {
              font-size: 0.75rem;
              font-weight: 400;
              color: $cta_color;
              text-transform: uppercase;
            }

            h5 {
              font-size: 0.7rem;
              font-weight: 500;
              color: $black_text_color;
              line-height: 120%;
            }
          }
        }

        .readMore {
          position: absolute;
          z-index: 9999999999999;
          bottom: -2rem;
          left: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: none;

          svg {
            color: $cta_color;
          }

          p {
            text-align: center;
            color: $white_text_color;
            font-size: 15px;
          }
        }

        &:hover {
          .readMore {
            display: flex;
          }
        }

      }

      // .pagination {
      //   text-align: center;
      //   color: $white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   button {
      //     margin-right: 10px;
      //     border: none;
      //     background: transparent;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;

      //     i {
      //       font-size: 20px;
      //     }
      //   }
      // }
    }

    .review {
      padding: 1.5rem 0;
      position: relative;

      .heading {
        text-align: center;
        padding-bottom: 1.2rem;

        h2 {
          font-size: 2rem;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }

      .review_item {

        margin-bottom: 2rem;

        .box {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 0.2rem;
          background-color: #fff;
          padding: 0.5rem;
          border-radius: 10px;

          h3 {
            font-size: 0.75rem;
            font-weight: 700;
            text-transform: uppercase;
            padding-bottom: 0.8rem;
            padding-left: 1rem;

          }

          p {
            font-size: 0.65rem;
            font-weight: 400;
            position: relative;
            padding-left: 1.5rem;
            color: #000;

            span {
              font-size: 4rem;
              position: absolute;
              top: -25px;
              bottom: 0;
              left: -3px;
            }
          }
        }
      }

      .button {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          padding: 0.6rem 1rem;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 0.85rem;
          background-color: $cta_color;
          color: $white_text_color;
          border: 2px solid $white_text_color;
        }

        button:hover {
          background-color: $white_text_color;
          color: $cta_color;
          border: 2px solid $cta_color;
        }
      }
    }
  }
}

//Mobile landscaps
@media screen and (min-width:650px)and (max-height:440px) {
  .homePage {
    .background-video {
      position: relative;
      width: 100%;
      height: 80vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;

      }


      .text-overlay {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

      }

      .info {
        position: relative;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        z-index: 2;
      }

      .info h1 {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 900;
        text-align: center;
      }

      .horizontal_line {
        border: 1.8px solid white;
        width: 180px;
        text-align: center;
      }

      .info p {
        text-align: center;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: auto;
        color: $white;
        max-width: 70%;
        padding: 0px;
        margin: 0px;
      }


      .ctaButton {
        width: 200px;
        height: 45px;
        font-weight: 700;
        font-size: 12px;
        background-color: $cta_color;
        color: $white;
        border: 1px solid $white;
        padding: 0.5rem 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .ctaButton:hover {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .ctaButton img {
        width: 100px;
        height: 90px;
      }

      .ctaButton.ctaClicked {
        background-color: $white !important;
        color: $cta_color !important;
      }

      .arrow_dwn {
        display: block;
        text-align: center;
        background-color: transparent !important;
        border: none;
        margin-top: 2rem;
        margin-bottom: 15px;

        img {
          width: 30px;
        }
      }


    }
  }


}