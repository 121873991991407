@import '../../../SCSS/variables';
:root{
    --border-radius:10px;
   
}

#membership-outer {
  width: 100%;
  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin: 2rem 0;
    h1{
      font-size: 2.2rem;
      font-weight: 600;
      margin-bottom: 0rem;
    }
}

#athlet{
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap; 
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  font-weight: 500;
  gap: 1rem;
  padding: 0 1rem;
  .box-2{
    position: relative;
    display: block;
    width: 260px; /* Fixed width for consistency */
    height: 360px;
    font-weight: 500;
    border: 1px solid $cta_color;
    border-radius: 5px;
    padding: 20px; /* Add inner spacing */
    box-shadow: 1px 1px 2px $cta_color;
    overflow: visible; /* Ensure no clipping */
    transition: box-shadow 0.3s ease; /* Smooth hover effect */
    margin-top: 10px;
    margin-bottom: 20px;
    &:hover{
      box-shadow: 3px 3px 5px $cta_color;
    }
   
    .plan{
        font-size: 24px;
        font-weight: 600;
        @media screen and (max-width:500px) {
          font-size: 25px;
          font-weight: 600;
        }
      }
      #but-content{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        .button{
          background-color: $cta_color;
          color: white;
          font-size: 15px;
          font-weight: 400;
          padding: 7px 10px;/* Adjusted for better spacing */
          border-radius: 10px;
          width: 170px;
          border: none; /* Remove default border */
          display: inline-flex; /* Align content properly */
          align-items: center;
          // justify-content: center;
          cursor: pointer; 
          position: absolute;
          margin-top: 10px;
          bottom: -40px;
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width:500px){
            padding: 5px ;
            width: 100px;
            margin-top: 5px;
            margin-bottom: 10px;
          }
          span:first-child{
              position: relative;
              font-weight: 600;
          }
           
      }
      }
  
      .features{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        line-height: 1.6rem;
        padding: 3px;
        
        gap: 20px;
        p{
          margin-top: 5px;
          margin-bottom: 0px;
        }
        ul{
          margin-bottom: 0px;
          margin: 0;
          padding: 0;
          li{
            font-size: 13px;
          }
        }
        @media screen and (max-width:500px) {
          line-height: 1rem;
        }
      }
}
}
    
}