@import "../../../SCSS/variables";

.CorrectApproach {
    overflow-y: scroll;
    scroll-behavior: smooth;
    max-height: 95vh;


    .container {
        max-width: 90%;

        margin: l;

        .heading {
            margin: 0.5rem 1rem;

            h1 {
                font-size: 56px;
                font-weight: 800;
                text-transform: uppercase;
                color: $cta_color;
            }

            p {
                color: black;
                font-size: 24px;
                font-weight: 500;
                padding-inline: 5px;
            }
        }

        .approach_cont {
            padding: 1rem;
            padding-block: 0px;

            ol {
                list-style-type: none !important;
                margin: 0px;
                padding: 0px;

                .approach_box_con {
                    .approach_box {
                        margin-bottom: 2rem;
                        display: flex;
                        width: 100%;

                        .number {
                            font-size: 56px;
                            font-weight: 700;
                            color: $cta_color;
                            margin-right: 1rem;

                        }

                        li {
                            width: 100%;
                            margin-top: 1rem;

                            .videoContainer {
                                height: 50vh;
                                width: 100%;

                                .loader_error-container {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 100%;
                                    width: 100%;

                                    p {
                                        color: red;
                                        font-size: 1.5rem;
                                    }
                                }

                                video {
                                    width: 100%;
                                    height: 100%;
                                    // object-fit: fill;
                                    border-radius: 10px;
                                }
                            }

                            .imageContainer {
                                height: 50vh;
                                width: 100%;
                                background-size: cover;
                                background-position: center;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                border-radius: 10px;

                                >div {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;

                                    i {
                                        font-size: 4rem;
                                        color: white;
                                    }

                                    span {
                                        font-size: 2rem;
                                        color: white;
                                    }
                                }

                                .loader_error-container {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 100%;
                                    width: 100%;

                                    p {
                                        color: red;
                                        font-size: 1.5rem;
                                    }
                                }

                            }

                            h4 {
                                text-transform: uppercase;
                                background-color: $cta_color;
                                color: $white_text_color;
                                font-size: 24px;
                                font-weight: 700;
                                padding: 1rem 2rem;
                                margin-block: 0.6rem;
                                border-radius: 10px;
                            }

                            .observation {
                                margin: 0px;
                                padding-left: 5px;
                                color: "#00000";
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

        .errorContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;

        }

        .button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            margin-bottom: 1rem;

            button {
                margin-top: 1rem;
                padding: 0.5rem;
                width: 323px;
                border: 2px solid $cta_color;
                background-color: transparent;
                font-size: 24px;
                color: $cta_color;
                font-weight: 500;

                &:hover {
                    background-color: $cta_color;
                    color: $white;
                }

                i {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    &::-webkit-scrollbar {
        width: 0px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #fff;
        border-radius: 5px;
    }

    .shadow::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

@media screen and (max-width: 1399px) {
    .CorrectApproach {
        .container {

            .heading {
                margin: 0.5rem 1rem;

                h1 {
                    font-size: 48px;
                    font-weight: 800;
                    text-transform: uppercase;
                    color: $cta_color;
                }

                p {
                    color: black;
                    font-size: 24px;
                    font-weight: 500;
                    padding-inline: 5px;
                }
            }

            .approach_cont {
                padding: 1rem;
                padding-block: 0px;

                ol {
                    list-style-type: none !important;
                    margin: 0px;
                    padding: 0px;

                    .approach_box_con {
                        .approach_box {
                            margin-bottom: 2rem;
                            display: flex;
                            width: 100%;

                            .number {
                                font-size: 42px;
                                font-weight: 700;
                                color: $cta_color;
                                margin-right: 1rem;

                            }

                            li {
                                width: 100%;
                                margin-top: 1rem;

                                .videoContainer {
                                    height: 50vh;
                                    width: 100%;

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                    video {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .imageContainer {
                                    height: 50vh;
                                    width: 100%;
                                    background-size: cover;
                                    background-position: center;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 10px;

                                    >div {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;

                                        i {
                                            font-size: 3.5rem;
                                            color: white;
                                        }

                                        span {
                                            font-size: 1.5rem;
                                            color: white;
                                        }
                                    }

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                }

                                h4 {
                                    text-transform: uppercase;
                                    background-color: $cta_color;
                                    color: $white_text_color;
                                    font-size: 20px;
                                    font-weight: 700;
                                    padding: 0.6rem 1.5rem;
                                    margin-block: 0.6rem;
                                    border-radius: 10px;
                                }

                                .observation {
                                    margin: 0px;
                                    padding-left: 5px;
                                    color: "#00000";
                                    font-weight: 500;
                                    font-size: 14px;
                                }

                            }
                        }
                    }


                }

            }

            .errorContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;

            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                margin-bottom: 1rem;

                button {
                    margin-top: 1rem;
                    padding: 0.4rem;
                    width: 280px;
                    background-color: transparent;
                    font-size: 20px;
                    font-weight: 500;

                    &:hover {
                        color: $white;
                    }

                    i {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1199px) {
    .CorrectApproach {
        .container {
            .heading {
                margin: 0.5rem 1rem;

                h1 {
                    font-size: 42px;
                    font-weight: 800;
                    text-transform: uppercase;
                    color: $cta_color;
                }

                p {
                    color: black;
                    font-size: 24px;
                    font-weight: 500;
                    padding-inline: 5px;
                }
            }

            .approach_cont {
                padding: 1rem;
                padding-block: 0px;

                ol {
                    list-style-type: none !important;
                    margin: 0px;
                    padding: 0px;

                    .approach_box_con {
                        .approach_box {
                            margin-bottom: 2rem;
                            display: flex;
                            width: 100%;

                            .number {
                                font-size: 32px;
                                font-weight: 700;
                                color: $cta_color;
                                margin-right: 1rem;

                            }

                            li {
                                width: 100%;
                                margin-top: 1rem;

                                .videoContainer {
                                    height: 50vh;
                                    width: 100%;

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                    video {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .imageContainer {
                                    height: 50vh;
                                    width: 100%;
                                    background-size: cover;
                                    background-position: center;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 10px;

                                    >div {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;

                                        i {
                                            font-size: 3rem;
                                            color: white;
                                        }

                                        span {
                                            font-size: 1.2rem;
                                            color: white;
                                        }
                                    }

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                }

                                h4 {
                                    text-transform: uppercase;
                                    background-color: $cta_color;
                                    color: $white_text_color;
                                    font-size: 18px;
                                    font-weight: 700;
                                    padding: 0.8rem 1.5rem;
                                    margin-block: 0.6rem;
                                    border-radius: 10px;
                                }

                                .observation {
                                    margin: 0px;
                                    padding-left: 5px;
                                    color: "#00000";
                                    font-weight: 500;
                                    font-size: 14px;
                                }

                            }
                        }
                    }


                }

            }

            .errorContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;

            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                margin-bottom: 1rem;

                button {
                    margin-top: 1rem;
                    padding: 0.4rem;
                    width: 280px;
                    background-color: transparent;
                    font-size: 20px;
                    font-weight: 500;

                    &:hover {
                        color: $white;
                    }

                    i {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px)and (min-height:750px) {
    .CorrectApproach {
        .container {
            .heading {
                margin: 0.5rem 1rem;

                h1 {
                    font-size: 48px;
                    font-weight: 800;
                    text-transform: uppercase;
                    color: $cta_color;
                }

                p {
                    color: black;
                    font-size: 24px;
                    font-weight: 500;
                    padding-inline: 5px;
                }
            }

            .approach_cont {
                padding: 1rem;
                padding-block: 0px;

                ol {
                    list-style-type: none !important;
                    margin: 0px;
                    padding: 0px;

                    .approach_box_con {
                        .approach_box {
                            margin-bottom: 2rem;
                            display: flex;
                            width: 100%;

                            .number {
                                font-size: 32px;
                                font-weight: 700;
                                color: $cta_color;
                                margin-right: 1rem;

                            }

                            li {
                                width: 100%;
                                margin-top: 1rem;

                                .videoContainer {
                                    height: 25vh;
                                    width: 100%;

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                    video {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .imageContainer {
                                    height: 25vh;
                                    width: 100%;
                                    background-size: cover;
                                    background-position: center;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 10px;

                                    >div {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;

                                        i {
                                            font-size: 3rem;
                                            color: white;
                                        }

                                        span {
                                            font-size: 1.2rem;
                                            color: white;
                                        }
                                    }

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                }

                                h4 {
                                    text-transform: uppercase;
                                    background-color: $cta_color;
                                    color: $white_text_color;
                                    font-size: 18px;
                                    font-weight: 700;
                                    padding: 0.8rem 1.5rem;
                                    margin-block: 0.6rem;
                                    border-radius: 10px;
                                }

                                .observation {
                                    margin: 0px;
                                    padding-left: 5px;
                                    color: "#00000";
                                    font-weight: 500;
                                    font-size: 14px;
                                }

                            }
                        }
                    }


                }

            }

            .errorContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;

            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                margin-bottom: 1rem;

                button {
                    margin-top: 1rem;
                    padding: 0.4rem;
                    width: 280px;
                    background-color: transparent;
                    font-size: 20px;
                    font-weight: 500;

                    &:hover {
                        color: $white;
                    }

                    i {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .CorrectApproach {
        .container {
            .heading {
                margin: 0.5rem 1rem;

                h1 {
                    font-size: 42px;
                    font-weight: 800;
                    text-transform: uppercase;
                    color: $cta_color;
                }

                p {
                    color: black;
                    font-size: 24px;
                    font-weight: 500;
                    padding-inline: 5px;
                }
            }

            .approach_cont {
                padding: 1rem;
                padding-block: 0px;

                ol {
                    list-style-type: none !important;
                    margin: 0px;
                    padding: 0px;

                    .approach_box_con {
                        .approach_box {
                            margin-bottom: 2rem;
                            display: flex;
                            width: 100%;

                            .number {
                                font-size: 26px;
                                font-weight: 700;
                                color: $cta_color;
                                margin-right: 1rem;

                            }

                            li {
                                width: 100%;
                                margin-top: 1rem;

                                .videoContainer {
                                    height: 50vh;
                                    width: 100%;

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                    video {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .imageContainer {
                                    height: 50vh;
                                    width: 100%;
                                    background-size: cover;
                                    background-position: center;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 10px;

                                    >div {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;

                                        i {
                                            font-size: 2.8rem;
                                            color: white;
                                        }

                                        span {
                                            font-size: 1rem;
                                            color: white;
                                        }
                                    }

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                }

                                h4 {
                                    text-transform: uppercase;
                                    background-color: $cta_color;
                                    color: $white_text_color;
                                    font-size: 16px;
                                    font-weight: 700;
                                    padding: 0.8rem 1.5rem;
                                    margin-block: 0.6rem;
                                    border-radius: 10px;
                                }

                                .observation {
                                    margin: 0px;
                                    padding-left: 5px;
                                    color: "#00000";
                                    font-weight: 500;
                                    font-size: 13px;
                                }

                            }
                        }
                    }


                }

            }

            .errorContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;

            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                margin-bottom: 1rem;

                button {
                    margin-top: 1rem;
                    padding: 0.4rem;
                    width: 220px;
                    background-color: transparent;
                    font-size: 16px;
                    font-weight: 500;

                    &:hover {
                        color: $white;
                    }

                    i {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) and (min-height:750px) {
    .CorrectApproach {
        .container {
            .heading {
                margin: 2rem 1rem;

                h1 {
                    font-size: 42px;
                    font-weight: 800;
                    text-transform: uppercase;
                    color: $cta_color;
                }

                p {
                    color: black;
                    font-size: 24px;
                    font-weight: 500;
                    padding-inline: 5px;
                }
            }

            .approach_cont {
                padding: 1rem;
                padding-block: 0px;

                ol {
                    list-style-type: none !important;
                    margin: 0px;
                    padding: 0px;

                    .approach_box_con {
                        .approach_box {
                            margin-bottom: 2rem;
                            display: flex;
                            width: 100%;

                            .number {
                                font-size: 26px;
                                font-weight: 700;
                                color: $cta_color;
                                margin-right: 1rem;

                            }

                            li {
                                width: 100%;
                                margin-top: 1rem;

                                .videoContainer {
                                    height: 25vh;
                                    width: 100%;

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                    video {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .imageContainer {
                                    height: 25vh;
                                    width: 100%;
                                    background-size: cover;
                                    background-position: center;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 10px;

                                    >div {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;

                                        i {
                                            font-size: 2.8rem;
                                            color: white;
                                        }

                                        span {
                                            font-size: 1rem;
                                            color: white;
                                        }
                                    }

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                }

                                h4 {
                                    text-transform: uppercase;
                                    background-color: $cta_color;
                                    color: $white_text_color;
                                    font-size: 16px;
                                    font-weight: 700;
                                    padding: 0.8rem 1.5rem;
                                    margin-block: 0.6rem;
                                    border-radius: 10px;
                                }

                                .observation {
                                    margin: 0px;
                                    padding-left: 5px;
                                    color: "#00000";
                                    font-weight: 500;
                                    font-size: 13px;
                                }

                            }
                        }
                    }


                }

            }

            .errorContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;

            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                margin-bottom: 1rem;

                button {
                    margin-top: 1rem;
                    padding: 0.4rem;
                    width: 220px;
                    background-color: transparent;
                    font-size: 16px;
                    font-weight: 500;

                    &:hover {
                        color: $white;
                    }

                    i {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .CorrectApproach {
        margin-top: 3rem;

        .container {
            max-width: 100%;

            .heading {
                margin: 0.5rem 1rem;

                h1 {
                    font-size: 36px;
                    font-weight: 800;
                    text-transform: uppercase;
                    color: $cta_color;
                }

                p {
                    color: black;
                    font-size: 24px;
                    font-weight: 500;
                    padding-inline: 5px;
                }
            }

            .approach_cont {
                padding: 1rem;
                padding-block: 0px;

                ol {
                    list-style-type: none !important;
                    margin: 0px;
                    padding: 0px;

                    .approach_box_con {
                        .approach_box {
                            margin-bottom: 2rem;
                            display: flex;
                            width: 100%;

                            .number {
                                font-size: 26px;
                                font-weight: 700;
                                color: $cta_color;
                                margin-right: 1rem;

                            }

                            li {
                                width: 100%;
                                margin-top: 0.5rem;

                                .videoContainer {
                                    height: 42vh;
                                    width: 100%;

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                    video {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .imageContainer {
                                    height: 42vh;
                                    width: 100%;
                                    background-size: cover;
                                    background-position: center;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 10px;

                                    >div {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;

                                        i {
                                            font-size: 2.8rem;
                                            color: white;
                                        }

                                        span {
                                            font-size: 1rem;
                                            color: white;
                                        }
                                    }

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                }

                                h4 {
                                    text-transform: uppercase;
                                    background-color: $cta_color;
                                    color: $white_text_color;
                                    font-size: 14px;
                                    font-weight: 700;
                                    padding: 0.5rem 1.5rem;
                                    margin-block: 0.6rem;
                                    border-radius: 10px;
                                }

                                .observation {
                                    margin: 0px;
                                    padding-left: 5px;
                                    color: "#00000";
                                    font-weight: 500;
                                    font-size: 13px;
                                }

                            }
                        }
                    }


                }

            }

            .errorContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;

            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                margin-bottom: 1rem;

                button {
                    margin-top: 0.5rem;
                    padding: 0.4rem;
                    width: 160px;
                    background-color: transparent;
                    font-size: 14px;
                    font-weight: 500;

                    &:hover {
                        color: $white;
                    }

                    i {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .CorrectApproach {
        margin-top: 1rem;

        .container {
            max-width: 100%;

            .heading {
                margin: 0.5rem 1rem;

                h1 {
                    font-size: 26px;
                    font-weight: 800;
                    text-transform: uppercase;
                    color: $cta_color;
                }

                p {
                    color: black;
                    font-size: 24px;
                    font-weight: 500;
                    padding-inline: 5px;
                }
            }

            .approach_cont {
                padding: 1rem;
                padding-block: 0px;

                ol {
                    list-style-type: none !important;
                    margin: 0px;
                    padding: 0px;

                    .approach_box_con {
                        .approach_box {
                            margin-bottom: 2rem;
                            display: flex;
                            width: 100%;

                            .number {
                                font-size: 22px;
                                font-weight: 700;
                                color: $cta_color;
                                margin-right: 1rem;

                            }

                            li {
                                width: 100%;
                                margin-top: 0.5rem;

                                .videoContainer {
                                    height: 20vh;
                                    width: 100%;

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                    video {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .imageContainer {
                                    height: 20vh;
                                    width: 100%;
                                    background-size: cover;
                                    background-position: center;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 10px;

                                    >div {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;

                                        i {
                                            font-size: 2.8rem;
                                            color: white;
                                        }

                                        span {
                                            font-size: 1rem;
                                            color: white;
                                        }
                                    }

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 1rem;
                                        }
                                    }

                                }

                                h4 {
                                    text-transform: uppercase;
                                    background-color: $cta_color;
                                    color: $white_text_color;
                                    font-size: 14px;
                                    font-weight: 700;
                                    padding: 0.5rem 0.8rem;
                                    margin-block: 0.6rem;
                                    border-radius: 10px;
                                }

                                .observation {
                                    margin: 0px;
                                    padding-left: 10px;
                                    color: "#00000";
                                    font-weight: 500;
                                    font-size: 11px;
                                }

                            }
                        }
                    }


                }

            }

            .errorContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;

            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                margin-bottom: 1rem;

                button {
                    margin-top: 0.5rem;
                    padding: 0.4rem;
                    width: 100px;
                    background-color: transparent;
                    font-size: 12px;
                    font-weight: 500;

                    &:hover {
                        color: $white;
                    }

                    i {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .CorrectApproach {
        margin-top: 1rem;
        max-height: 110vh;

        .container {
            max-width: 100%;

            .heading {
                margin: 0.5rem 1rem;

                h1 {
                    font-size: 20px;
                    font-weight: 800;
                    text-transform: uppercase;
                    color: $cta_color;
                }

                p {
                    color: black;
                    font-size: 22px;
                    font-weight: 500;
                    padding-inline: 5px;
                }
            }

            .approach_cont {
                padding: 0rem;
                padding-block: 0px;

                ol {
                    list-style-type: none !important;
                    margin: 0px;
                    padding: 0px;

                    .approach_box_con {
                        .approach_box {
                            margin-bottom: 1rem;
                            display: flex;
                            width: 100%;

                            .number {
                                font-size: 16px;
                                font-weight: 700;
                                color: $cta_color;
                                margin-right: 1rem;

                            }

                            li {
                                width: 100%;
                                margin-top: 0.5rem;

                                .videoContainer {
                                    height: 20vh;
                                    width: 100%;

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 12px;
                                        }
                                    }

                                    video {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .imageContainer {
                                    height: 20vh;
                                    width: 100%;
                                    background-size: cover;
                                    background-position: center;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 10px;

                                    >div {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;

                                        i {
                                            font-size: 2.8rem;
                                            color: white;
                                        }

                                        span {
                                            font-size: 1rem;
                                            color: white;
                                        }
                                    }

                                    .loader_error-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        width: 100%;

                                        p {
                                            color: red;
                                            font-size: 12px;
                                        }
                                    }

                                }

                                h4 {
                                    text-transform: uppercase;
                                    background-color: $cta_color;
                                    color: $white_text_color;
                                    font-size: 11px;
                                    font-weight: 700;
                                    padding: 0.5rem 0.5rem;
                                    margin-block: 0.6rem;
                                    border-radius: 10px;
                                }

                                .observation {
                                    margin: 0px;
                                    padding-left: 10px;
                                    color: "#00000";
                                    font-weight: 500;
                                    font-size: 9px;
                                }

                            }
                        }
                    }


                }

            }

            .errorContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;

            }

            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                margin-bottom: 1rem;

                button {
                    margin-top: 0.5rem;
                    padding: 0.3rem;
                    width: 80px;
                    background-color: transparent;
                    font-size: 10px;
                    font-weight: 500;

                    &:hover {
                        color: $white;
                    }

                    i {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}