@import "../../SCSS/variables";

.wrp {
    display: grid;
    grid-template-columns: 1fr 4fr;
    margin-top: 1rem;

    .sidenav {
        padding: 1rem;
        width: 250px;
        border-right: 1px solid $darkgreen;

        ul {
            list-style-type: none;
            padding: 0px;
            margin: 0px;
            width: 100%;


            li {
                // margin-block: 2rem;
                width: 100%;
                margin-bottom: 2rem;

                a {
                    text-decoration: none;
                    color: inherit;
                    padding: 8px 10px;
                    font-weight: 500;
                    font-size: 16px;

                    i {
                        margin-right: 10px;
                    }

                    &.active {
                        background-color: $mediumgreen;
                        border: 2px solid $darkgreen;
                        border-radius: 8px;
                    }
                }
            }
        }

        .guideline_video_wrap {
            border-top: 1px solid rgb(211, 208, 208);
            padding-block: 1rem;

            .content_wrap {
                .header {
                    background-color: $inactive_text_color;
                    padding-block: 10px;
                    color: $white;
                    font-weight: 500;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;

                    h2 {
                        font-size: 1rem;
                        text-align: center;
                    }
                }

                .content {
                    padding: 1rem;
                    border-left: 1px solid $inactive_text_color;
                    border-bottom: 1px solid $inactive_text_color;
                    border-right: 1px solid $inactive_text_color;

                    .guidelines_wrp {
                        padding-top: 1rem;
                        display: flex;
                        flex-direction: column;
                        row-gap: 2px;

                        .guidelines_list {
                            display: flex;
                            column-gap: 8px;
                            row-gap: 2px;

                            p {
                                font-size: 12px;
                                text-align: left;
                                font-weight: 500;
                                color: #000000;
                                text-align: justify;
                            }
                        }
                    }

                    .bottom_wrap {
                        margin-top: 2rem;

                        p {
                            text-align: center;
                            font-weight: 500;

                            a {
                                color: $primary_color;
                                font-weight: 600;
                            }
                        }
                    }
                }




            }
        }
    }

    .content {
        .toggle_button {
            display: none;

            button {}
        }
    }
}

@media screen and (max-width: 768px) {
    .wrp {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 1rem;
        position: relative;

        .open {
            transform: translate(0%) !important;
            transition: 0.8s;
        }

        .sidenav {
            padding: 1rem;
            width: 250px;
            border-right: 1px solid $darkgreen;
            transition: 0.8s;
            position: absolute;
            transform: translateX(-100%);
            background-color: #fff;
            z-index: 3;
            bottom: 0;
            // top: 0;
            height: 100%;

            ul {
                list-style-type: none;
                padding: 0px;
                margin: 0px;
                width: 100%;
                margin-top: 1rem;


                li {
                    // margin-block: 2rem;
                    width: 100%;
                    margin-bottom: 2rem;

                    a {
                        text-decoration: none;
                        color: inherit;
                        padding: 8px 10px;
                        font-weight: 500;
                        font-size: 16px;

                        i {
                            margin-right: 10px;
                        }

                        &.active {
                            background-color: $mediumgreen;
                            border: 2px solid $darkgreen;
                            border-radius: 8px;
                        }
                    }
                }
            }

            .guideline_video_wrap {
                border-top: 1px solid rgb(211, 208, 208);
                padding-block: 1rem;

                .content_wrap {
                    .header {
                        background-color: $inactive_text_color;
                        padding-block: 10px;
                        color: $white;
                        font-weight: 500;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;

                        h2 {
                            font-size: 1rem;
                            text-align: center;
                        }
                    }

                    .content {
                        padding: 1rem;
                        border-left: 1px solid $inactive_text_color;
                        border-bottom: 1px solid $inactive_text_color;
                        border-right: 1px solid $inactive_text_color;

                        .guidelines_wrp {
                            padding-top: 1rem;
                            display: flex;
                            flex-direction: column;
                            row-gap: 2px;

                            .guidelines_list {
                                display: flex;
                                column-gap: 8px;
                                row-gap: 2px;

                                p {
                                    font-size: 12px;
                                    text-align: left;
                                    font-weight: 500;
                                    color: #000000;
                                    text-align: justify;
                                }
                            }
                        }

                        .bottom_wrap {
                            margin-top: 2rem;

                            p {
                                text-align: center;
                                font-weight: 500;

                                a {
                                    color: $primary_color;
                                    font-weight: 600;
                                }
                            }
                        }
                    }




                }
            }
        }
    }

    .content {
        .toggle_button {
            display: block !important;
            display: flex !important;
            justify-content: flex-end;

            button {
                position: relative;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border: none;
                background-color: transparent;
                z-index: 4;

                &.menuicons span {
                    transform: translateX(60px);
                }

                &.menuicons span:nth-child(1) {
                    transform: translateY(0px);
                    width: 20px;
                    transform: rotate(50deg);
                }

                &.menuicons span:nth-child(2) {
                    transform: translateY(0px);
                    width: 20px;
                    transform: rotate(128deg);
                }

                & span {
                    position: absolute;
                    width: 30px;
                    height: 2.5px;
                    background-color: $primary_color;
                    border-radius: 4px;
                    transition: 0.5s;
                    right: 15px;

                    &:nth-child(1) {
                        transform: translateY(-8px);
                        width: 20px;
                        left: 15px;
                    }

                    &:nth-child(2) {
                        transform: translateY(8px);
                        width: 20px;
                        left: 15px;
                    }
                }
            }

            .menu-one {
                display: none;
            }
        }
    }
}