@import "../../SCSS/variables";

.videoContainer{
position: relative; 
}
.playerContainer {
    background-color: white;
    // padding: 0.3rem;
    width: 100%;
    position: relative;

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
    }
    
    .poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        opacity: 0.8; /* Slight opacity for poster */
        z-index: 0;
    }
    
    .spinner {
        border: 4px solid rgba(255, 255, 255, 0.3);
        border-top: 4px solid white;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
        z-index: 1;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    video {
        width: 100%;
        // height: 65vh;
    }

}
.buttonContainer {
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
    padding-block: 1rem;

    button {
        padding: 1rem 2rem;
        font-size: 18px;
        font-weight: 600;
        border: none;
        outline: none;
        border-radius: 5px;
    }

    .buttonOutline {
        border: 1px solid $cta_color;
        color: $cta_color;
        background-color: white;
    }

    

    .buttonPrimary {
        background-color: $cta_color;
        color: white;
    }
}