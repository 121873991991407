@import "../../SCSS/variables";

.TermsService {
    .bnnr {
        background-color: $primary_color;
        padding: 2rem;

        .container .heading {
            font-size: 6rem;
            font-weight: 800;
            text-transform: uppercase;
            color: $white;
            line-height: 90%;
        }
    }

    .container_terms_service {
        margin: auto;
        margin-block: 3rem;
    }

    .date {
        margin-block: 2rem;
        margin-bottom: 0.5rem;
        font-size: 1.25rem;
        font-weight: 500;
        color: $cta_color;
        padding-inline: 5px;
    }

    h1 {
        font-size: 2rem;
        margin: 0px;
        font-weight: 800;
        color: $cta_color;
    }

    .extra-bottom-mar {
        margin-bottom: 3rem;
    }

    h2 {
        font-size: 24px;
        color: $cta_color;
    }

    .section {
        padding-top: 80px;
    }

    h3 {
        font-size: 20px;
        color: $cta_color;
    }

    h4 {
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 10px;
        color: $cta_color;
    }

    p {
        font-size: 1.25rem;
        margin-block: 2rem;
        color: $black_text_color;
        font-size: 20px;
    }

    ul,
    ol {
        margin: 5px;
        padding: 5px;
        margin-bottom: 15px;
    }

    .table_list {
        list-style-type: none;
    }

    li {
        font-size: 1.25rem;
        margin-block: 0.5rem;
        color: $black_text_color;
    }

    a {
        text-decoration: none;
        font-size: 1.25rem;
        color: $black_text_color;
    }

    a:hover {
        text-decoration: underline;
    }

    strong {
        font-size: 1.25rem;
        color: $black_text_color;
        font-weight: normal;
    }
}

@media screen and (max-width: 1399px) {
    .TermsService {
        .bnnr {
            background-color: $primary_color;
            padding: 1rem;

            .container .heading {
                font-size: 4rem;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
            }
        }

        .container_terms_service {
            margin: auto;
            margin-block: 3rem;
        }

        .date {
            margin-block: 2rem;
            margin-bottom: 0.5rem;
            font-size: 1rem;
            font-weight: 500;
            color: $cta_color;
            padding-inline: 5px;
        }

        h1 {
            font-size: 1.8rem;
            margin: 0px;
            font-weight: 800;
            color: $cta_color;
        }

        .extra-bottom-mar {
            margin-bottom: 3rem;
        }

        h2 {
            font-size: 20px;
            color: $cta_color;
        }

        .section {
            padding-top: 80px;
        }

        h3 {
            font-size: 18px;
            color: $cta_color;
        }

        h4 {
            font-size: 16px;
            margin-top: 25px;
            margin-bottom: 10px;
            color: $cta_color;
        }

        p {
            font-size: 1rem;
            margin-block: 2rem;
            color: $black_text_color;
            font-size: 20px;
        }

        ul,
        ol {
            margin: 5px;
            padding: 5px;
            margin-bottom: 15px;
        }

        .table_list {
            list-style-type: none;
        }

        li {
            font-size: 1rem;
            margin-block: 0.5rem;
            color: $black_text_color;
        }

        a {
            text-decoration: none;
            font-size: 1rem;
            color: $black_text_color;
        }

        a:hover {
            text-decoration: underline;
        }

        strong {
            font-size: 1rem;
            color: $black_text_color;
            font-weight: normal;
        }
    }
}

@media screen and (max-width: 1199px) {
    .TermsService {
        .bnnr {
            background-color: $primary_color;
            padding: 2rem;

            .container .heading {
                font-size: 4rem;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
            }
        }

        .container_terms_service {
            margin: auto;
            margin-block: 3rem;
        }

        .date {
            margin-block: 2rem;
            margin-bottom: 0.5rem;
            font-size: 1.1rem;
            font-weight: 500;
            color: $cta_color;
            padding-inline: 5px;
        }

        h1 {
            font-size: 1.9rem;
            margin: 0px;
            font-weight: 800;
            color: $cta_color;
        }

        .extra-bottom-mar {
            margin-bottom: 3rem;
        }

        h2 {
            font-size: 22px;
            color: $cta_color;
        }

        .section {
            padding-top: 80px;
        }

        h3 {
            font-size: 18px;
            color: $cta_color;
        }

        h4 {
            font-size: 18px;
            margin-top: 25px;
            margin-bottom: 10px;
            color: $cta_color;
        }

        p {
            font-size: 1rem !important;
            margin-block: 2rem;
            color: $black_text_color;
            font-size: 20px;
        }

        ul,
        ol {
            margin: 5px;
            padding: 5px;
            margin-bottom: 15px;
        }

        .table_list {
            list-style-type: none;
        }

        li {
            font-size: 1rem;
            margin-block: 0.5rem;
            color: $black_text_color;
        }

        a {
            text-decoration: none;
            font-size: 1rem;
            color: $black_text_color;
        }

        a:hover {
            text-decoration: underline;
        }

        strong {
            font-size: 1.1rem;
            color: $black_text_color;
            font-weight: normal;
        }
    }
}

@media screen and (max-width: 1024px) {
    .TermsService {
        .bnnr {
            background-color: $primary_color;
            padding: 2rem;

            .container .heading {
                font-size: 3.5rem;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
            }
        }

        .container_terms_service {
            margin: auto;
            margin-block: 3rem;
        }

        .date {
            margin-block: 2rem;
            margin-bottom: 0.5rem;
            font-size: 1rem;
            font-weight: 500;
            color: $cta_color;
            padding-inline: 5px;
        }

        h1 {
            font-size: 1.8rem;
            margin: 0px;
            font-weight: 800;
            color: $cta_color;
        }

        .extra-bottom-mar {
            margin-bottom: 3rem;
        }

        h2 {
            font-size: 20px;
            color: $cta_color;
        }

        .section {
            padding-top: 80px;
        }

        h3 {
            font-size: 16px;
            color: $cta_color;
        }

        h4 {
            font-size: 16px;
            margin-top: 25px;
            margin-bottom: 10px;
            color: $cta_color;
        }

        p {
            font-size: 14px !important;
            margin-block: 2rem;
            color: $black_text_color;
            font-size: 20px;
        }

        ul,
        ol {
            margin: 5px;
            padding: 5px;
            margin-bottom: 15px;
        }

        .table_list {
            list-style-type: none;
        }

        li {
            font-size: 14px;
            margin-block: 0.5rem;
            color: $black_text_color;
        }

        a {
            text-decoration: none;
            font-size: 14px;
            color: $black_text_color;
        }

        a:hover {
            text-decoration: underline;
        }

        strong {
            font-size: 1rem;
            color: $black_text_color;
            font-weight: normal;
        }
    }
}

@media screen and (max-width: 992px) {
    .TermsService {
        .bnnr {
            background-color: $primary_color;
            padding: 2rem;

            .container .heading {
                font-size: 3rem;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
            }
        }

        .container_terms_service {
            margin: auto;
            margin-block: 3rem;
        }

        .date {
            margin-block: 2rem;
            margin-bottom: 0.5rem;
            font-size: 0.95rem;
            font-weight: 500;
            color: $cta_color;
            padding-inline: 5px;
        }

        h1 {
            font-size: 1.6rem;
            margin: 0px;
            font-weight: 800;
            color: $cta_color;
        }

        .extra-bottom-mar {
            margin-bottom: 3rem;
        }

        h2 {
            font-size: 18px;
            color: $cta_color;
        }

        .section {
            padding-top: 80px;
        }

        h3 {
            font-size: 15px;
            color: $cta_color;
        }

        h4 {
            font-size: 15px;
            margin-top: 25px;
            margin-bottom: 10px;
            color: $cta_color;
        }

        p {
            font-size: 13px !important;
            margin-block: 2rem;
            color: $black_text_color;
            font-size: 20px;
        }

        ul,
        ol {
            margin: 5px;
            padding: 5px;
            margin-bottom: 15px;
        }

        .table_list {
            list-style-type: none;
        }

        li {
            font-size: 13px;
            margin-block: 0.5rem;
            color: $black_text_color;
        }

        a {
            text-decoration: none;
            font-size: 13px;
            color: $black_text_color;
        }

        a:hover {
            text-decoration: underline;
        }

        strong {
            font-size: 13px;
            color: $black_text_color;
            font-weight: normal;
        }
    }
}

@media screen and (max-width: 768px) {
    .TermsService {
        .bnnr {
            background-color: $primary_color;
            padding: 1.5rem;
            padding-top: 3.5rem;

            .container .heading {
                font-size: 2.5rem;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
            }
        }

        .container_terms_service {
            margin: auto;
            margin-block: 3rem;
        }

        .date {
            margin-block: 2rem;
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
            font-weight: 500;
            color: $cta_color;
            padding-inline: 5px;
        }

        h1 {
            font-size: 1.5rem;
            margin: 0px;
            font-weight: 800;
            color: $cta_color;
        }

        .extra-bottom-mar {
            margin-bottom: 3rem;
        }

        h2 {
            font-size: 17px;
            color: $cta_color;
        }

        .section {
            padding-top: 80px;
        }

        h3 {
            font-size: 14px;
            color: $cta_color;
        }

        h4 {
            font-size: 14px;
            margin-top: 25px;
            margin-bottom: 10px;
            color: $cta_color;
        }

        p {
            font-size: 12px !important;
            margin-block: 2rem;
            color: $black_text_color;
            font-size: 20px;
        }

        ul,
        ol {
            margin: 5px;
            padding: 5px;
            margin-bottom: 15px;
        }

        .table_list {
            list-style-type: none;
        }

        li {
            font-size: 12px;
            margin-block: 0.5rem;
            color: $black_text_color;
        }

        a {
            text-decoration: none;
            font-size: 12px;
            color: $black_text_color;
        }

        a:hover {
            text-decoration: underline;
        }

        strong {
            font-size: 12px;
            color: $black_text_color;
            font-weight: normal;
        }
    }
}

@media screen and (max-width: 576px) {
    .TermsService {
        .bnnr {
            padding: 0rem;
            padding-top: 2.5rem;
            padding-bottom: 1rem;

            .container .heading {
                font-size: 1.8rem;
                padding-inline: 0rem;
                margin: 0px;
            }
        }

        .container_terms_service {
            max-width: 95%;
            padding: 1rem;
            padding-top: 0rem;
        }

        .date {
            margin-block: 0.8rem;
            margin-bottom: 0.5rem;
            font-size: 1.25rem;
            font-weight: 500;
        }

        h1 {
            font-size: 1.2rem;
            font-weight: 800;
        }

        h2 {
            font-size: 1rem;
            padding-top: 40px;
        }

        .section {
            padding-top: 70px;
            margin-top: -70px;
        }

        h3 {
            font-size: 0.9rem;
            margin-top: 25px;
            margin-bottom: 10px;
        }

        h4 {
            font-size: 1rem;
            margin-top: 25px;
            margin-bottom: 10px;
        }

        p {
            font-size: 0.8rem;
            margin-bottom: 10px;
        }

        ul {
            margin-bottom: 15px;
        }

        li {
            font-size: 0.8rem;
            margin-bottom: 15px;
        }

        a {
            font-size: 0.8rem;
            margin-bottom: 15px;
        }

        a:hover {
            text-decoration: underline;
        }

        strong {
            font-size: 0.8rem;
            margin-bottom: 15px;
        }
    }
}