@import "../../SCSS/variables";
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');


.About_us {
    .bannar {
        background-color: $primary_color;
        padding: 2rem;

        .container .heading {
            font-size: 6rem;
            font-weight: 800;
            text-transform: uppercase;
            color: $white;
            line-height: 90%;
            padding-inline: 3rem;

        }
    }

    .About_us_content .container {
        max-width: 992px;
        margin-block: 2rem;


        .top_section {
            padding-block: 4rem;
            display: flex;
            text-align: center;
            flex-direction: column;
            gap: 2rem;
            justify-content: center;
            justify-items: center;

            p {
                font-size: 20px;
                color: $black;
                font-weight: 400;

                a {
                    color: $cta_color;
                    font-size: inherit;
                    padding-inline: 5px;
                }
            }
        }

        .bottom_section {

            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            background-color: $cta_color;
            padding: 25px;
            color: $white;
            border-radius: 7px;
            .queote_container {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 2rem;

                .double_queote {
                    font-size: 64px;
                    font-family: "Space Grotesk", sans-serif;
                }

                .flex_end {
                    align-self: flex-end;
                }

                .queote {
                    font-weight: 600;
                    font-style: italic;
                    font-size: 32px;
                }

                .rotote_queote {
                    transform: rotate(180deg);
                    align-self: flex-start;
                }

                .name {
                    font-weight: 400;
                    font-size: 20px;
                    text-transform: uppercase;
                }

                >div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

            }

            img {
                width: auto;
                max-height: 300px;
                border-radius: 10px;
            }
        }
    }
}


@media screen and (max-width: 1399px) {
    .About_us {
        .bannar {
            background-color: $primary_color;
            padding: 1rem;

            .container .heading {
                font-size: 4rem;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
                padding-inline: 3rem;

            }
        }

        .About_us_content .container {
            max-width: 992px;
            margin-block: 1rem;


            .top_section {
                padding-block: 4rem;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                text-align: center;

                p {
                    font-size: 18px;
                    color: $black;
                    font-weight: 400;

                    a {
                        color: $cta_color;
                        font-size: inherit;
                        padding-inline: 5px;
                    }
                }
            }

            .bottom_section {

                display: flex;
                justify-content: space-between;

                .queote_container {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-right: 2rem;

                    .double_queote {
                        font-size: 64px;
                        font-family: "Space Grotesk", sans-serif;
                    }

                    .flex_end {
                        align-self: flex-end;
                    }

                    .queote {
                        font-weight: 600;
                        font-style: italic;
                        font-size: 28px;
                    }

                    .rotote_queote {
                        transform: rotate(180deg);
                        align-self: flex-start;
                    }

                    .name {
                        font-weight: 400;
                        font-size: 20px;
                        text-transform: uppercase;
                    }

                    >div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                }

                img {
                    width: auto;
                    max-height: 300px;
                    border-radius: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .About_us {
        .bannar {
            background-color: $primary_color;
            padding: 2rem;

            .container .heading {
                font-size: 4.5rem;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
                padding-inline: 2rem;

            }
        }

        .About_us_content .container {
            max-width: 992px;
            margin-block: 1.5rem;


            .top_section {
                padding-block: 4rem;
                display: flex;
                flex-direction: column;
                gap: 2rem;

                p {
                    font-size: 18px;
                    color: $black;
                    font-weight: 400;
                    text-align: center;

                    a {
                        color: $cta_color;
                        font-size: inherit;
                        padding-inline: 5px;
                    }
                }
            }

            .bottom_section {

                display: flex;
                justify-content: space-between;

                .queote_container {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-right: 2rem;

                    .double_queote {
                        font-size: 64px;
                        font-family: "Space Grotesk", sans-serif;
                    }

                    .flex_end {
                        align-self: flex-end;
                    }

                    .queote {
                        font-weight: 600;
                        font-style: italic;
                        font-size: 30px;
                    }

                    .rotote_queote {
                        transform: rotate(180deg);
                        align-self: flex-start;
                    }

                    .name {
                        font-weight: 400;
                        font-size: 20px;
                        text-transform: uppercase;
                    }

                    >div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                }

                img {
                    width: auto;
                    max-height: 300px;
                    border-radius: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .About_us {
        .bannar {
            background-color: $primary_color;
            padding: 2rem;

            .container .heading {
                font-size: 4rem;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
                padding-inline: 2rem;

            }
        }

        .About_us_content .container {
            max-width: 90%;
            margin-block: 1.5rem;


            .top_section {
                padding-block: 4rem;
                display: flex;
                flex-direction: column;
                gap: 2rem;

                p {
                    font-size: 18px;
                    color: $black;
                    font-weight: 400;
                    text-align: center;

                    a {
                        color: $cta_color;
                        font-size: inherit;
                        padding-inline: 5px;
                    }
                }
            }

            .bottom_section {

                display: flex;
                justify-content: space-between;

                .queote_container {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-right: 2rem;

                    .double_queote {
                        font-size: 64px;
                        font-family: "Space Grotesk", sans-serif;
                    }

                    .flex_end {
                        align-self: flex-end;
                    }

                    .queote {
                        font-weight: 600;
                        font-style: italic;
                        font-size: 30px;
                    }

                    .rotote_queote {
                        transform: rotate(180deg);
                        align-self: flex-start;
                    }

                    .name {
                        font-weight: 400;
                        font-size: 20px;
                        text-transform: uppercase;
                    }

                    >div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                }

                img {
                    width: auto;
                    max-height: 300px;
                    border-radius: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .About_us {
        .bannar {
            background-color: $primary_color;
            padding-block: 2rem;

            .container .heading {
                font-size: 3.5rem;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 80%;
                padding-inline: 1rem;

            }
        }

        .About_us_content .container {
            max-width: 90%;
            margin-block: 1.5rem;


            .top_section {
                padding-block: 4rem;
                display: flex;
                flex-direction: column;
                gap: 2rem;

                p {
                    font-size: 16px;
                    color: $black;
                    font-weight: 400;
                    text-align: center;

                    a {
                        color: $cta_color;
                        font-size: inherit;
                        padding-inline: 5px;
                    }
                }
            }

            .bottom_section {

                display: flex;
                justify-content: space-between;

                .queote_container {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-right: 2rem;

                    .double_queote {
                        font-size: 64px;
                        font-family: "Space Grotesk", sans-serif;
                    }

                    .flex_end {
                        align-self: flex-end;
                    }

                    .queote {
                        font-weight: 600;
                        font-style: italic;
                        font-size: 28px;
                    }

                    .rotote_queote {
                        transform: rotate(180deg);
                        align-self: flex-start;
                    }

                    .name {
                        font-weight: 400;
                        font-size: 18px;
                        text-transform: uppercase;
                    }

                    >div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                }

                img {
                    width: auto;
                    max-height: 260px;
                    border-radius: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .About_us {
        .bannar {
            background-color: $primary_color;
            padding-block: 2rem;
            padding-top: 3.5rem;

            .container .heading {
                font-size: 2.6rem;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 80%;
                padding-inline: 1rem;

            }
        }

        .About_us_content .container {
            max-width: 90%;
            margin-block: 0.5rem;


            .top_section {
                padding-block: 4rem;
                display: flex;
                flex-direction: column;
                gap: 2rem;

                p {
                    font-size: 16px;
                    color: $black;
                    font-weight: 400;
                    text-align: center;

                    a {
                        color: $cta_color;
                        font-size: inherit;
                        padding-inline: 5px;
                    }
                }
            }

            .bottom_section {

                display: flex;
                justify-content: space-between;

                .queote_container {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-right: 2rem;

                    .double_queote {
                        font-size: 60px;
                        font-family: "Space Grotesk", sans-serif;
                    }

                    .flex_end {
                        align-self: flex-end;
                    }

                    .queote {
                        font-weight: 600;
                        font-style: italic;
                        font-size: 24px;
                    }

                    .rotote_queote {
                        transform: rotate(180deg);
                        align-self: flex-start;
                    }

                    .name {
                        font-weight: 400;
                        font-size: 16px;
                        text-transform: uppercase;
                    }

                    >div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                }

                img {
                    width: auto;
                    max-height: 240px;
                    border-radius: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .About_us {
        .bannar {
            padding: 1rem;
            padding-top: 2rem;

            .container .heading {
                font-size: 2rem;
                padding-inline: 0rem;
                margin: 0px;

            }
        }

        .About_us_content .container {
            max-width: 90%;
            margin-block: 1rem;


            .top_section {
                padding-block: 1rem;
                gap: 1rem;

                p {
                    font-size: 14px;
                    text-align: center;

                    a {
                        padding-inline: 4px;
                    }
                }
            }

            .bottom_section {
                flex-direction: column-reverse;

                .queote_container {
                    padding-right: 0rem;
                    max-width: fit-content;
                    margin: auto;

                    .double_queote {
                        font-size: 32px;
                    }

                    .flex_end {
                        align-self: flex-end;
                    }

                    .queote {
                        font-weight: 600;
                        font-style: italic;
                        font-size: 18px;
                    }

                    .rotote_queote {
                        transform: rotate(180deg);
                        align-self: flex-start;
                    }

                    .name {
                        font-weight: 400;
                        font-size: 18px;
                        text-transform: uppercase;
                    }

                    >div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                }

                img {
                    // aspect-ratio: 0.8/1;

                    border-radius: 10px;
                }
            }
        }
    }
}