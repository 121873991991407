@import '../../SCSS/variables';

.login-page {
  // max-width: 95%;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 20px;

  .heading {
    h1 {
      font-size: 32px;
      font-weight: 600;
    }
  }

  .header-section {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .divider {
      border: 2px solid black;
      width: 40px;
    }
  }

  .login-options-section {
    margin-block: 1.5rem;

    button {
      background-color: transparent;
      color: #000;
      box-shadow: none;
      border: 1px solid #000;
      padding: 0.5rem 0.8rem;
      border-radius: 50px;
      margin: 0 1rem;
    }
  }

  .mobile-login-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 14px;
      opacity: 0.7;
      font-weight: 300;

    }

    .mobile-login {
      // width: 300px;
      width: 90%;

      h5 {
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 15px;
      }

      .input_wrp {
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          border: none;
        }
      }

      p {
        font-size: 14px;
        // margin: 1rem;
        margin-top: 0.6rem;
        font-weight: 500;
        color: #494949;
        width: 100%;

        >a {
          text-decoration: none;
          color: $cta_color;
          padding-left: 5px;
        }
      }

      .country-code-input {
        margin-bottom: 10px;
      }

      .mobile-number-input {
        margin-bottom: 10px;
      }

      button {
        margin-top: 40px;
      }
    }
  }

  .otpBtn {
    background-color: $cta_color;
    // padding: 1rem;
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 1rem;

    &:hover {
      background-color: rgba($color: $cta_color, $alpha: 0.8);

    }
  }
}


.login-page {
  /* Your existing styles for login page */

  .otp-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
      font-size: 14px;
      text-align: center;
      margin-top: 0rem;
      margin-bottom: 0.5rem;
    }

    p {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
    }

    .resend_otp {
      text-align: center;
      margin-top: 3rem;
    }

    a {
      font-weight: 300;
    }

    .otp-inputs {
      display: flex;
      gap: 10px;
      justify-content: center;
      margin: 20px 0;
      border: none;

      input {
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 1.2rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        
        &:focus {
          border-color: #1976d2;
          box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
        }
      }
    }

    input:last-child {
      border: 1px solid #ccc !important;
    }

    .otpBtn {
      margin-top: 5px;
    }
  }
}

:global {
  .css-1wc848c-MuiFormHelperText-root {
    position: absolute;
    border-radius: 20px !important;
  }

  .MuiPaper-root {
    border-radius: 20px !important;
  }
}



@media screen and (max-width: 1399px) {
  .login-page {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    border-radius: 20px;

    .heading {
      h1 {
        font-size: 26px;
        font-weight: 600;
      }
    }

    .header-section {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .divider {
        border: 2px solid black;
        width: 35px;
      }
    }

    .login-options-section {
      margin-block: 1.2rem;

      button {
        background-color: transparent;
        color: #000;
        box-shadow: none;
        border: 1px solid #000;
        padding: 0.5rem 0.8rem;
        border-radius: 50px;
        margin: 0 1rem;
      }
    }

    .mobile-login-section {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 14px;
        opacity: 0.7;
        font-weight: 300;

      }

      .mobile-login {
        // width: 300px;
        width: 90%;

        h5 {
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 15px;
        }

        .input_wrp {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            border: none;
          }
        }

        p {
          font-size: 12px;
          // margin: 1rem;
          margin-top: 0.6rem;
          font-weight: 500;
          color: #494949;
          width: 100%;

          >a {
            text-decoration: none;
            color: $cta_color;
            padding-left: 5px;
          }
        }

        .country-code-input {
          margin-bottom: 10px;
        }

        .mobile-number-input {
          margin-bottom: 10px;
        }

        button {
          margin-top: 40px;
        }
      }
    }

    .otpBtn {
      background-color: $cta_color;
      // padding: 1rem;
      text-transform: capitalize;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0.5rem;

      &:hover {
        background-color: rgba($color: $cta_color, $alpha: 0.8);

      }
    }
  }


  .login-page {
    /* Your existing styles for login page */

    .otp-section {

      h5 {
        font-size: 14px;
        text-align: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 10px;
        font-size: 12px;
        font-weight: 500;
      }

      .resend_otp {
        text-align: center;
        margin-top: 2.5rem;
      }

      a {
        font-weight: 300;
      }

      .otp-inputs {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin: 20px 0;

        input {
          width: 40px;
          height: 40px;
          text-align: center;
          font-size: 1.2rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          outline: none;
          
          &:focus {
            border-color: #1976d2;
            box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
          }
        }
      }

      input:last-child {
        border: 1px solid #ccc !important;

      }

      .otpBtn {
        margin-top: 5px;
      }
    }
  }

  :global {
    .css-1wc848c-MuiFormHelperText-root {
      position: absolute;
      border-radius: 20px !important;
    }

    .MuiPaper-root {
      border-radius: 20px !important;
    }
  }
}

@media screen and (max-width: 1199px) {
  .login-page {
    // max-width: 95%;
    width: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    border-radius: 20px;

    .heading {
      h1 {
        font-size: 26px;
        font-weight: 600;
      }
    }

    .header-section {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .divider {
        border: 2px solid black;
        width: 40px;
      }
    }

    .login-options-section {
      margin-block: 1.5rem;

      button {
        background-color: transparent;
        color: #000;
        box-shadow: none;
        border: 1px solid #000;
        padding: 0.5rem 0.8rem;
        border-radius: 50px;
        margin: 0 1rem;
      }
    }

    .mobile-login-section {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 13px;
        opacity: 0.7;
        font-weight: 300;

      }

      .mobile-login {
        // width: 300px;
        width: 90%;

        h5 {
          font-size: 14px;
          font-weight: 600;
          padding-bottom: 15px;
        }

        .input_wrp {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            border: none;
          }
        }

        p {
          font-size: 13px;
          // margin: 1rem;
          margin-top: 0.6rem;
          font-weight: 500;
          color: #494949;
          width: 100%;

          >a {
            text-decoration: none;
            color: $cta_color;
            padding-left: 5px;
          }
        }

        .country-code-input {
          margin-bottom: 10px;
        }

        .mobile-number-input {
          margin-bottom: 10px;
        }

        button {
          margin-top: 40px;
        }
      }
    }

    .otpBtn {
      background-color: $cta_color;
      // padding: 1rem;
      text-transform: capitalize;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 1rem;
      max-width: 90%;

      &:hover {
        background-color: rgba($color: $cta_color, $alpha: 0.8);

      }
    }
  }


  .login-page {
    /* Your existing styles for login page */

    .otp-section {

      h5 {
        font-size: 14px;
        text-align: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 10px;
        font-size: 12px;
        font-weight: 500;
      }

      .resend_otp {
        text-align: center;
        margin-top: 2.5rem;
      }

      a {
        font-weight: 300;
      }

      .otp-inputs {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin: 20px 0;

        input {
          width: 40px;
          height: 40px;
          text-align: center;
          font-size: 1.2rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          outline: none;
          
          &:focus {
            border-color: #1976d2;
            box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
          }
        }
      }

      input:last-child {
        border: 1px solid #ccc !important;

      }

      .otpBtn {
        margin-top: 5px;
      }
    }
  }

  :global {
    .css-1wc848c-MuiFormHelperText-root {
      position: absolute;
      border-radius: 20px !important;
    }

    .MuiPaper-root {
      border-radius: 20px !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .login-page {
    // max-width: 95%;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    border-radius: 20px;

    .heading {
      h1 {
        font-size: 24px;
        font-weight: 600;
      }
    }

    .header-section {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .divider {
        border: 2px solid black;
        width: 40px;
      }
    }

    .login-options-section {
      margin-block: 1.5rem;

      button {
        background-color: transparent;
        color: #000;
        box-shadow: none;
        border: 1px solid #000;
        padding: 0.5rem 0.8rem;
        border-radius: 50px;
        margin: 0 1rem;
      }
    }

    .mobile-login-section {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 13px;
        opacity: 0.7;
        font-weight: 300;

      }

      .mobile-login {
        // width: 300px;
        width: 90%;

        h5 {
          font-size: 13px;
          font-weight: 600;
          padding-bottom: 15px;
        }

        .input_wrp {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            border: none;
          }
        }

        p {
          font-size: 12px;
          // margin: 1rem;
          margin-top: 0.6rem;
          font-weight: 500;
          color: #494949;
          width: 100%;

          >a {
            text-decoration: none;
            color: $cta_color;
            padding-left: 5px;
          }
        }

        .country-code-input {
          margin-bottom: 10px;
        }

        .mobile-number-input {
          margin-bottom: 10px;
        }

        button {
          margin-top: 40px;
        }
      }
    }

    .otpBtn {
      background-color: $cta_color;
      // padding: 1rem;
      text-transform: capitalize;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 1rem;
      max-width: 90%;

      &:hover {
        background-color: rgba($color: $cta_color, $alpha: 0.8);

      }
    }
  }

  .login-page {
    /* Your existing styles for login page */

    .otp-section {

      h5 {
        font-size: 13;
        text-align: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 10px;
        font-size: 12px;
        font-weight: 500;
      }

      .resend_otp {
        text-align: center;
        margin-top: 2.5rem;
      }

      a {
        font-weight: 300;
      }

      .otp-inputs {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin: 20px 0;

        input {
          width: 40px;
          height: 40px;
          text-align: center;
          font-size: 1.2rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          outline: none;
          
          &:focus {
            border-color: #1976d2;
            box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
          }
        }
      }

      input:last-child {
        border: 1px solid #ccc !important;

      }

      .otpBtn {
        margin-top: 5px;
      }
    }
  }

  :global {
    .css-1wc848c-MuiFormHelperText-root {
      position: absolute;
      border-radius: 20px !important;
    }

    .MuiPaper-root {
      border-radius: 20px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .login-page {
    // max-width: 95%;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-radius: 20px;

    .heading {
      h1 {
        font-size: 22px;
        font-weight: 600;
      }
    }

    .header-section {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .divider {
        border: 2px solid black;
        width: 40px;
      }
    }

    .login-options-section {
      margin-block: 1rem;

      button {
        background-color: transparent;
        color: #000;
        box-shadow: none;
        border: 1px solid #000;
        padding: 0.5rem 0.8rem;
        border-radius: 50px;
        margin: 0 0.8rem;
      }
    }

    .mobile-login-section {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 13px;
        opacity: 0.7;
        font-weight: 300;

      }

      .mobile-login {
        // width: 300px;
        width: 90%;

        h5 {
          font-size: 13px;
          font-weight: 600;
          padding-bottom: 10px;
        }

        .input_wrp {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            border: none;
          }
        }

        p {
          font-size: 11px;
          // margin: 1rem;
          margin-top: 0.2rem;
          font-weight: 500;
          color: #494949;
          width: 100%;

          >a {
            text-decoration: none;
            color: $cta_color;
            padding-left: 5px;
          }
        }

        .country-code-input {
          margin-bottom: 10px;
        }

        .mobile-number-input {
          margin-bottom: 10px;
        }

        button {
          margin-top: 40px;
        }
      }
    }

    .otpBtn {
      background-color: $cta_color;
      // padding: 1rem;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 1rem;
      max-width: 90%;

      &:hover {
        background-color: rgba($color: $cta_color, $alpha: 0.8);

      }
    }
  }


  .login-page {
    /* Your existing styles for login page */

    .otp-section {

      h5 {
        font-size: 12px;
        text-align: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 9px;
        font-size: 12px;
        font-weight: 500;
      }

      .resend_otp {
        text-align: center;
        margin-top: 2.5rem;
      }

      a {
        font-weight: 300;
      }

      .otp-inputs {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin: 20px 0;

        input {
          width: 40px;
          height: 40px;
          text-align: center;
          font-size: 1.2rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          outline: none;
          
          &:focus {
            border-color: #1976d2;
            box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
          }
        }
      }

      input:last-child {
        border: 1px solid #ccc !important;

      }

      .otpBtn {
        margin-top: 5px;
      }
    }
  }

  :global {
    .css-1wc848c-MuiFormHelperText-root {
      position: absolute;
      border-radius: 20px !important;
    }

    .MuiPaper-root {
      border-radius: 20px !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .login-page {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-radius: 20px;

    .heading {
      h1 {
        font-size: 22px;
        font-weight: 600;
      }
    }

    .header-section {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .divider {
        border: 2px solid black;
        width: 30px;
      }
    }

    .login-options-section {
      margin-block: 1rem;

      button {
        background-color: transparent;
        color: #000;
        box-shadow: none;
        border: 1px solid #000;
        padding: 0.5rem 0.8rem;
        border-radius: 50px;
        margin: 0 0.8rem;
      }
    }

    .mobile-login-section {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 13px;
        opacity: 0.7;
        font-weight: 300;

      }

      .mobile-login {
        // width: 300px;
        width: 90%;

        h5 {
          font-size: 13px;
          font-weight: 600;
          padding-bottom: 10px;
        }

        .input_wrp {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            border: none;
          }
        }

        p {
          font-size: 12px;
          // margin: 1rem;
          margin-top: 0.5rem;
          font-weight: 500;
          color: #494949;
          width: 100%;

          >a {
            text-decoration: none;
            color: $cta_color;
            padding-left: 5px;
          }
        }

        .country-code-input {
          margin-bottom: 10px;
        }

        .mobile-number-input {
          margin-bottom: 10px;
        }

        button {
          margin-top: 30px;
        }
      }
    }

    .otpBtn {
      background-color: $cta_color;
      // padding: 1rem;
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 1rem;
      max-width: 85%;

      &:hover {
        background-color: rgba($color: $cta_color, $alpha: 0.8);

      }
    }
  }


  .login-page {
    /* Your existing styles for login page */

    .otp-section {


      h5 {
        font-size: 13px;
        text-align: center;
        margin-top: 0.6rem;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 9px;
        font-size: 11px;
        font-weight: 500;
      }

      .resend_otp {
        text-align: center;
        margin-top: 1.5rem;
      }

      a {
        font-weight: 300;
      }

      .otp-inputs {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin: 20px 0;

        input {
          width: 40px;
          height: 40px;
          text-align: center;
          font-size: 1.2rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          outline: none;
          
          &:focus {
            border-color: #1976d2;
            box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
          }
        }
      }

      input:last-child {
        border: 1px solid #ccc !important;

      }

      .otpBtn {
        margin-top: 5px;
      }
    }
  }

  :global {
    .css-1wc848c-MuiFormHelperText-root {
      position: absolute;
      border-radius: 20px !important;
    }

    .MuiPaper-root {
      border-radius: 20px !important;
    }
  }
}