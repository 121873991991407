.GameCoachFeedback {
    padding: 2rem;
    width: 100%;

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .videoSection {
        width: 100%;

        .videoImageContainer {
            width: 100%;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-repeat: no-repeat;
            background-size: cover;

            .iconContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                i {
                    font-size: 3.5rem;
                    color: white;
                }

                span {
                    font-size: 1.5rem;
                    color: white;
                }
            }




        }

        .video_cont {

            video {
                width: 100%;
                height: 300px;
            }

        }
    }



}