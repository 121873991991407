@import '../../SCSS/variables';

.blog {
    padding-top: 3rem;
    background-color: $white_text_color;

    .container {
        .heading {
            padding-bottom: 20px;
            text-align: center;

            h1 {
                font-size: 6rem;
                font-weight: 900;
                text-transform: uppercase;
                line-height: 104px;
                color: $cta_color !important;
                text-align: start;
            }
        }
    }
}

.blog_box {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $cta_color;

    .container {
        .blog_container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-content: space-around;
            background-color: $cta_color;

            .box {
                padding: 1rem;
                max-width: 370px;
                cursor: pointer;

                .item {
                    background-color: #fff;
                    // background-color: red;
                    padding: 1rem 1rem;
                    box-shadow: 0 0 15px #00000031;
                    border-radius: 10px;

                    img {
                        width: 100%;
                        height: 239px;
                        border-radius: 10px;
                    }

                    .blg_info {
                        padding: 1rem 0.5rem;

                        h5 {
                            font-size: 14px;
                            opacity: 0.7;
                        }

                        ul {
                            display: grid;
                            grid-template-rows: auto auto;
                            grid-template-columns: 1fr 1fr;
                            text-align: center;
                            column-gap: 0.5rem;
                            row-gap: 0.5rem;
                            margin: 1rem 0;
                            list-style-type: none;

                            li {
                                border: 1px solid #0000006e;
                                padding: 0.3rem 0.3rem;
                                margin: 0 0.1rem;
                                border-radius: 50px;
                                font-size: 14px;
                                opacity: 0.8;
                                cursor: pointer;
                                transition: all 0.3s ease-in-out;

                                &:hover {
                                    background-color: rgba(199, 199, 199, 0.461);
                                    color: #000;
                                    opacity: 1;
                                }
                            }

                            li:nth-child(3) {
                                grid-column: span 2;
                                width: 70%;
                            }
                        }

                        h2 {
                            font-size: 24px;
                            font-weight: 700;
                            color: #000000d8;
                            text-transform: uppercase;

                        }

                        p {
                            font-size: 14px;
                            font-weight: 500;
                            opacity: 0.9;
                        }

                        h6 {
                            font-size: 12px;
                            text-transform: uppercase;
                            margin-top: 2rem;
                            opacity: 0.6;
                        }

                        h3 {
                            font-size: 17px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1399px) {
    .blog {
        padding-top: 2rem;

        .container {
            max-width: 90%;

            .heading {
                padding-bottom: 22px;

                h1 {
                    font-size: 6rem;
                    line-height: 106px;
                }
            }
        }
    }

    .blog_box {
        padding-top: 3rem;
        padding-bottom: 3rem;

        .container {
            max-width: 90%;

            .blog_container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                justify-content: space-around;

                .box {
                    padding: 1.2rem;
                    max-width: 375px;

                    .item {
                        padding: 1.2rem 1.2rem;
                        box-shadow: 0 0 15px #00000031;

                        img {
                            height: 230px;
                        }

                        .blg_info {
                            padding: 1.1rem 0.6rem;

                            h5 {
                                font-size: 14px;
                            }

                            ul {
                                display: grid;
                                grid-template-rows: auto auto;
                                grid-template-columns: 1fr 1fr;
                                text-align: center;
                                column-gap: 0.6rem;
                                row-gap: 0.6rem;
                                margin: 1.2rem 0;
                                padding-left: 0.3rem;

                                li {
                                    padding: 0.3rem 0.3rem;
                                    margin: 0 0.1rem;
                                    font-size: 12px;

                                    &:hover {
                                        background-color: rgba(199, 199, 199, 0.461);
                                        color: #000;
                                        opacity: 1;
                                    }
                                }

                                li:nth-child(3) {
                                    grid-column: span 2;
                                }
                            }

                            h2 {
                                font-size: 22px;
                            }

                            p {
                                font-size: 14px;
                            }

                            h6 {
                                font-size: 13px;
                                margin-top: 2.2rem;
                            }

                            h3 {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .blog {
        padding-top: 2.8rem;

        .container {
            max-width: 90%;

            .heading {
                padding-bottom: 20px;

                h1 {
                    font-size: 6rem;
                    line-height: 104px;
                }
            }
        }
    }

    .blog_box {
        padding-top: 3rem;
        padding-bottom: 3rem;

        .container {
            max-width: 90%;

            .blog_container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                justify-content: space-around;

                .box {
                    padding: 1rem;
                    max-width: 370px;

                    .item {
                        padding: 1rem 1rem;
                        box-shadow: 0 0 15px #00000031;

                        img {
                            height: 239px;
                        }

                        .blg_info {
                            padding: 1rem 0.5rem;

                            h5 {
                                font-size: 14px;
                            }

                            ul {
                                display: grid;
                                grid-template-rows: auto auto;
                                grid-template-columns: 1fr 1fr;
                                text-align: center;
                                column-gap: 0.5rem;
                                row-gap: 0.5rem;
                                margin: 1rem 0;
                                padding-left: 0.3rem;

                                li {
                                    padding: 0.3rem 0.3rem;
                                    margin: 0 0.1rem;
                                    font-size: 14px;

                                    &:hover {
                                        background-color: rgba(199, 199, 199, 0.461);
                                        color: #000;
                                        opacity: 1;
                                    }
                                }

                                li:nth-child(3) {
                                    grid-column: span 2;
                                }
                            }

                            h2 {
                                font-size: 24px;
                            }

                            p {
                                font-size: 14px;
                            }

                            h6 {
                                font-size: 12px;
                                margin-top: 2rem;
                            }

                            h3 {
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .blog {
        padding-top: 2.5rem;

        .container {
            max-width: 90%;

            .heading {
                padding-bottom: 19px;

                h1 {
                    font-size: 5.8rem;
                    line-height: 103px;
                }
            }
        }
    }

    .blog_box {
        padding-top: 2.8rem;
        padding-bottom: 2.8rem;

        .container {
            max-width: 90%;

            .blog_container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                justify-content: space-around;

                .box {
                    padding: 0.9rem;
                    max-width: 360px;

                    .item {
                        padding: 0.9rem 0.9rem;
                        box-shadow: 0 0 15px #00000031;

                        img {
                            height: 220px;
                        }

                        .blg_info {
                            padding: 0.8rem 0.3rem;

                            h5 {
                                font-size: 13px;
                            }

                            ul {
                                display: grid;
                                grid-template-rows: auto auto;
                                grid-template-columns: 1fr 1fr;
                                text-align: center;
                                column-gap: 0.5rem;
                                row-gap: 0.5rem;
                                margin: 1rem 0;
                                padding-left: 0.3rem;

                                li {
                                    padding: 0.3rem 0.3rem;
                                    margin: 0 0.1rem;
                                    font-size: 12px;

                                    &:hover {
                                        background-color: rgba(199, 199, 199, 0.461);
                                        color: #000;
                                        opacity: 1;
                                    }
                                }

                                li:nth-child(3) {
                                    grid-column: span 2;
                                }
                            }

                            h2 {
                                font-size: 23px;
                            }

                            p {
                                font-size: 14px;
                            }

                            h6 {
                                font-size: 12px;
                                margin-top: 1.8rem;
                            }

                            h3 {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .blog {
        padding-top: 2rem;

        .container {
            max-width: 90%;

            .heading {
                padding-bottom: 18px;

                h1 {
                    font-size: 5.5rem;
                    line-height: 102px;
                }
            }
        }
    }

    .blog_box {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;

        .container {
            max-width: 90%;

            .blog_container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                justify-content: space-around;

                .box {
                    padding: 0.8rem;
                    max-width: 355px;

                    .item {
                        padding: 0.8rem 0.8rem;
                        box-shadow: 0 0 15px #00000031;

                        img {
                            height: 200px;
                        }

                        .blg_info {
                            padding: 0.6rem 0.4rem;

                            h5 {
                                font-size: 12px;
                            }

                            ul {
                                display: grid;
                                grid-template-rows: auto auto;
                                grid-template-columns: 1fr 1fr;
                                text-align: center;
                                column-gap: 0.4rem;
                                row-gap: 0.4rem;
                                margin: 0.9rem 0;
                                padding-left: 0.3rem;

                                li {
                                    padding: 0.2rem 0.2rem;
                                    margin: 0 0.1rem;
                                    font-size: 11px;

                                    &:hover {
                                        background-color: rgba(199, 199, 199, 0.461);
                                        color: #000;
                                        opacity: 1;
                                    }
                                }

                                li:nth-child(3) {
                                    grid-column: span 2;
                                }
                            }

                            h2 {
                                font-size: 22px;
                            }

                            p {
                                font-size: 14px;
                            }

                            h6 {
                                font-size: 12px;
                                margin-top: 1.7rem;
                            }

                            h3 {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .blog {
        padding-top: 1.8rem;

        .container {
            max-width: 90%;

            .heading {
                padding-bottom: 16px;

                h1 {
                    font-size: 5rem;
                    line-height: 100px;
                }
            }
        }
    }

    .blog_box {
        padding-top: 2rem;
        padding-bottom: 2rem;

        .container {
            max-width: 90%;

            .blog_container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-content: space-around;

                .box {
                    padding: 0.6rem;
                    max-width: 340px;

                    .item {
                        padding: 0.6rem 0.6rem;
                        box-shadow: 0 0 15px #00000031;

                        img {
                            height: 170px;
                        }

                        .blg_info {
                            padding: 0.6rem 0.3rem;

                            h5 {
                                font-size: 12px;
                            }

                            ul {
                                display: grid;
                                grid-template-rows: auto auto;
                                grid-template-columns: 1fr 1fr;
                                text-align: center;
                                column-gap: 0.3rem;
                                row-gap: 0.3rem;
                                margin: 0.8rem 0;
                                padding-left: 0.2rem;

                                li {
                                    padding: 0.2rem 0.2rem;
                                    margin: 0 0.1rem;
                                    font-size: 10px;

                                    &:hover {
                                        background-color: rgba(199, 199, 199, 0.461);
                                        color: #000;
                                        opacity: 1;
                                    }
                                }

                                li:nth-child(3) {
                                    grid-column: span 2;
                                }
                            }

                            h2 {
                                font-size: 20px;
                            }

                            p {
                                font-size: 12px;
                            }

                            h6 {
                                font-size: 11px;
                                margin-top: 1.4rem;
                            }

                            h3 {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .blog {
        padding-top: 1.5rem;

        .container {
            max-width: 90%;

            .heading {
                padding-bottom: 0px;

                h1 {
                    font-size: 2.8rem;
                    text-align: start;
                    padding: 0px;
                    margin: 0px;
                }
            }
        }
    }

    .blog_box {
        padding-top: 2rem;
        padding-bottom: 3rem;

        .container {
            max-width: 90%;

            .blog_container {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                justify-content: space-around;
                background-color: $cta_color;

                .box {
                    padding: 1rem;
                    max-width: 370px;
                    cursor: pointer;
                    margin: auto;

                    .item {
                        background-color: #fff;
                        // background-color: red;
                        padding: 1rem 1rem;
                        box-shadow: 0 0 15px #00000031;
                        border-radius: 10px;

                        img {
                            width: 100%;
                            height: 239px;
                            border-radius: 10px;
                        }

                        .blg_info {
                            padding: 1rem 0.5rem;

                            h5 {
                                font-size: 14px;
                                opacity: 0.7;
                            }

                            ul {
                                display: grid;
                                grid-template-rows: auto auto;
                                grid-template-columns: 1fr 1fr;
                                text-align: center;
                                column-gap: 0.5rem;
                                row-gap: 0.5rem;
                                margin: 1rem 0;
                                list-style-type: none;
                                padding: 0;

                                li {
                                    border: 1px solid #0000006e;
                                    padding: 0.3rem 0.3rem;
                                    margin: 0 0.1rem;
                                    border-radius: 50px;
                                    font-size: 14px;
                                    opacity: 0.8;
                                    cursor: pointer;
                                    transition: all 0.3s ease-in-out;

                                    &:hover {
                                        background-color: rgba(199, 199, 199, 0.461);
                                        color: #000;
                                        opacity: 1;
                                    }
                                }

                                li:nth-child(3) {
                                    grid-column: span 2;
                                    width: 70%;
                                }
                            }

                            h2 {
                                font-size: 20px;
                                font-weight: 700;
                                color: #000000d8;
                                text-transform: uppercase;

                            }

                            p {
                                font-size: 14px;
                                font-weight: 500;
                                opacity: 0.9;
                            }

                            h6 {
                                font-size: 12px;
                                text-transform: uppercase;
                                margin-top: 2rem;
                                opacity: 0.6;
                            }

                            h3 {
                                font-size: 16px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .blog {
        padding-top: 1rem;

        .container {
            max-width: 90%;

            .heading {
                padding-bottom: 0px;

                h1 {
                    font-size: 2.5rem;
                    text-align: start;
                    padding: 0px;
                    margin: 0px;
                }
            }
        }
    }

    .blog_box {
        padding-top: 1.5rem;
        padding-bottom: 3rem;

        .container {
            max-width: 90%;

            .blog_container {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                justify-content: space-around;
                background-color: $cta_color;

                .box {
                    padding: 1rem;
                    max-width: 300px;
                    cursor: pointer;
                    margin: auto;

                    .item {
                        background-color: #fff;
                        // background-color: red;
                        padding: 0.5rem 0.5rem;
                        box-shadow: 0 0 15px #00000031;
                        border-radius: 10px;

                        img {
                            width: 100%;
                            height: 150px;
                            border-radius: 10px;
                        }

                        .blg_info {
                            padding: 1rem 0.5rem;

                            h5 {
                                font-size: 12px;
                                opacity: 0.7;
                            }

                            ul {
                                display: grid;
                                grid-template-rows: auto auto;
                                grid-template-columns: 1fr 1fr;
                                text-align: center;
                                column-gap: 0.5rem;
                                row-gap: 0.5rem;
                                margin: 1rem 0;
                                list-style-type: none;
                                padding: 0;

                                li {
                                    border: 1px solid #0000006e;
                                    padding: 0.3rem 0.3rem;
                                    margin: 0 0.1rem;
                                    border-radius: 50px;
                                    font-size: 11px;
                                    opacity: 0.8;
                                    cursor: pointer;
                                    transition: all 0.3s ease-in-out;

                                    &:hover {
                                        background-color: rgba(199, 199, 199, 0.461);
                                        color: #000;
                                        opacity: 1;
                                    }
                                }

                                li:nth-child(3) {
                                    grid-column: span 2;
                                    width: 70%;
                                }
                            }

                            h2 {
                                font-size: 16px;
                                font-weight: 700;
                                color: #000000d8;
                                text-transform: uppercase;

                            }

                            p {
                                font-size: 11px;
                                font-weight: 500;
                                opacity: 0.9;
                            }

                            h6 {
                                font-size: 12px;
                                text-transform: uppercase;
                                margin-top: 1rem;
                                opacity: 0.6;
                            }

                            h3 {
                                font-size: 14px;
                                font-weight: 600;
                                margin: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}