@import "../../SCSS/variables";

.PrivacyPolicy {
    .PrivacyPolicy_bannar {
        background-color: $primary_color;
        padding: 2rem;

        .container .heading {
            font-size: 6rem !important;
            font-weight: 800;
            text-transform: uppercase;
            color: $white;
            line-height: 90%;
        }
    }

    .container_privacy {
        width: 90%;
        margin: auto;
        padding-block: 2rem;




        h2,
        h3,
        strong {
            color: $cta_color;
            font-weight: bold;
            font-size: 1.125rem;
            margin-top: 2rem;
        }

        strong {
            color: $black;
        }

        p,
        ul,
        li {
            color: 000000;
            line-height: 1.6;
            margin-block: 2rem;
            font-size: 1.25rem;
            font-weight: 500;
        }

        ul {
            list-style-type: disc;
            padding-left: 20px;
            margin: 10px 0;
        }

        a {
            color: $cta_color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .date {
            margin-bottom: 4rem;
            font-size: 1.25rem;
            font-weight: 500;
            color: $cta_color;
        }
    }
}

@media screen and (max-width: 1399px) {
    .PrivacyPolicy {
        .PrivacyPolicy_bannar {
            background-color: $primary_color;
            padding: 1rem;

            .container .heading {
                font-size: 4rem !important;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
            }
        }

        .container_privacy {
            margin: auto;
            padding-block: 1.5rem;




            h2,
            h3,
            strong {
                color: $cta_color;
                font-weight: bold;
                font-size: 1.2rem;
                margin-top: 2rem;
            }

            strong {
                color: $black;
            }

            p,
            ul,
            li {
                color: 000000;
                line-height: 1.5;
                margin-block: 2rem;
                font-size: 1rem;
                font-weight: 500;
            }

            ul {
                list-style-type: disc;
                padding-left: 20px;
                margin: 10px 0;
            }

            a {
                color: $cta_color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .date {
                margin-bottom: 4rem;
                font-size: 1.25rem;
                font-weight: 500;
                color: $cta_color;
            }
        }
    }
}


@media screen and (max-width: 1199px) {
    .PrivacyPolicy {
        .PrivacyPolicy_bannar {
            background-color: $primary_color;
            padding: 2rem;

            .container .heading {
                font-size: 4rem !important;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
            }
        }

        .container_privacy {
            margin: auto;
            padding-block: 1.2rem;




            h2,
            h3,
            strong {
                color: $cta_color;
                font-weight: bold;
                font-size: 1rem;
                margin-top: 2rem;
            }

            strong {
                color: $black;
            }

            p,
            ul,
            li {
                color: 000000;
                line-height: 1.6;
                margin-block: 2rem;
                font-size: 0.89rem;
                font-weight: 500;
            }

            ul {
                list-style-type: disc;
                padding-left: 20px;
                margin: 10px 0;
            }

            a {
                color: $cta_color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .date {
                margin-bottom: 3rem;
                font-size: 1.25rem;
                font-weight: 500;
                color: $cta_color;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .PrivacyPolicy {
        .PrivacyPolicy_bannar {
            background-color: $primary_color;
            padding: 2rem;

            .container .heading {
                font-size: 3rem !important;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
                padding-inline: 1rem;
            }
        }

        .container_privacy {
            margin: auto;
            padding-block: 1.2rem;




            h2,
            h3,
            strong {
                color: $cta_color;
                font-weight: bold;
                font-size: 1rem;
                margin-top: 2rem;
            }

            strong {
                color: $black;
            }

            p,
            ul,
            li {
                color: 000000;
                line-height: 1.6;
                margin-block: 2rem;
                font-size: 0.89rem;
                font-weight: 500;
            }

            ul {
                list-style-type: disc;
                padding-left: 20px;
                margin: 10px 0;
            }

            a {
                color: $cta_color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .date {
                margin-bottom: 3rem;
                font-size: 1rem;
                font-weight: 500;
                color: $cta_color;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .PrivacyPolicy {
        .PrivacyPolicy_bannar {
            background-color: $primary_color;
            padding: 2rem;

            .container .heading {
                font-size: 3rem !important;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
                padding-inline: 1rem;
            }
        }

        .container_privacy {
            margin: auto;
            padding-block: 1.2rem;




            h2,
            h3,
            strong {
                color: $cta_color;
                font-weight: bold;
                font-size: 1rem;
                margin-top: 2rem;
            }

            strong {
                color: $black;
            }

            p,
            ul,
            li {
                color: 000000;
                line-height: 1.6;
                margin-block: 2rem;
                font-size: 0.89rem;
                font-weight: 500;
            }

            ul {
                list-style-type: disc;
                padding-left: 20px;
                margin: 10px 0;
            }

            a {
                color: $cta_color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .date {
                margin-bottom: 3rem;
                font-size: 1rem;
                font-weight: 500;
                color: $cta_color;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .PrivacyPolicy {
        .PrivacyPolicy_bannar {
            background-color: $primary_color;
            padding: 1.5rem;
            padding-top: 3.5rem;

            .container .heading {
                font-size: 2.6rem !important;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
                padding-inline: 0rem;
            }
        }

        .container_privacy {
            margin: auto;
            padding-block: 1.2rem;




            h2,
            h3,
            strong {
                color: $cta_color;
                font-weight: bold;
                font-size: 0.95rem;
                margin-top: 1.8rem;
            }

            strong {
                color: $black;
            }

            p,
            ul,
            li {
                color: 000000;
                line-height: 1.6;
                margin-block: 2rem;
                font-size: 0.8rem;
                font-weight: 500;
            }

            ul {
                list-style-type: disc;
                padding-left: 20px;
                margin: 10px 0;
            }

            a {
                color: $cta_color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .date {
                margin-bottom: 2.5rem;
                font-size: 0.95rem;
                font-weight: 500;
                color: $cta_color;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .PrivacyPolicy {
        .PrivacyPolicy_bannar {
            background-color: $primary_color;
            padding: 0rem;
            padding-top: 2.5rem;
            padding-bottom: 1rem;

            .container .heading {
                font-size: 1.8rem !important;
                font-weight: 800;
                text-transform: uppercase;
                color: $white;
                line-height: 90%;
                padding-inline: 0rem;
            }
        }

        .container_privacy {
            margin: auto;
            padding-block: 0.5rem;

            h2,
            h3,
            strong {
                color: $cta_color;
                font-weight: bold;
                font-size: 0.85rem;
                margin-top: 1.8rem;
            }

            strong {
                color: $black;
            }

            p,
            ul,
            li {
                color: 000000;
                line-height: 1.6;
                margin-block: 2rem;
                font-size: 0.75rem;
                font-weight: 500;
            }

            ul {
                list-style-type: disc;
                padding-left: 20px;
                margin: 10px 0;
            }

            a {
                color: $cta_color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .date {
                margin-bottom: 2.5rem;
                font-size: 0.8rem;
                font-weight: 500;
                color: $cta_color;
            }
        }
    }
}