@import '../../../SCSS/variables';

.support_container {

    p {
        font-size: 1.5rem;
        font-weight: 400;

        a {
            color: $cta_color;
            font-weight: 600;
        }
    }
}


@media screen and (max-width:1399px) {
    .support_container {
        p {

            font-size: 1.4rem;
        }
    }
}

@media screen and (max-width:1199px) {
    .support_container {
        p {
            font-size: 1rem;
        }
    }
}

@media screen and (max-width:991px) {
    .support_container {
        p {
            font-size: 1rem;
        }
    }
}

@media screen and (max-width:767px) {
    .support_container {
        p {
            font-size: 0.8rem;
        }
    }
}

@media screen and (max-width:500px) {
    .support_container {
        p {
            font-size: 0.8rem;
        }
    }
}