// @import "../../assets/variables.scss";
@import "../../SCSS/variables";

.container_upload_section {
    max-width: 90%;
    margin: auto;
}

.uploadSection {
    h1 {
        color: #000;
        padding-top: 1rem;
        padding-bottom: 0.8rem;
        width: 55%;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
    }

    .Upload {
        display: flex;
        justify-content: space-evenly;
        column-gap: 30px;
        margin-bottom: 2rem;

        .drag_over {
            border-color: $cta_color;
            background-color: rgba(0, 197, 79, 0.1);
        }

        .left_container {
            width: 55%;
            box-sizing: border-box;
            background-color: $background_color;
            border: 2px solid $cta_color;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 20px;
            box-shadow: 3px 6px 12px 4px hsla(0, 0%, 0%, 0.3);

            .button_container {

                button {
                    width: 100%;
                    color: white;
                    font-size: 18px;
                    font-weight: 500;
                    background-color: $cta_color;
                    border-radius: 0px 0px 18px 18px;
                    padding: 1rem;
                    text-transform: uppercase;
                    border: none;
                }
            }

            .browse_container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;

                svg {
                    color: $cta_color;
                    width: 65px;
                    height: 80px;
                }

                .custom_icon {
                    color: $cta_color;
                    font-size: 50px;
                }

                h2 {
                    margin-top: 1rem;
                    color: $cta_color;
                    font-weight: $h2_font_weight;
                    font-size: 48pts;
                }

                .browseFile {
                    font-size: 20px;
                    color: black;
                    font-weight: 700;
                    margin-top: 2rem;

                    label {
                        color: $cta_color;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }

                .fileSupportedType {
                    font-size: 12px;
                    opacity: 0.5;
                    color: 000000;
                    font-weight: 500;
                    margin: 0px;
                }

                .success_message {
                    font-size: 12px;
                    color: $cta_color;
                    font-weight: 500;
                    text-align: center;
                    margin-bottom: 1rem;

                }

                .error_message {
                    font-size: 12px;
                    color: red;
                    text-align: center;
                    margin-bottom: 1rem;
                }
            }

            .filename {
                // padding-left: 3rem;
                font-size: 16px;
                font-weight: 600;
                text-align: center;
            }

            .progress_container {
                padding: 0 2rem 2rem;

                .button_group {
                    text-align: right;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    >p {
                        font-weight: 600;
                        size: 20px;
                        color: #000000;
                    }

                    button {
                        padding: 0;
                        display: inline-block;
                        background-color: transparent;
                        padding: 0 0.5rem;
                        color: #000;
                        border: none;
                        opacity: 0.8;
                    }

                }

                .progress_bar {
                    background-color: rgba(0, 128, 0, 0.311);
                    border-radius: 10px;

                    span {
                        background-color: $cta_color;
                        border-radius: 10px;

                    }
                }

                .progress_info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        margin: 0.5rem 0;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }

        }

        .right_container {
            width: 45%;
            background-color: $background_color;
            border-radius: 20px;
            border: 2px solid $cta_color;
            box-shadow: 3px 6px 12px 4px hsla(0, 0%, 0%, 0.3);

            .video_box {
                width: 100%;
                max-height: 347px;
                border-radius: 20px 20px 0px 0px;
                background-color: $cta_color;

                video {
                    padding: 0px;
                    margin: 0px;
                    min-width: 100%;
                    border-radius: 18px 18px 0px 0px;
                    height: 250px;
                    width: 100%;
                    object-fit: fill;
                }
            }

            .guidelines_box {
                h2 {
                    padding: 0px;
                    margin: 0px;
                    padding: 6px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: white;
                    background-color: $cta_color;
                }

                .guidelines_wrp {
                    padding-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    row-gap: 2px;

                    .guidelines_list {
                        display: flex;
                        column-gap: 10px;
                        row-gap: 2px;
                        padding: 0px 30px;

                        p {
                            font-size: 18px;
                            text-align: left;
                            font-weight: 500;
                            line-height: 21px;
                            color: #000000;
                            text-align: justify;
                        }
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 1399px) {
    .uploadSection {
        h1 {
            padding-top: 0.5rem;
            padding-bottom: 0.2rem;
            font-size: 28px;
        }

        .Upload {
            gap: 2rem;

            .drag_over {
                background-color: rgba(0, 197, 79, 0.1);
            }

            .left_container {
                box-shadow: 3px 6px 12px 4px hsla(0, 0%, 0%, 0.3);

                .button_container {
                    button {
                        width: 100%;
                        font-size: 24px;
                        padding: 0.5rem;
                    }
                }

                .browse_container {
                    padding: 36px;

                    svg {
                        color: $cta_color;
                        width: 55px;
                        height: 70px;
                    }

                    .custom_icon {
                        font-size: 42px;
                    }

                    h2 {
                        margin-top: 1.2rem;
                        font-size: 32px;
                    }

                    .browseFile {
                        font-size: 18px;
                        margin-top: 1rem;
                    }

                    .fileSupportedType {
                        font-size: 12px;
                        margin: 0px;
                    }

                    .success_message {
                        font-size: 13px;
                        margin-bottom: 0.5rem;

                    }

                    .error_message {
                        font-size: 13px;
                        margin-bottom: 0.5rem;
                    }
                }

                .filename {
                    font-size: 18px;
                }

                .progress_container {
                    padding: 0 0.5rem 0rem;

                    .button_group {

                        >p {
                            size: 22px;
                        }

                        button {
                            padding: 0;
                            padding: 0 0.6rem;
                        }

                    }

                    .progress_bar {
                        border-radius: 10px;

                        span {
                            border-radius: 10px;

                        }
                    }

                    .progress_info {
                        p {
                            margin: 0.2rem 0;
                            font-size: 14px;
                        }
                    }
                }

            }

            .right_container {
                box-shadow: 3px 6px 12px 4px hsla(0, 0%, 0%, 0.3);

                .video_box {
                    max-height: auto;
                    border-radius: 20px 20px 0px 0px;

                    video {
                        border-radius: 18px 18px 0px 0px;
                        height: 230px;
                        width: 100%;
                        object-fit: fill;
                    }
                }

                .guidelines_box {
                    h2 {
                        padding: 0px;
                        margin: 0px;
                        padding: 5px;
                        font-size: 18px;
                    }

                    .guidelines_wrp {
                        padding-top: 0.6rem;
                        row-gap: 2px;

                        .guidelines_list {
                            column-gap: 8px;
                            row-gap: 2px;
                            padding: 0px 18px;

                            p {
                                font-size: 14.5px;
                                line-height: 20px;
                                margin-bottom: 0.5rem;
                            }
                        }
                    }

                }
            }
        }
    }
}


@media screen and (max-width: 1199px) {
    .uploadSection {
        h1 {
            padding-top: 0.8rem;
            padding-bottom: 0.5rem;
            font-size: 26px;
        }

        .Upload {
            gap: 1rem;

            .left_container {
                min-height: auto;

                .button_container {
                    button {
                        font-size: 20px;
                        padding: 0.6rem;
                    }
                }

                .browse_container {
                    padding: 30px;

                    svg {
                        color: $cta_color;
                        width: 50px;
                        height: 70px;
                    }


                    h2 {
                        margin-top: 1rem;
                        font-size: 28px;
                    }

                    .browseFile {
                        font-size: 14px;
                        margin-top: 2rem;
                    }

                    .fileSupportedType {
                        font-size: 12px;
                        margin: 0px;
                    }

                    .success_message {
                        font-size: 12px;
                        margin-bottom: 1rem;

                    }

                    .error_message {
                        font-size: 12px;
                        margin-bottom: 1rem;
                    }
                }

                .filename {
                    font-size: 14px;
                }

                .progress_container {
                    padding: 0 2rem 2rem;

                    .button_group {

                        >p {
                            font-size: 14px;
                        }

                        button {
                            padding: 0;
                            padding: 0 0.5rem;
                        }

                    }

                    .progress_bar {
                        border-radius: 10px;

                        span {
                            border-radius: 10px;

                        }
                    }

                    .progress_info {
                        p {
                            margin: 0.5rem 0;
                            font-size: 12px;
                        }
                    }
                }

            }

            .right_container {

                .video_box {
                    max-height: 350px;

                    video {
                        border-radius: 18px 18px 0px 0px;
                        height: 210px;
                        width: 100%;
                        object-fit: fill;
                    }
                }

                .guidelines_box {
                    h2 {
                        padding: 0px;
                        margin: 0px;
                        padding: 5px;
                        font-size: 16px;
                    }

                    .guidelines_wrp {
                        padding-top: 0.4rem;
                        row-gap: 2px;

                        .guidelines_list {
                            column-gap: 6px;
                            row-gap: 2px;
                            padding: 0px 15px;

                            p {
                                font-size: 14px;
                                line-height: 19px;
                            }
                        }
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .uploadSection {
        h1 {
            font-size: 28px;
        }

        .Upload {

            .left_container {
                min-height: auto;

                .button_container {
                    button {
                        font-size: 22px;
                        padding: 1rem;
                    }
                }

                .browse_container {
                    padding: 30px;

                    svg {
                        color: $cta_color;
                        width: 50px;
                        height: 70px;
                    }


                    h2 {
                        margin-top: 1rem;
                        font-size: 30px;
                    }

                    .browseFile {
                        font-size: 16px;
                        margin-top: 2rem;
                    }

                    .fileSupportedType {
                        font-size: 12px;
                        margin: 0px;
                    }

                    .success_message {
                        font-size: 12px;
                        margin-bottom: 1rem;

                    }

                    .error_message {
                        font-size: 11px;
                        margin-bottom: 1rem;
                    }
                }

                .filename {
                    font-size: 12px;
                }

                .progress_container {
                    padding: 0 2rem 2rem;

                    .button_group {

                        >p {
                            font-size: 12px;
                        }

                        button {
                            padding: 0;
                            padding: 0 0.5rem;
                        }

                    }

                    .progress_bar {
                        border-radius: 10px;

                        span {
                            border-radius: 10px;

                        }
                    }

                    .progress_info {
                        p {
                            margin: 0.5rem 0;
                            font-size: 12px;
                        }
                    }
                }

            }

            .right_container {

                .video_box {


                    video {
                        border-radius: 18px 18px 0px 0px;
                        height: 210px;
                        width: 100%;
                        object-fit: fill;
                    }
                }

                .guidelines_box {
                    h2 {
                        font-size: 16px;
                    }

                    .guidelines_wrp {

                        .guidelines_list {
                            column-gap: 9px;
                            row-gap: 2px;
                            padding: 0px 20px;

                            p {
                                font-size: 13px;
                                line-height: 21px;
                            }
                        }
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 991px)and (min-height:800px) {
    .uploadSection {
        padding-top: 2rem;

        h1 {
            font-size: 28px;
        }

        .Upload {
            .left_container {
                min-height: auto;

                .button_container {
                    button {
                        font-size: 22px;
                        padding: 1rem;
                    }
                }

                .browse_container {
                    padding: 30px;

                    svg {
                        color: $cta_color;
                        width: 50px;
                        height: 70px;
                    }


                    h2 {
                        margin-top: 1rem;
                        font-size: 30px;
                    }

                    .browseFile {
                        font-size: 16px;
                        margin-top: 2rem;
                    }

                    .fileSupportedType {
                        font-size: 12px;
                        margin: 0px;
                    }

                    .success_message {
                        font-size: 12px;
                        margin-bottom: 1rem;

                    }

                    .error_message {
                        font-size: 11px;
                        margin-bottom: 1rem;
                    }
                }

                .filename {
                    font-size: 12px;
                }

                .progress_container {
                    padding: 0 2rem 2rem;

                    .button_group {

                        >p {
                            font-size: 12px;
                        }

                        button {
                            padding: 0;
                            padding: 0 0.5rem;
                        }

                    }

                    .progress_bar {
                        border-radius: 10px;

                        span {
                            border-radius: 10px;

                        }
                    }

                    .progress_info {
                        p {
                            margin: 0.5rem 0;
                            font-size: 12px;
                        }
                    }
                }

            }

            .right_container {

                .video_box {


                    video {
                        border-radius: 18px 18px 0px 0px;
                        height: 210px;
                        width: 100%;
                        object-fit: fill;
                    }
                }

                .guidelines_box {
                    h2 {
                        font-size: 16px;
                    }

                    .guidelines_wrp {

                        .guidelines_list {
                            column-gap: 9px;
                            row-gap: 2px;
                            padding: 0px 20px;

                            p {
                                font-size: 13px;
                                line-height: 21px;
                            }
                        }
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .uploadSection {
        width: 90%;
        max-width: 480px;
        margin: auto;

        h1 {
            padding-top: 3rem;
            padding-bottom: 1rem;
            text-align: center;
            font-size: 28px;
            width: 100%;
        }

        .Upload {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            gap: 2.2rem;

            .left_container {
                width: 100%;
                min-height: 420px;

                .button_container {
                    button {

                        font-size: 18px;

                    }
                }

                .browse_container {
                    min-height: 100%;
                    padding: 26px;

                    h2 {

                        font-size: 28px;
                        text-align: center;
                    }

                    .browseFile {
                        font-size: 16px;
                    }

                    .fileSupportedType {
                        font-size: 11px;
                    }

                    .success_message {
                        font-size: 12px;
                    }

                    .error_message {
                        font-size: 12px;
                    }
                }

                .filename {
                    font-size: 12px;
                }

                .progress_container {
                    padding: 0 2.2rem 2.2rem;

                    .button_group {
                        text-align: right;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        >p {
                            font-weight: 600;
                            size: 22px;
                            color: #000000;
                        }

                        button {
                            padding: 0;
                            display: inline-block;
                            background-color: transparent;
                            padding: 0 0.6rem;
                            color: #000;
                            border: none;
                            opacity: 0.8;
                        }

                    }

                    .progress_bar {
                        background-color: rgba(0, 128, 0, 0.311);
                        border-radius: 10px;

                        span {
                            background-color: $cta_color;
                            border-radius: 10px;

                        }
                    }

                    .progress_info {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        p {
                            margin: 0.6rem 0;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }

            }

            .right_container {
                width: 100%;
                min-height: 480px;

                .video_box {
                    video {
                        border-radius: 18px 18px 0px 0px;
                        height: 210px;
                        width: 100%;
                        object-fit: fill;
                    }
                }

                .guidelines_box {


                    h2 {
                        padding: 0px;
                        margin: 0px;
                        padding: 12px;
                        text-align: center;
                        font-size: 16px;

                    }

                    .guidelines_wrp {
                        padding-top: 1.2rem;
                        row-gap: 2px;

                        .guidelines_list {
                            padding: 0px 22px;

                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .uploadSection {
        h1 {

            padding-top: 3rem;
            padding-bottom: 0.8rem;
            text-align: center;
            font-size: 24px;
            width: 100%;

        }

        .Upload {
            flex-direction: column-reverse;
            gap: 2rem;
            max-width: 100%;
            margin: auto;

            .left_container {
                width: 100%;
                min-height: auto;
                margin-bottom: 2rem;


                .browse_container {
                    min-height: 100%;
                    padding: 30px;

                    h2 {
                        margin-top: 1rem;
                        font-size: 22px;
                        text-align: center;
                    }

                    .browseFile {
                        font-size: 18px;
                        margin-top: 1.2rem;
                        text-align: center;
                    }

                    .fileSupportedType {
                        font-size: 12px;
                        margin: 0px;
                    }

                    .success_message {
                        font-size: 12px;
                        text-align: center;
                        margin-bottom: 1rem;

                    }

                    .error_message {
                        font-size: 12px;
                        text-align: center;
                        margin-bottom: 1rem;
                    }
                }

                .filename {
                    font-size: 14px;
                }

                .progress_container {
                    padding: 0 2rem 2rem;

                    .button_group {
                        text-align: right;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        >p {

                            size: 20px;
                        }

                        button {
                            padding: 0;
                            display: inline-block;
                            background-color: transparent;
                            padding: 0 0.5rem;
                            border: none;
                            opacity: 0.8;
                        }

                    }

                    .progress_bar {
                        border-radius: 10px;

                        span {
                            border-radius: 10px;

                        }
                    }

                    .progress_info {


                        p {
                            margin: 0.5rem 0;
                        }
                    }
                }

            }

            .right_container {
                width: 100%;
                background-color: $background_color;
                border-radius: 20px;
                border: 2px solid $cta_color;
                box-shadow: 3px 6px 12px 4px hsla(0, 0%, 0%, 0.3);

                .video_box {
                    video {
                        border-radius: 18px 18px 0px 0px;
                        height: 210px;
                        width: 100%;
                        object-fit: fill;
                    }
                }

                .guidelines_box {
                    h2 {
                        padding: 0px;
                        margin: 0px;
                        padding: 10px;
                        text-align: center;
                        font-size: 16px;
                    }

                    .guidelines_wrp {
                        padding-top: 0.5rem;
                        row-gap: 2px;

                        .guidelines_list {
                            column-gap: 8px;
                            row-gap: 2px;
                            padding: 0px 10px;

                            p {
                                font-size: 14px;
                                text-align: left;
                                font-weight: 500;
                                line-height: 21px;
                                color: #000000;
                            }
                        }
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .uploadSection {
        h1 {

            padding-top: 2rem;
            padding-bottom: 0.2rem;
            text-align: center;
            font-size: 20px;
            width: 100%;

        }

        .Upload {
            flex-direction: column-reverse;
            gap: 2rem;
            max-width: 100%;
            margin: auto;

            .left_container {
                width: 100%;
                min-height: auto;
                margin-bottom: 2rem;


                .browse_container {
                    min-height: 100%;
                    padding: 30px;

                    h2 {
                        margin-top: 1rem;
                        font-size: 18px;
                        text-align: center;
                    }

                    .browseFile {
                        font-size: 14px;
                        margin-top: 1.2rem;
                        text-align: center;
                    }

                    .fileSupportedType {
                        font-size: 11px;
                        margin: 0px;
                    }

                    .success_message {
                        font-size: 11px;
                        text-align: center;
                        margin-bottom: 1rem;

                    }

                    .error_message {
                        font-size: 11px;
                        text-align: center;
                        margin-bottom: 1rem;
                    }
                }

                .filename {
                    font-size: 12px;
                }

                .progress_container {
                    padding: 0 2rem 2rem;

                    .button_group {
                        text-align: right;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        >p {

                            size: 16px;
                        }

                        >div {
                            button {
                                padding: 0;
                                display: inline-block;
                                background-color: transparent;
                                padding: 0 0.2rem;
                                border: none;
                                opacity: 0.8;
                                font-size: 10px;
                            }
                        }



                    }

                    .progress_bar {
                        border-radius: 10px;

                        span {
                            border-radius: 10px;

                        }
                    }

                    .progress_info {


                        p {
                            margin: 0.5rem 0;
                        }
                    }
                }

            }

            .right_container {
                width: 100%;
                background-color: $background_color;
                border-radius: 20px;
                border: 2px solid $cta_color;
                box-shadow: 3px 6px 12px 4px hsla(0, 0%, 0%, 0.3);

                .video_box {


                    video {
                        border-radius: 18px 18px 0px 0px;
                        height: 200px;
                        width: 100%;
                        object-fit: fill;
                    }
                }

                .guidelines_box {
                    h2 {
                        padding: 0px;
                        margin: 0px;
                        padding: 10px;
                        text-align: center;
                        font-size: 14px;
                    }

                    .guidelines_wrp {
                        padding-top: 0.5rem;
                        row-gap: 2px;

                        .guidelines_list {
                            column-gap: 8px;
                            row-gap: 2px;
                            padding: 0px 10px;

                            p {
                                font-size: 12px;
                                text-align: left;
                                font-weight: 500;
                                line-height: 20px;
                                color: #000000;
                            }
                        }
                    }

                }
            }
        }
    }
}